import { useState, useContext, useEffect } from 'react';
import { Box, Button, Container } from '@mui/material';
import { SubtaskItem as SubtaskItemType } from '@api';
import { ActivityContext } from 'src/contexts/ActivityContext';
import { t } from 'i18next';
import MoreOptionsHeader from '../MoreOptionsHeader';
import AddIcon from '@mui/icons-material/Add';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { validateSubtasks } from '@/components/utils/Validate';
import { SupervisorContext } from 'src/contexts/SupervisorContext';
import { AppContext } from 'src/contexts/AppContext';
import SubtasksList from './SubtasksList';

const Subtasks = () => {
  const { pageHeight } = useContext(AppContext);
  const formHandler = useContext(ActivityFormContext);
  const { supervisorHeaderHeight } = useContext(SupervisorContext);
  const [formValid, setFormValid] = useState<boolean | string>(false);

  const { activity, setActivity } = useContext(ActivityContext);
  const [subtasks, setSubtasks] = useState<SubtaskItemType[]>(activity.subtasks || []);

  const generateUniqueRandomId = (): number => {
    // generate a random number
    const javaMaxInt = 2147483647;
    const id = Math.floor(Math.random() * javaMaxInt);
    // check if the number exists on the instance
    if (subtasks && subtasks.map((item) => item.subtaskId).includes(id)) {
      // if yes, call the function again with the same parameter
      return generateUniqueRandomId();
    } else {
      // if no, return the number as the result
      return id;
    }
  };

  const updateSubtasks = () => {
    setActivity({ ...activity, subtasks: subtasks });
    formHandler.goToPreviousForm();
  };

  useEffect(() => {
    const error = validateSubtasks(subtasks, t);
    if (error === null) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [subtasks]);

  const addSubtaskItem = () => {
    const id: number = generateUniqueRandomId();
    // Create an object iwth a random id, which isn't used yet
    const newItem: SubtaskItemType = {
      subtaskId: id,
      subtaskText: '',
      checked: false,
      picture: '',
    };
    const newSubtaskItems = Array.from(subtasks);
    newSubtaskItems.push(newItem);
    setSubtasks(newSubtaskItems);
  };

  return (
    <Box
      sx={{
        minHeight: `calc(${pageHeight}px - ${supervisorHeaderHeight}px - 24px)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <MoreOptionsHeader />

      <Box sx={{ width: '100%', mt: 1, height: '100%', display: 'flex', justifyContent: 'center' }}>
        <SubtasksList list={subtasks} onListUpdate={setSubtasks} />
      </Box>

      <Button
        sx={{ mt: 2, maxWidth: '35em', width: '100%' }}
        variant="contained"
        onClick={addSubtaskItem}
      >
        <AddIcon /> {subtasks.length ? t('Add more') : t('Add subtask')}
      </Button>
      <Box id="divider" sx={{ flex: 1 }} />
      <Container sx={{ py: 2, px: 0 }}>
        <Button
          sx={{ width: '100%', padding: 1.25 }}
          variant="contained"
          onClick={updateSubtasks}
          disabled={!formValid}
        >
          {activity.subtasks && activity.subtasks.length ? t('Update subtasks') : t('Add subtasks')}
        </Button>
      </Container>
    </Box>
  );
};

export default Subtasks;
