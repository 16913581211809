import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import googleLogo from '@/assets/google-icon.svg';
import { BASE_URL } from '@api';
import { useTranslation } from 'react-i18next';

const GoogleLoginButton = () => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        size="large"
        variant="contained"
        color="googleWhite"
        sx={{
          justifyContent: 'left',
          width: '100%',
          border: '1px solid lightgray',
          color: 'rgba(0, 0, 0, 0.87)',
        }}
        href={`${BASE_URL}/login/google`}
      >
        <img src={googleLogo} alt="Google-icon" style={{ height: '1.5em', width: '1.5em' }} />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          {t('Sign in with Google')}
        </Box>
      </Button>
    </>
  );
};

export default GoogleLoginButton;
