import { createContext, useEffect, useState, useRef, useContext, useMemo } from 'react';
import { Client, Supervisor, SupervisorConnection } from '@api';
import SupervisorHeader from '../components/Supervisor/SupervisorHeader';
import LoadingPage from '../router/LoadingPage';
import { SupervisorApi } from '@api';
import { AppContext } from './AppContext';
import { useLocalStorageState } from 'src/components/utils/useLocalStorageState';

type props = {
  children?: React.ReactNode;
};

export type SupervisorContextType = {
  currentClient?: Client;
  setClient: (newVal?: Client) => void;
  clients: Client[];
  supervisors: Supervisor[];
  supervisorRequests: SupervisorConnection[];
  isSupervising: boolean;
  setSupervising: (newVal: boolean) => void;
  refresh: () => void;
  supervisorHeaderRef?: React.RefObject<HTMLDivElement>;
  supervisorHeaderHeight: number;
  setSupervisorHeaderHeight: (newVal: number) => void;
};

export const SupervisorContext = createContext<SupervisorContextType>({} as SupervisorContextType);

export const SupervisorScene = ({ children }: props) => {
  const { user } = useContext(AppContext);
  const supervisorHeaderRef = useRef<HTMLDivElement | null>(null);
  const [currentClient, setCurrentClient] = useState<Client>();
  const [supervisorHeaderHeight, setSupervisorHeaderHeight] = useState<number>(0);
  const [supervisorConnections, setSupervisorConnections] = useState<SupervisorConnection[]>([]);
  const { pageHeight } = useContext(AppContext);

  // const [isSupervising, setIsSupervising] = useState<boolean>(false); // if we can set this from LS then problem solved
  const getSupervising = () => {
    return localStorage.getItem('supervising') === `${user?.tag}`;
  };

  const [isSupervising, setIsSupervising] = useState<boolean>(getSupervising());

  // if isSupervising, Await for supervisors to be loaded before rendering
  const [loaded, setLoaded] = useState<boolean>(!isSupervising);

  const setSupervising = (newVal: boolean) => {
    setIsSupervising(newVal);
    localStorage.setItem('supervising', newVal ? `${user?.tag}` : '');
    if (!newVal) setCurrentClient(undefined);
  };

  useEffect(() => {
    const init = async () => {
      await refresh();
      setLoaded(true);
    };
    init();
  }, []);

  const parseSupervisorConnections = (supervisorConnections?: SupervisorConnection[]) => {
    const myTag = user?.tag;
    const clients: Client[] = [];
    const supervisors: Supervisor[] = [];
    const supervisorRequests: SupervisorConnection[] = [];
    if (myTag && supervisorConnections) {
      supervisorConnections.forEach((connection) => {
        if (connection.acceptedConnection === null) supervisorRequests.push(connection);
        else if (connection.acceptedConnection) {
          if (connection.supervisor.tag === myTag) clients.push(connection.client);
          if (connection.client.tag === myTag) supervisors.push(connection.supervisor);
        }
      });
    }
    return { clients, supervisors, supervisorRequests };
  };

  const { clients, supervisors, supervisorRequests } = useMemo(() => {
    const { clients, supervisors, supervisorRequests } =
      parseSupervisorConnections(supervisorConnections);

    if (isSupervising && clients) {
      const sessionCurrentClient = sessionStorage.getItem('currentClient');
      if (!currentClient && sessionCurrentClient) {
        const client = clients.find((client) => client.tag === sessionCurrentClient);
        if (client) setCurrentClient(client);
      }
    }
    return { clients, supervisors, supervisorRequests };
  }, [supervisorConnections, isSupervising]);

  const refresh = async () => {
    const supervisorConnections = await new SupervisorApi().getSupervisorConnections();
    //Check if current client is still in the list
    if (currentClient) {
      const client = clients.find((client) => client.tag === currentClient.tag);
      if (!client) {
        setClient(undefined);
      }
    }
    setSupervisorConnections(supervisorConnections ?? []);
    return clients;
  };

  const setClient = (client?: Client) => {
    setCurrentClient(client);
    if (client) sessionStorage.setItem('currentClient', client.tag);
    else sessionStorage.removeItem('currentClient');
  };

  if (!loaded) return <LoadingPage minHeight={pageHeight + 'px'} />;
  return (
    <SupervisorContext.Provider
      value={{
        supervisorHeaderRef,
        currentClient,
        setClient,
        clients,
        supervisors,
        supervisorRequests,
        isSupervising,
        setSupervising,
        refresh,
        supervisorHeaderHeight,
        setSupervisorHeaderHeight,
      }}
    >
      <SupervisorHeader />
      {children}
    </SupervisorContext.Provider>
  );
};
