import { Board } from '@api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ColorSelector from './colorSelect/ColorSelector';
import { NotesContext } from 'src/contexts/NotesContext';
import { async } from 'q';
import { set } from 'date-fns';

type BoardDialogProps = {
  open: boolean;
  onClose: () => void;
  board?: Board;
};

const defaultBoard: Board = {
  id: 1,
  title: '',
  color: '',
  notes: [],
  position: 1,
};

const BoardDialog = ({ open, onClose, board }: BoardDialogProps) => {
  const { t } = useTranslation();
  const [validBoard, setValidBoard] = useState(false);
  const [boardName, setBoardName] = useState('');
  const [boardColor, setBoardColor] = useState('');
  const { boards, createBoard, editBoard, deleteBoard } = useContext(NotesContext);
  const [sending, setSending] = useState(false);

  const handleRemove = async () => {
    setSending(true);
    if (board) {
      await deleteBoard(board);
    }
    onClose();
    setSending(false);
  };

  const handleAddOrEditBoard = async () => {
    setSending(true);
    if (board) {
      await editBoard({ ...board, title: boardName, color: boardColor });
    } else {
      await createBoard({
        ...defaultBoard,
        title: boardName,
        color: boardColor,
        position: boards.length + 1,
      });
    }
    onClose();
    setValidBoard(false);
    setBoardName('');
    setBoardColor('');
    setSending(false);
  };

  useEffect(() => {
    if (board) {
      setBoardName(board.title);
      setBoardColor(board.color);
    } else {
      if (boardName.length > 0) setBoardName('');
      if (boardColor.length > 0) setBoardColor('');
    }
  }, [board]);

  useEffect(() => {
    const validBoard =
      boardName.length !== 0 &&
      boardColor.length !== 0 &&
      boardColor.match(/^#[0-9A-F]{6}$/i) !== null;

    // Check if is the same board
    if (board && board.title === boardName && board.color === boardColor) {
      setValidBoard(false);
      return;
    }
    setValidBoard(validBoard);
  }, [boardName, boardColor]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="board-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle id="alert-dialog-title">{board ? t('Edit board') : t('Add Board')}</DialogTitle>
      <DialogContent>
        <TextField
          label={t('Title')}
          value={boardName}
          onChange={(e) => setBoardName(e.target.value)}
          fullWidth
          sx={{ my: 2 }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
          }}
        />
        <Typography variant={'subtitle1'}>{t('Board Color')}</Typography>
        <ColorSelector
          selectedColor={boardColor}
          onSelectColor={(newColor) => setBoardColor(newColor)}
        ></ColorSelector>
      </DialogContent>
      <DialogActions sx={{ justifyContent: board ? 'space-between' : '' }}>
        {board && (
          <Button variant={'contained'} onClick={handleRemove} color={'error'} disabled={sending}>
            {t('Remove')}
          </Button>
        )}
        <Button
          variant={'contained'}
          onClick={handleAddOrEditBoard}
          color={'primary'}
          disabled={!validBoard || sending}
        >
          {board ? t('Edit') : t('Add')}
        </Button>
      </DialogActions>
      {sending && (
        <Stack
          justifyContent={'center'}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <LinearProgress color="info" />
        </Stack>
      )}
    </Dialog>
  );
};

export default BoardDialog;
