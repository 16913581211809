import { useContext, useMemo } from 'react';
import { CalendarContext } from '@/contexts/CalendarContext';
import { isDate } from 'date-fns';
import Statistics from '../Statistics';
import { useTranslation } from 'react-i18next';

const CompletedWeeklyActivities = () => {
  const { t } = useTranslation();
  const { weekInstances } = useContext(CalendarContext);

  const completedInstances = useMemo(
    () => weekInstances.filter((instance) => isDate(instance.dateIsCompleted)).length,
    [weekInstances],
  );

  return <Statistics completedInstances={completedInstances} title={t('Completed activities')} />;
};

export default CompletedWeeklyActivities;
