import ReactEcharts from 'echarts-for-react';
import { StatisticsContext } from 'src/contexts/StatisticsContext';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { useContext, useEffect, useState } from 'react';

const LineChart = () => {
  const { data, intervalType } = useContext(StatisticsContext);

  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const imageHeight = 30;
  const categories = {
    day: [],
    week:
      lang === 'sv'
        ? ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön']
        : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    month: [],
    year:
      lang === 'sv'
        ? ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
        : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  };

  const [options, setOptions] = useState<any>({
    animationDuration: 500,
    gradientColor: ['#00d4ff', '#090979'],
    visualMap: [
      {
        type: 'continuous',
        show: false,
        min: 0,
        max: 5,
        color: ['#009f54', '#91bd49', '#fce92b', '#ee8130', '#eb2c3a', '#cccccc'],
      },
    ],
    grid: {
      left: 40,
      top: 20,
      right: 0,
      bottom: 40,
    },
    xAxis: {
      type: 'category',
      data: categories[intervalType as keyof typeof categories],
    },
    yAxis: {
      type: 'category',
      minInterval: 1,
      data: [0, 1, 2, 3, 4, 5],
      min: 0,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        formatter: (value: number) => {
          return '{' + value + '| }\n{value|' + '}';
        },
        axisLineColor: '',
        interval: 0,
        margin: 10,
        verticalAlign: 'center',
        rich: {
          1: {
            height: imageHeight,
            backgroundColor: {
              image: '/emotions/1.png',
            },
          },
          2: {
            height: imageHeight,
            backgroundColor: {
              image: '/emotions/2.png',
            },
          },
          3: {
            height: imageHeight,
            backgroundColor: {
              image: '/emotions/3.png',
            },
          },
          4: {
            height: imageHeight,
            backgroundColor: {
              image: '/emotions/4.png',
            },
          },
          5: {
            height: imageHeight,
            backgroundColor: {
              image: '/emotions/5.png',
            },
          },
        },
      },
    },
    series: [
      {
        data: [1, 4, 5, 0, 3, 2, 5, 2, 3],
        type: 'line',
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: 5,
        },
      },
    ],
  });

  const getTitleString = () => {
    if (intervalType === 'day') return t('Average rating per hour');
    else if (intervalType === 'week') return t('Average rating per day');
    else if (intervalType === 'month') return t('Average rating per day');
    else return t('Average rating per month');
  };

  const getupdatedSeries = () => {
    if (!Object.keys(data).length) return [];

    return data?.timestampAverages.map((item: any) => Math.ceil(item.average));
  };

  useEffect(() => {
    setOptions({ ...options, series: [{ data: [] }] });
    if (data?.timestampAverages) {
      const arr = getupdatedSeries();

      setOptions({ ...options, series: [{ data: arr }] });
    }
  }, [data]);

  return (
    <Paper
      elevation={4}
      sx={() => ({
        width: '100%',
        height: '50%',
        padding: '16px',
        marginTop: '16px',
      })}
    >
      <Typography variant="subtitle1">{getTitleString()}</Typography>

      <ReactEcharts option={options} />
    </Paper>
  );
};

export default LineChart;
