import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '@/components/utils/ConfirmDialog';
import DeleteStatusDialog from './DeleteStatusDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { InstanceContext } from '@/contexts/InstanceContext';

const DeleteInstanceAction = () => {
  const { t } = useTranslation();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState<'idle' | 'loading' | 'success' | 'error'>(
    'idle',
  );
  const { deleteInstance } = useContext(InstanceContext);
  const navigate = useNavigate();

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
  };

  const handleDelete = async () => {
    closeConfirmDialog();
    setDeleteStatus('loading');
    const res = await deleteInstance();
    if (res) {
      setDeleteStatus('success');
    } else setDeleteStatus('error');
  };

  const handleNavigate = () => {
    navigate('/');
  };

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          padding: 1,
          right: 0,
          top: 0,
          bottom: 0,
          background: 'rgba(255,0,0,0.15)',
        }}
        color="error"
        onClick={() => setConfirmOpen(true)}
      >
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog
        bodyText={t('Are you sure you want to delete this activity?')}
        onClose={closeConfirmDialog}
        onConfirm={handleDelete}
        title={t('Delete Activity')}
        open={confirmOpen}
        yesNo
      />
      <DeleteStatusDialog status={deleteStatus} onClose={handleNavigate} />
    </>
  );
};

export default DeleteInstanceAction;
