import { InputLabel, TextField, Typography, Box } from '@mui/material';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { useContext, useState, useEffect } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useTranslation } from 'react-i18next';
import { validateName } from '@/components/utils/Validate';
import SuggestionCard from '@/components/activity/createActivity/SuggestionCard';
import { AiApi, languageKey } from '@api';
import { CalendarContext } from '@/contexts/CalendarContext';
import { Suggestion } from '@/api';
import CircularProgress from '@mui/material/CircularProgress';

type TitleFormProps = {
  errorOnEmpty?: boolean;
  validateForm?: boolean;
};

const TitleForm = ({ errorOnEmpty = false, validateForm = true }: TitleFormProps) => {
  const formHandler = useContext(ActivityFormContext);
  const { activity, setActivity } = useContext(ActivityContext);
  const [error, setError] = useState<boolean | string>(false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const aiEnabled = localStorage.getItem('aiEnabled') === 'true';

  const isLanguageKey = (lang: any): lang is languageKey => {
    return ['sv', 'en', 'de', 'fr', 'es', 'it', 'pl'].includes(lang);
  };

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(true);
  const { weekInstances } = useContext(CalendarContext);

  useEffect(() => {
    const error = validateName(activity.activityName, errorOnEmpty, t);
    if (error === null && activity.activityName.trim().length) {
      validateForm && formHandler.setValid(true);
      setError(false);
    } else {
      validateForm && formHandler.setValid(false);
      if (error) setError(error);
      else setError('');
    }
  }, [activity]);

  useEffect(() => {
    const fetchSuggestion = async () => {
      const suggestions = await new AiApi().activitySuggestions(
        weekInstances,
        isLanguageKey(lang) ? lang : 'sv',
      );
      setSuggestions(suggestions);
      setSuggestionsLoading(false);
    };
    if (aiEnabled) fetchSuggestion();
  }, []);

  return (
    <>
      <InputLabel htmlFor="activityTitle">{t('Activity title')}:</InputLabel>
      <TextField
        id="activityTitle"
        placeholder={`${t('Type here')}...`}
        variant="outlined"
        value={activity.activityName}
        onChange={(e) => setActivity({ ...activity, activityName: e.target.value })}
        error={!!error}
        helperText={error}
        onKeyDown={(event) => {
          if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
        }}
      />
      {aiEnabled && (
        <>
          <Typography
            variant="subtitle1"
            component="h2"
            pt={4}
            fontWeight={'bold'}
            color={'GrayText'}
          >
            {t('AI-suggestions')}
          </Typography>
          {suggestions.length > 0 ? (
            <>
              {suggestions.map((suggestion, index) => (
                <SuggestionCard suggestion={suggestion} key={index} />
              ))}
            </>
          ) : (
            <>
              {suggestionsLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography variant="body2">{t('No suggestions available')}</Typography>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TitleForm;
