import { boardRequestBody, noteRequestBody, toBoard } from './../types/Note';
/* tslint:disable */
/* eslint-disable */

import * as runtime from '../runtime';
import { BaseAPI } from '../baseApi';
import { Board, Note, toNote } from '../types';
import { HttpStatusCode } from 'axios';

export interface UpdateNotesPositionRequest {
  notes: Note[];
  supervisedTag?: string;
}
export interface EditNoteRequest {
  note: Note;
  supervisedTag?: string;
}

export interface CreateNoteRequest {
  note: Note;
  supervisedTag?: string;
}

export interface RemoveNoteRequest {
  noteId: number;
  supervisedTag?: string;
}

export interface GetBoardsRequest {
  supervisedTag?: string;
}

export interface EditBoardRequest {
  board: Board;
  supervisedTag?: string;
}

export interface CreateBoardRequest {
  board: Board;
  supervisedTag?: string;
}

export interface RemoveBoardRequest {
  boardId: number;
  supervisedTag?: string;
}
export interface UpdateBoardsPositionRequest {
  boards: Board[];
  supervisedTag?: string;
}

export class NoteApi extends BaseAPI {
  async editNote(requestParameters: EditNoteRequest): Promise<Note | undefined> {
    const queryParameters: any = {};
    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';
    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/notes`,
        method: 'PUT',
        query: queryParameters,
        headers: headerParameters,
        body: runtime.toRequestBody(noteRequestBody(requestParameters.note)),
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Note => toNote(jsonValue),
      ).value();
    } catch (error) {}
  }

  async createNote(requestParameters: CreateNoteRequest): Promise<Note | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/notes`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: runtime.toRequestBody(noteRequestBody(requestParameters.note)),
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Note => toNote(jsonValue),
      ).value();
    } catch (error) {}
  }

  async removeNote(requestParameters: RemoveNoteRequest): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/notes/${requestParameters.noteId}`,
        method: 'DELETE',
        query: queryParameters,
      });

      return await response.status;
    } catch (error) {}
  }

  async updateNotesPosition(
    requestParameters: UpdateNotesPositionRequest,
  ): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/notes/edit-positions`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.notes.map(noteRequestBody),
      });

      return response.status;
    } catch (error) {}
  }

  async editBoard(requestParameters: EditBoardRequest): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/note-boards`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: runtime.toRequestBody(boardRequestBody(requestParameters.board)),
      });

      return response.status;
    } catch (error) {}
  }

  async createBoard(requestParameters: CreateBoardRequest): Promise<Board | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/note-boards`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: runtime.toRequestBody(boardRequestBody(requestParameters.board)),
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Board => toBoard(jsonValue),
      ).value();
    } catch (error) {}
  }

  async getBoards(requestParameters: GetBoardsRequest): Promise<Board[] | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/note-boards`,
        method: 'GET',
        query: queryParameters,
      });

      return await new runtime.JSONApiResponse(response, (jsonValue): Board[] =>
        jsonValue.map(toBoard),
      ).value();
    } catch (error) {}
  }

  async removeBoard(requestParameters: RemoveBoardRequest): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/note-boards/${requestParameters.boardId}`,
        method: 'DELETE',
        query: queryParameters,
      });

      return response.status;
    } catch (error) {}
  }

  async updateBoardPosition(
    requestParameters: UpdateBoardsPositionRequest,
  ): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/note-boards/positions`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.boards.map(boardRequestBody),
      });

      return response.status;
    } catch (error) {}
  }
}
