import { Box } from '@mui/material';

type ColorCircleProps = {
  color: string;
  isSelected: boolean;
  onClick: () => void;
};

const ColorCircle = ({ color, isSelected, onClick }: ColorCircleProps) => {
  const width = 2.5;
  const widthInEm = width + 'em';
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          width: widthInEm,
          height: widthInEm,
          borderRadius: '50%',
          backgroundColor: color,
          margin: 1,
          border: '1px solid gray',
        }}
      ></Box>
      <Box
        sx={{
          width: widthInEm,
          height: width / 8 + 'em',
          borderRadius: width / 2 + 'em',
          backgroundColor: color,
          opacity: isSelected ? 1 : 0,
        }}
      ></Box>
    </Box>
  );
};

export default ColorCircle;
