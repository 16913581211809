/* tslint:disable */
/* eslint-disable */

import { BaseAPI } from '../baseApi';
import { Contact } from '../types';
import * as runtime from '../runtime';

export interface getStatisticsRequest {
  startDate: Date;
  endDate: Date;
  supervisedTag?: string;
}

export interface getDailyStatisticsRequest {
  date: Date;
  supervisedTag?: string;
}

export class StatisticsAPI extends BaseAPI {
  async getStatistics(requestParameters: getStatisticsRequest, intervalType: string): Promise<any> {
    const intervals: any = {
      day: 'statistics/daily',
      week: 'statistics/weekly',
      month: 'statistics/monthly',
      year: 'statistics/yearly',
    };

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.startDate !== undefined) {
      queryParameters['intervalStartDate'] = (requestParameters.startDate as any)
        .toISOString()
        .slice(0, 10);
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters['intervalEndDate'] = (requestParameters.endDate as any)
        .toISOString()
        .slice(0, 10);
    }

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: intervals[intervalType],
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      });

      const res: any = await new runtime.JSONApiResponse(
        response,
        (jsonValue): Array<any> => jsonValue,
      ).value();

      if (intervalType === 'year' && typeof res !== 'string') {
        const pixelResponse = await this.request({
          path: 'statistics/pixel',
          method: 'GET',
          headers: headerParameters,
          query: queryParameters,
        });

        const pixelRes = await new runtime.JSONApiResponse(
          pixelResponse,
          (jsonValue): Array<any> => jsonValue,
        ).value();

        res.pixels = pixelRes;
      }

      if (res) return res;
      return {};
    } catch (error) {
      console.error(error);
    }
  }

  async getDailyStatistics(requestParameters: getDailyStatisticsRequest): Promise<any> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.date !== undefined) {
      queryParameters['date'] = (requestParameters.date as any).toISOString().slice(0, 10);
    }
    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/statistics/daily`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      });

      const res = await new runtime.JSONApiResponse(
        response,
        (jsonValue): Array<any> => jsonValue,
      ).value();

      if (res) return res;

      return {};
    } catch (error) {}
  }
}
