import {
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext, useState, useEffect, useMemo } from 'react';
import { NavigationItems } from './NavigationItems';
import { Link } from 'react-router-dom';
import { AppContext } from '@/contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CalendarContext } from 'src/contexts/CalendarContext';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const Navbar = () => {
  const location = useLocation();
  const theme = useTheme();
  const smallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const getCurrentIndex = () => {
    const index = NavigationItems.findIndex((item) => item.link === location.pathname);
    return Math.max(index, 0);
  };
  const [value, setValue] = useState(getCurrentIndex());
  const { navbarRef, showNavbar } = useContext(AppContext);
  const { goToCurrentWeek } = useContext(CalendarContext);
  const { t } = useTranslation();

  useEffect(() => {
    setValue(getCurrentIndex());
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (NavigationItems[newValue].text === 'Daily') goToCurrentWeek();
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigation: {
    items: JSX.Element[];
    moreItems: JSX.Element[] | null;
  } = useMemo(() => {
    // if the screen is small and down, show only the first 3 items, and a "more" popup modal.
    const items = smallAndDown ? NavigationItems.slice(0, 3) : NavigationItems;
    const popupItems = smallAndDown ? NavigationItems.slice(3) : [];
    return {
      items: [
        ...items.map(({ link, text, icon }) => (
          <BottomNavigationAction
            aria-label={link}
            label={t(text)}
            icon={icon}
            component={Link}
            to={link}
            key={link}
            sx={{
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.lineLight}`,
              },
            }}
          ></BottomNavigationAction>
        )),
        smallAndDown ? (
          <BottomNavigationAction
            aria-label={t('Show More') ?? 'Show More'}
            label={t('More')}
            icon={<MoreHorizIcon />}
            id="more-nav-menu-button"
            aria-controls={open ? 'more-nav-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.lineLight}`,
              },
            }}
          />
        ) : (
          <></>
        ),
      ],
      moreItems: smallAndDown
        ? popupItems.map(({ link, text, icon }) => (
            <MenuItem
              component={Link}
              to={link}
              key={link}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: `${theme.palette.lineLight}`,
                },
                color: 'rgb(0, 0, 0, 0.6)',
              }}
              onClick={handleClose}
            >
              {icon}
              <Typography variant="h6" sx={{ ml: 1 }}>
                {t(text)}
              </Typography>
            </MenuItem>
          ))
        : null,
    };
  }, [t, theme, smallAndDown]);

  return (
    <Paper
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        visibility: showNavbar ? 'visible' : 'hidden',
        width: '100%',
        maxWidth: '100%',
      }}
      elevation={3}
      ref={navbarRef}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
        sx={{ justifyContent: smallAndDown ? 'space-evenly' : 'center' }}
      >
        {navigation.items}
      </BottomNavigation>
      <Menu
        id="more-nav-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-nav-menu-button',
        }}
      >
        {navigation.moreItems}
      </Menu>
    </Paper>
  );
};

export default Navbar;
