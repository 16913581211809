import { ActivityInstance } from '@api';
import * as runtime from '../runtime';
import { BaseAPI } from '../baseApi';
import { SubtaskItem } from '@api';
import { loadAIProfile } from '@/contexts/AIProfileContext';
/* tslint:disable */
/* eslint-disable */

export type Suggestion = {
  activityName: string;
  startDate: string;
  duration: number;
  description: string;
  subtasks?: SubtaskItem[];
};

export type languageKey = 'sv' | 'en' | 'de' | 'fr' | 'es' | 'it' | 'pl';

const LanguageMapper: Record<languageKey, string> = {
  sv: 'Svenska',
  en: 'Engelska',
  de: 'Tyska',
  fr: 'Franska',
  es: 'Spanska',
  it: 'Italienska',
  pl: 'Polska',
};

export class AiApi extends BaseAPI {
  async prompt(promptText: string): Promise<string> {
    const headerParameters: runtime.HTTPHeaders = {
      'Content-Type': 'application/json',
    };

    try {
      // Making the POST request to the /ai endpoint
      const response = await this.request({
        path: `/ai`, // Ensure this matches your backend endpoint
        method: 'POST',
        headers: headerParameters,
        body: JSON.stringify({ prompt: promptText }), // Send the prompt text in the body
      });

      // Process the response and return the result
      return response.data.choices[0].message.content ?? '';
    } catch (error) {
      console.error('Error in promptAi:', error);
      // Handle or throw the error as needed
      return '';
    }
  }

  // Rensa cachen
  clearSuggestionCache = () => {
    localStorage.removeItem('suggestionCache');
    localStorage.removeItem('suggestionCache_exp');
    localStorage.removeItem('suggestionCache_language');
  };

  // Spara data i cachen
  saveSuggestionCache = (data: string, language: languageKey) => {
    const suggestionCacheTimeLimit = 60 * 60 * 1000;
    localStorage.setItem('suggestionCache', JSON.stringify(data));
    localStorage.setItem('suggestionCache_exp', (Date.now() + suggestionCacheTimeLimit).toString());
    localStorage.setItem('suggestionCache_language', language);
  };

  getSuggestionCache = (language: languageKey) => {
    const cacheLanguage = localStorage.getItem('suggestionCache_language');
    const expiration = localStorage.getItem('suggestionCache_exp');
    if (expiration && Date.now() < parseInt(expiration, 10) && cacheLanguage === language) {
      const data = localStorage.getItem('suggestionCache');
      return data ? JSON.parse(data) : null;
    }
    // Rensa cache om den är för gammal
    this.clearSuggestionCache();
    return null;
  };

  activitySuggestions = async (
    activities: ActivityInstance[],
    language: languageKey,
  ): Promise<Suggestion[]> => {
    const cachedData = this.getSuggestionCache(language);
    if (cachedData) {
      try {
        const parsedCache: Suggestion[] = JSON.parse(cachedData);
        if (Array.isArray(parsedCache) && parsedCache.length === 3) return parsedCache;
        else this.clearSuggestionCache();
      } catch (e) {
        console.error('Error parsing cached data:', e);
        //There might be something wrong with the cache, clear it
        this.clearSuggestionCache();
      }
    }

    let suggestionPrompt =
      'Ge tre förslag på aktiviteter baserade på följande lista av aktiviteter som användaren redan har planerat:';
    const activityPrompts = activities.map(
      (activity, index) =>
        `${index + 1}. ${activity.activityName}, Duration: ${
          activity.duration
        } minutes, Start Date: ${activity.startDate}`,
    );
    const activitiesPrompt = activityPrompts.join('\n');
    suggestionPrompt += `\n${activitiesPrompt}`;

    // Add information about the user
    const AIProile = loadAIProfile();
    if (AIProile != null) {
      suggestionPrompt += `
      Här är lite information om användaren:
      - ${AIProile.gender}, ${AIProile.age} år
      - Föredragna typer av aktiviteter: ${AIProile.preferredActivities.join(', ')}
      `;
    }

    //Get current date
    const currentDate = new Date().toISOString();
    //Get next sunday 23:59
    const lastDayOfWeek = new Date();
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + (7 - lastDayOfWeek.getDay()));
    lastDayOfWeek.setHours(23, 59, 59);
    const lastDayOfWeekFormatted = lastDayOfWeek.toISOString();

    suggestionPrompt += `
    Aktiviteterna ska vara i samma stil som de tidigare planerade och utföras mellan ${currentDate} och ${lastDayOfWeekFormatted}.
    Försök hitta 3 olika typer av aktiviteter, och gärna sådana typer som inte redan planerats in, eller som passar bra in i schemat. De kan utgå, men behöver inte helt vara baserade
    på de aktivitetstyper användaren har som föredragna aktivitetstyper.
  
    Försök att hitta en ledig lucka i schemat där ingen annan aktivitet är planerad och lägg till aktiviteter där. Ingen aktivitet ska skapas mellan 22:00 och 07:00 (på natten).
  
    Undvik aktiviteter som kräver förbokning eller speciell utrustning som användaren inte har tillgång till.

    Formatet ska vara en JSON-array med exakt tre objekt:
    [
      {
        "activityName": "string",
        "startDate": "Date",
        "duration": number,
        "description": "string",
        "subtasks": [
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
        ]
      },
      {
        "activityName": "string",
        "startDate": "Date",
        "duration": number,
        "description": "string",
        "subtasks": [
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
        ]
      },
      {
        "activityName": "string",
        "startDate": "Date",
        "duration": number,
        "description": "string",
        "subtasks": [
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
          {subtaskId: number, subtaskText: "string", checked: false, picture: null},
        ]
      }
    ]
  
    Använd alltid dubbla citattecken ("") för nycklar och textvärden så att svaret är i giltigt JSON-format. Returnera endast arrayen i det angivna formatet utan ytterligare text eller symboler
    
    Notera att en av aktiviteterna innehåller "subtasks" som är en array av objekt. Om det inte finns några subtasks, returnera en tom array.
    Generera subtasks om det är passande för aktiviteten. subtaskID ska vara ett unikt ID för varje subtask. Subtaks måste INTE vara EXAKT 3 st, det kan vara 0-5 st beroende på hur många som är passande.
  
    Svaret ska genereras på ${LanguageMapper[language]}. Var noga med att stava rätt och använda korrekt grammatik.
    
    Viktigt: activityName, description och subtaskText får inte innehålla dessa tecken: /[<>;'"/\\[\]{}_\\&*@^:=!|+?#%~]/;
    `;

    const response = await this.prompt(suggestionPrompt);
    this.saveSuggestionCache(response, language);
    try {
      const parsedResponse = JSON.parse(response);
      if (Array.isArray(parsedResponse) && parsedResponse.length === 3) {
        return parsedResponse;
      } else {
        console.error('Invalid response from AI:', response);
        return [];
      }
    } catch (e) {
      console.error('Error parsing JSON:', e);
      return [];
    }
  };

  statisticsAnalysis = async (
    statistics: object,
    name: string,
    language: languageKey,
  ): Promise<string> => {
    let suggestionPrompt = `
      Analysera statistiken och ge en väldigt kort (Max 50 ord) sammanfattning av vad som kan förbättras i användarens schema och vad som är bra.
      Efter en användare avslutat en aktivitet kan de välja att betygsätta den. Betyget är en siffra mellan 1 och 5 där 1 är dåligt och 5 är bra. 0 betyder att användaren inte har betygsatt aktiviteten (Alltså inte lägsta betyg).

      Användaren heter: ${name}.

      Svaret ska genereras på ${LanguageMapper[language]}. Var noga med att stava rätt och använda korrekt grammatik.

      Här är statistiken för användarens aktiviteter:
    `;

    suggestionPrompt += '\n' + JSON.stringify(statistics);

    return await this.prompt(suggestionPrompt);
  };
}
