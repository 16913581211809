import { Box } from '@mui/material';
import { ActivityScene } from '@/contexts/ActivityContext';
import CreateActivityHeader from '@/components/activity/createActivity/CreateActivityHeader';
import CreateActivityFooter from '@/components/activity/createActivity/CreateActivityFooter';
import ActivityFormScene from '@/contexts/ActivityFormContext';
import CreateActivityContent from '@/components/activity/createActivity/CreateActivityContent';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '@/contexts/AppContext';
import { SupervisorContext } from 'src/contexts/SupervisorContext';

const CreateActivityPage = () => {
  const { setShowNavbar, pageHeight } = useContext(AppContext);
  const { supervisorHeaderHeight } = useContext(SupervisorContext);

  useEffect(() => {
    setShowNavbar(false);
    return () => setShowNavbar(true);
  }, []);

  return (
    <ActivityScene useStoredActivity>
      <ActivityFormScene>
        <Box
          sx={{
            minHeight: `calc(${pageHeight}px - ${supervisorHeaderHeight}px)`,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CreateActivityHeader />
          <CreateActivityContent />
          <CreateActivityFooter />
        </Box>
      </ActivityFormScene>
    </ActivityScene>
  );
};

export default CreateActivityPage;
