import { createContext, useState } from 'react';

type props = {
  children?: React.ReactNode;
};

export type AIProfileForm = {
  age: number | undefined;
  gender: string | undefined;
  interests: string | undefined;
  preferredActivities: string[];
};

export type AIForm = {
  component: () => JSX.Element;
  title: string;
};

type AIProfileContextType = {
  form: AIProfileForm;
  setForm: (form: AIProfileForm) => void;
  saveAIProfile: () => void;
};

export const AIFormsContext = createContext<AIProfileContextType>({} as AIProfileContextType);

export const loadAIProfile = (): AIProfileForm | null => {
  const data = localStorage.getItem('AIProfileForm');

  if (!data) {
    return null;
  }

  try {
    const parsedData = JSON.parse(data);
    // Validate the parsed object
    if (
      typeof parsedData === 'object' &&
      parsedData !== null &&
      (typeof parsedData.age === 'number' || parsedData.age === undefined) &&
      (typeof parsedData.gender === 'string' || parsedData.gender === undefined) &&
      (typeof parsedData.interests === 'string' || parsedData.interests === undefined) &&
      Array.isArray(parsedData.preferredActivities) &&
      parsedData.preferredActivities.every((activity: any) => typeof activity === 'string')
    ) {
      return parsedData as AIProfileForm;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

//ToDo This component is rendered twice when setActivity is called, it should not do that
export const AIProfileyScene = ({ children }: props) => {
  const [form, setForm] = useState<AIProfileForm>({
    age: undefined,
    gender: undefined,
    interests: undefined,
    preferredActivities: [],
  });

  //Function to save form in local storage
  const saveAIProfile = () => {
    localStorage.setItem('AIProfileForm', JSON.stringify(form));
  };

  return (
    <AIFormsContext.Provider
      value={{
        form: form,
        setForm: setForm,
        saveAIProfile: saveAIProfile,
      }}
    >
      {children}
    </AIFormsContext.Provider>
  );
};
