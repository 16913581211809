import { Container, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';

const CreateActivityContent = () => {
  const formHandler = useContext(ActivityFormContext);

  return (
    <Container sx={{ paddingTop: 3 }}>
      <Stack justifyContent={'center'}>{React.createElement(formHandler.current.component)}</Stack>
    </Container>
  );
};

export default CreateActivityContent;
