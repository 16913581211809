/* Component is only rendered when activity.recurringActivity != null */
import { ActivityContext } from '@/contexts/ActivityContext';
import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';

const SubtaskText = () => {
  const { activity } = useContext(ActivityContext);
  const { palette } = useTheme();

  return (
    <ol
      style={{
        margin: 0,
        listStylePosition: 'inside',
        padding: 0,
        marginTop: '8px',
        color: palette.grey[500],
      }}
    >
      {activity.subtasks?.map((item, index) => (
        <li style={{ overflowWrap: 'break-word' }} key={item.subtaskId}>
          {item.subtaskText}
        </li>
      ))}
    </ol>
  );
};

export default SubtaskText;
