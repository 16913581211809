import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { StatisticsContext } from 'src/contexts/StatisticsContext';
import { getWeekString, getMonthString, getYearString } from '../utils/DateUtils';
import { useTranslation } from 'react-i18next';

const IntervalSelect = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];

  const { intervalType, interval, nextPeriod, prevPeriod } = useContext(StatisticsContext);

  const [dateText, setDateText] = useState<string>('');
  const [isfuture, setIsFuture] = useState<boolean>(false);
  const { palette } = useTheme();

  const isInTheFuture = () => {
    if (intervalType === 'day') setIsFuture(new Date().getDate() <= interval.start.getDate());
    else if (intervalType === 'week') setIsFuture(new Date() < interval.end);
    else if (intervalType === 'month')
      setIsFuture(new Date().getMonth() >= interval.start.getMonth());
    else if (intervalType === 'year')
      setIsFuture(new Date().getFullYear() <= interval.start.getFullYear());
    else return setIsFuture(false);
  };

  useEffect(() => {
    isInTheFuture();

    if (intervalType === 'day') setDateText(`${interval.start.toLocaleDateString()}`);
    else if (intervalType === 'week') setDateText(`${t('Week')} ${getWeekString(interval.start)}`);
    else if (intervalType === 'month') setDateText(getMonthString(interval.start, lang));
    else if (intervalType === 'year') setDateText(getYearString(interval.start));
  }, [interval]);

  return (
    <Stack
      direction={'row'}
      justifyContent="space-evenly"
      alignItems="center"
      color={palette.primary.main}
    >
      <IconButton onClick={prevPeriod} color="primary">
        <ArrowLeftIcon fontSize="large" />
      </IconButton>
      <Typography variant="h6">{dateText}</Typography>
      <IconButton onClick={nextPeriod} color="primary" disabled={isfuture}>
        <ArrowRightIcon fontSize="large" />
      </IconButton>
    </Stack>
  );
};

export default IntervalSelect;
