import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import BoardItem, { BoardItemProps } from './BoardItem';

//Wrapper for NoteItem that makes it sortable and draggable
const SortableBoardItem = (props: BoardItemProps) => {
  const { isDragging, setNodeRef, transform, transition } = useSortable({
    id: props.board.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return <BoardItem ref={setNodeRef} style={style} invisible={isDragging} {...props} />;
};

export default SortableBoardItem;
