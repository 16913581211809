import { useNavigate } from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { IconButton, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditAction from './EditAction';
import { CalendarContext } from 'src/contexts/CalendarContext';
import { useContext } from 'react';

const ViewActivityHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { refreshWeek } = useContext(CalendarContext);

  const handleBack = () => {
    refreshWeek();
    navigate(-1);
  };

  return (
    <>
      <Box sx={{ m: 2, position: 'relative' }}>
        <IconButton
          sx={{ position: 'absolute', padding: 1, bgcolor: 'line', top: 0, bottom: 0 }}
          onClick={handleBack}
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', padding: 0.5 }}>
          {t('View Activity')}
        </Typography>
        <EditAction />
      </Box>
    </>
  );
};

export default ViewActivityHeader;
