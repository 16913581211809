import { createContext, useContext } from 'react';
import { CalendarContext } from './CalendarContext';
import { ActivityInstance } from '@api';
import { compareAsc } from 'date-fns';

type props = {
  date: Date;
  children?: React.ReactNode;
};

export type CalendarDayContextType = {
  instances: ActivityInstance[];
  date: Date;
};

export const CalendarDayContext = createContext<CalendarDayContextType>(
  {} as CalendarDayContextType,
);

export const CalendarDayScene = ({ date, children }: props) => {
  const { weekInstances } = useContext(CalendarContext);
  //Get instances from instances where startDate == date
  const filteredInstances = weekInstances
    ? weekInstances
        .filter((instance) => {
          const instanceDate = new Date(instance.startDate);
          //Add duration (in minutes) to startDate to get endDate
          const instanceEndDate = new Date(instanceDate.getTime() + instance.duration * 60000);
          return (
            (instanceDate.getDate() === date.getDate() &&
              instanceDate.getMonth() === date.getMonth() &&
              instanceDate.getFullYear() === date.getFullYear()) ||
            (instanceEndDate.getDate() === date.getDate() &&
              instanceEndDate.getMonth() === date.getMonth() &&
              instanceEndDate.getFullYear() === date.getFullYear())
          );
        })
        .sort((a, b) => compareAsc(a.startDate, b.startDate))
    : [];

  return (
    <CalendarDayContext.Provider
      value={{
        instances: filteredInstances,
        date: date,
      }}
    >
      {children}
    </CalendarDayContext.Provider>
  );
};
