import { Routes, Route } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import DailyPage from '../pages/daily/DailyPage';
import CreateActivityPage from '../pages/activity/CreateActivityPage';
import NotesPage from '../pages/notes/NotesPage';
import WeekPage from '../pages/week/WeekPage';
import ViewActivityPage from '../pages/activity/ViewActivityPage';
import EditActivityPage from '../pages/activity/EditActivityPage';
import { CalendarScene } from '../contexts/CalendarContext';
import SettingsPage from '../pages/settings/SettingsPage';
import NotFoundPage from '../pages/NotFound/NotFoundPage';
import SuperviseGuard from './SuperviseGuard';
import PushNotificationProvider from '../PushNotificationsProvider';
import StatisticsPage from 'src/pages/Statistics/StatisticsPage';
import AIProfilePage from 'src/pages/ai/AIProfilePage';

const AuthRouter = () => {
  return (
    <CalendarScene>
      <PushNotificationProvider />
      <Layout>
        <Routes>
          <Route
            path={'/'}
            element={
              <SuperviseGuard>
                <DailyPage />
              </SuperviseGuard>
            }
          />
          <Route
            path={'/login'}
            element={
              <SuperviseGuard>
                <DailyPage />
              </SuperviseGuard>
            }
          />
          <Route
            path={'/create-activity/:formId'}
            element={
              <SuperviseGuard>
                <CreateActivityPage />
              </SuperviseGuard>
            }
          />
          <Route
            path={'/activity/:instanceId'}
            element={
              <SuperviseGuard>
                <ViewActivityPage />
              </SuperviseGuard>
            }
          />
          <Route
            path={'/edit-activity/:instanceId/:editSingleActivity/:formId'}
            element={
              <SuperviseGuard>
                <EditActivityPage />
              </SuperviseGuard>
            }
          />
          <Route
            path={'/week'}
            element={
              <SuperviseGuard>
                <WeekPage />
              </SuperviseGuard>
            }
          />
          <Route
            path={'/notes'}
            element={
              <SuperviseGuard>
                <NotesPage />
              </SuperviseGuard>
            }
          />
          <Route
            path={'/statistics'}
            element={
              <SuperviseGuard>
                <StatisticsPage />
              </SuperviseGuard>
            }
          />
          <Route
            path={'/ai-profile'}
            element={
              <SuperviseGuard>
                <AIProfilePage />
              </SuperviseGuard>
            }
          />
          <Route path={'/settings'} element={<SettingsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </CalendarScene>
  );
};

export default AuthRouter;
