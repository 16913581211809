// SuccessAnimation.tsx
import React from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { SxProps, Theme } from '@mui/system';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';

const SuccessAnimation: React.FC = () => {
  const spinGrowBounceAnimation = `
    @keyframes spinGrowBounce {
      0% {
        transform: scale(0) rotate(0deg);
        opacity: 0;
      }
      60% {
        transform: scale(1) rotate(380deg);
        opacity: 1;
      }
      80% {
        transform: scale(1) rotate(350deg);
        opacity: 1;
      }
      100% {
        transform: scale(1) rotate(360deg);
        opacity: 1;
      }
    }
  `;

  const { palette } = useTheme();

  const checkmarkIconStyle: SxProps<Theme> = {
    fontSize: 60,
    color: palette.success.main,
    animation: 'spinGrowBounce 1.2s forwards',
  };

  return (
    <Box sx={{ position: 'relative', zIndex: 999 }}>
      <style>{spinGrowBounceAnimation}</style>
      <CheckRoundedIcon sx={checkmarkIconStyle} />
    </Box>
  );
};

export default SuccessAnimation;
