import { Box } from '@mui/material';
import React, { useEffect, useState, MutableRefObject } from 'react';
//A component that renders each day
const TimeIntervalRenderer = ({
  children,
  times,
  parentRef,
}: {
  children: React.ReactElement;
  times: number;
  parentRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const [parentHeight, setParentHeight] = useState(0); // store the parent height in state
  const [distance, setDistance] = useState(0); // store the distance between each number component in state

  const numbers = Array.from({ length: times + 1 }, (_, i) => i);

  useEffect(() => {
    // get the parent height when the component mounts
    if (parentRef)
      if (parentRef.current) {
        const height = parentRef.current.getBoundingClientRect().height;
        setParentHeight(height);
      }
  }, [parentRef]);

  useEffect(() => {
    // calculate the distance between each number component based on the parent height and the number of components
    if (parentHeight > 0) {
      const distance = parentHeight / times;
      setDistance(distance);
    }
  }, [parentHeight]);
  return (
    <>
      {numbers.map((number) => (
        <Box
          sx={{
            position: 'absolute',
            top: `${distance * number}px`,
            left: '0',
            transform: 'translateY(-50%)',
            width: '100%',
            height: `${distance}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          key={number}
        >
          {React.cloneElement(children, { index: number })}
        </Box>
      ))}
    </>
  );
};

export default TimeIntervalRenderer;
