import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  bodyText: string;
  yesNo?: boolean;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  bodyText,
  yesNo,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const maxWidth = isSmallScreen ? 'xs' : 'sm';
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogTitle id="confirm-dialog-title" style={{ textAlign: 'center' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textPrimary">
          {bodyText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {yesNo ? t('No') : t('Cancel')}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus variant="contained">
          {yesNo ? t('Yes') : t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
