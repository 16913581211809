import ActivityCard from '@/components/activity/ActivityCard';
import { Stack, Typography, Box } from '@mui/material';
import { CalendarDayContext } from '@/contexts/CalendarDayContext';
import { useContext, useMemo } from 'react';
import { isBefore, isDate } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MissedActivities = () => {
  const { instances } = useContext(CalendarDayContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const missedInstances = useMemo(
    () =>
      instances.filter(
        (instance) => isBefore(instance.startDate, new Date()) && !isDate(instance.dateIsStarted),
      ),
    [instances],
  );

  const viewActivity = (activityInstanceId: number) => () => {
    navigate(`/activity/${activityInstanceId}`);
  };

  return (
    <Stack spacing={2}>
      {missedInstances.length > 0 && (
        <>
          <Typography variant="subtitle2">{t('Missed Activities')}</Typography>
          {missedInstances.map((instance, index) => (
            <Box onClick={viewActivity(instance.activityInstanceId)} key={index} display={'block'}>
              <ActivityCard key={index} activityInstance={instance} type={'missed'}></ActivityCard>
            </Box>
          ))}
        </>
      )}
    </Stack>
  );
};

export default MissedActivities;
