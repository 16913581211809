import { Button, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SimpleList from '../utils/list/SimpleList';
import Delete from '@mui/icons-material/Delete';
import { useContext, useState } from 'react';
import { Supervisor, SupervisorApi, SupervisorConnection } from '@api';
import { SupervisorContext } from 'src/contexts/SupervisorContext';
import BasicDialog from '../utils/dialogs/BasicDialog';
import { AppContext } from 'src/contexts/AppContext';

const ManageSupervisorsSetting = () => {
  const { t } = useTranslation();
  const [newSupervisor, setNewSupervisor] = useState('');
  const [error, setError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [removeSupervisorDialog, setRemoveSupervisorDialog] = useState<undefined | Supervisor>();
  const { supervisors, refresh, supervisorRequests } = useContext(SupervisorContext);
  const { user } = useContext(AppContext);

  const outgoingRequests = supervisorRequests?.filter(
    (request) => request.client.tag === user?.tag,
  );

  const validateTag = () => {
    const pattern = /^[A-Z]{4}#\d{4}$/;
    if (pattern.test(newSupervisor)) {
      setError('');
      return true;
    }
    if (newSupervisor.length === 0) setError('Tag cannot be empty');
    else setError('Invalid tag, expected format: ABCD#1234');
    return false;
  };

  const removeSupervisor = async () => {
    if (removeSupervisorDialog) {
      const res = await new SupervisorApi().removeSupervisor({
        supervisor: removeSupervisorDialog,
      });
      if (res) refresh();
    }
  };

  const addSupervisor = async () => {
    const res = await new SupervisorApi().createSupervisor({ tag: newSupervisor });
    if (res) {
      refresh();
      setNewSupervisor('');
    }
  };

  const handleAddSupervisor = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (validateTag()) {
      setDialogOpen(true);
    }
  };

  const getPendingListText = (request: SupervisorConnection) => {
    const { supervisor, acceptedConnection } = request;
    return `${supervisor.tag} - ${acceptedConnection === null ? t('Pending') : t('Declined')}`;
  };

  return (
    <Stack spacing={3}>
      <Typography variant="subtitle2">{t('Information for supervisor client')}</Typography>
      <Stack>
        <Typography variant="subtitle1">{t('Add supervisor')}</Typography>
        <Stack direction={'row'} sx={{ position: 'relative', minHeight: '5em' }}>
          <TextField
            id="add-supervisor"
            placeholder={`${t('Tag')}`}
            variant="outlined"
            value={newSupervisor}
            onChange={(e) => {
              if (error) setError('');
              setNewSupervisor(e.target.value);
            }}
            error={!!error}
            helperText={t(error)}
            InputProps={{
              sx: {
                borderRadius: '1em',
                '&.MuiOutlinedInput-root': {
                  borderRadius: '1em',
                },
              },
            }}
            sx={{
              width: '100%',
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSupervisor}
            sx={{ position: 'absolute', top: 0, right: 0, borderRadius: '1em', p: 2 }}
          >
            {t('Add')}
          </Button>
        </Stack>
      </Stack>
      {outgoingRequests.length > 0 ? (
        <Typography variant="subtitle1">{t('Pending supervisors')}</Typography>
      ) : null}
      <SimpleList
        items={outgoingRequests.map((request) => getPendingListText(request))}
        icon={<Delete color={'error'} />}
        onIconClick={(tag) => {
          const croppedTag = tag.substring(0, 9);
          const request = outgoingRequests.find((request) => request.supervisor.tag === croppedTag);
          setRemoveSupervisorDialog(request?.supervisor);
        }}
      ></SimpleList>
      {supervisors.length > 0 ? (
        <Typography variant="subtitle1">{t('Supervisors')}</Typography>
      ) : null}
      <SimpleList
        items={supervisors.map((supervisor) => supervisor.tag)}
        icon={<Delete color={'error'} />}
        onIconClick={(tag) =>
          setRemoveSupervisorDialog(supervisors.find((supervisor) => supervisor.tag === tag))
        }
      ></SimpleList>
      <BasicDialog
        open={!!removeSupervisorDialog}
        content={{
          title: t('Remove supervisor'),
          body: `${removeSupervisorDialog?.tag}: ${t('Will be removed as your supervisor')}`,
          confirmText: t('Remove'),
          cancelText: t('Cancel'),
        }}
        onClose={() => setRemoveSupervisorDialog(undefined)}
        onConfirm={removeSupervisor}
      ></BasicDialog>
      <BasicDialog
        open={dialogOpen}
        content={{
          title: t('Add supervisor'),
          body: `${newSupervisor}: ${t('Will be added as your supervisor')}`,
          confirmText: t('Add'),
          cancelText: t('Cancel'),
        }}
        onClose={() => setDialogOpen(false)}
        onConfirm={addSupervisor}
      ></BasicDialog>
    </Stack>
  );
};

export default ManageSupervisorsSetting;
