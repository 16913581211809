import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  Button,
  Stack,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface SubtaskItemProps {
  imgSrc: string;
  title: string;
  checked: boolean;
  id: number;
  onCheckedChange: (id: number) => void;
  imageHeight?: number;
}

const SubtaskItem: React.FC<SubtaskItemProps> = ({
  imgSrc,
  title,
  checked,
  onCheckedChange,
  imageHeight = 50,
  id,
}) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const maxWidth = isSmallScreen ? 'xs' : 'sm';

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [imageLoadError, setImageLoadError] = useState(false);

  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCheckboxChange = () => {
    onCheckedChange(id);
  };

  return (
    <>
      <Stack alignItems="center" direction={'row'} spacing={2}>
        <Button
          color={'secondary'}
          variant={'contained'}
          onClick={handleCheckboxChange}
          sx={{
            border: '1px solid',
            borderColor: checked ? theme.palette.primary.main : 'transparent',
            width: '100%',
            justifyContent: 'space-between',
            my: 1,
          }}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <Checkbox
              color="primary"
              checked={checked}
              inputProps={{ 'aria-label': 'Checkbox for subtask item' }}
            />
            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
              {title}
            </Typography>
          </Stack>
          <Box width={imageHeight} height={imageHeight}>
            {imgSrc && imgSrc.length !== 0 && !imageLoadError && (
              <img
                src={imgSrc}
                alt={'Subtask'}
                height={imageHeight}
                onError={() => setImageLoadError(true)}
                onClick={handleImageClick}
                style={{
                  cursor: 'pointer',
                  aspectRatio: '1/1',
                  borderRadius: '1em',
                  objectFit: 'cover',
                }}
              />
            )}
          </Box>
        </Button>
      </Stack>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth={maxWidth} fullWidth>
        <Box position="relative" overflow={'hidden'}>
          <DialogContent>
            <img src={imgSrc} alt={title} style={{ width: '100%' }} />
          </DialogContent>
          <IconButton
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
            style={{ position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Dialog>
    </>
  );
};

export default SubtaskItem;
