import { createContext, useEffect, useState } from 'react';
import DateForm from '../components/activity/createActivity/DateForm';
import DurationForm from '../components/activity/createActivity/DurationForm';
import MoreOptionsForm from '../components/activity/createActivity/moreOptions/MoreOptionsForm';
import TaskCreated from '../components/activity/createActivity/TaskCreated';
import TitleForm from '../components/activity/createActivity/TitleForm';
import Subtasks from '../components/activity/createActivity/moreOptions/Subtasks/Subtasks';
import SetRecurrence from '../components/activity/createActivity/moreOptions/SetRecurrence';
import EditActivityForm from '../components/activity/editActivity/EditActivityForm';
import ActivityEdited from '../components/activity/editActivity/ActivityEdited';
import ErrorView from '../components/utils/ErrorView';
import { useNavigate, useParams } from 'react-router-dom';

// Declare your formComponents name here
export enum ActivityForms {
  Title = 'Title',
  Date = 'Date',
  Duration = 'Duration',
  Created = 'Created',
  MoreOptions = 'MoreOptions',
  AddSubtasks = 'AddSubtasks',
  SetRecurrence = 'SetRecurrence',
  Edit = 'Edit',
  EditInstance = 'EditInstance',
  Edited = 'Edited',
}

export type ActivityForm = {
  component: (props: any) => JSX.Element;
  formId: string;
  formName: string;
  previousFormAction?: 'ValidateRecurrence';
  nextForm?: ActivityForms | 'Complete';
  nextFormText?: string;
  nextFormAction?: 'Create' | 'Edit' | 'EditSingleActivity' | 'AddSubtasks' | 'AddRecurrence';
  additionalForm?: ActivityForms; // use for more options etc
  additionalFormText?: string; // use for more options etc
  progressValue?: number;
  isValid?: boolean;
  noHeader?: boolean;
  noActivityName?: boolean;
  noFormName?: boolean;
};

type ComponentLookupTable = {
  [key: string]: ActivityForm;
};

/*
define your form component here
*/
export const DefaultActivityFormsDefs: ComponentLookupTable = {
  Title: {
    component: TitleForm,
    formId: 'Title',
    formName: 'Title',
    nextForm: ActivityForms.Date,
    nextFormText: 'Next: Date & Time',
    progressValue: 1,
    isValid: false,
    noFormName: true,
    noActivityName: true,
  },
  Date: {
    component: DateForm,
    formId: 'Date',
    formName: 'Date & Time',
    nextForm: ActivityForms.Duration,
    nextFormText: 'Next: Duration',
    progressValue: 2,
    isValid: false,
  },
  Duration: {
    component: DurationForm,
    formId: 'Duration',
    formName: 'Duration',
    nextForm: ActivityForms.Created,
    additionalForm: ActivityForms.MoreOptions,
    additionalFormText: 'More options',
    nextFormText: 'Create activity',
    nextFormAction: 'Create',
    progressValue: 3,
    isValid: false,
  },
  MoreOptions: {
    component: MoreOptionsForm,
    formId: 'MoreOptions',
    formName: 'More options',
    nextForm: ActivityForms.Created,
    nextFormText: 'Create activity',
    nextFormAction: 'Create',
    noHeader: true,
    isValid: true,
  },
  AddSubtasks: {
    component: Subtasks,
    formId: 'AddSubtasks',
    formName: 'Add subtasks',
    noHeader: true,
    isValid: true,
  },
  SetRecurrence: {
    component: SetRecurrence,
    formId: 'SetRecurrence',
    formName: 'Set recurrence',
    previousFormAction: 'ValidateRecurrence',
    noHeader: true,
    isValid: false,
  },
  Created: {
    component: TaskCreated,
    formId: 'Created',
    formName: 'Activity created',
    noHeader: true,
  },
  Edit: {
    component: EditActivityForm,
    formId: 'Edit',
    formName: 'Edit',
    nextForm: ActivityForms.Edited,
    nextFormAction: 'Edit',
    nextFormText: 'Update activity',
    noHeader: true,
    noActivityName: true,
    isValid: false,
  },
  Edited: {
    component: ActivityEdited,
    formId: 'Edited',
    formName: 'Activity created',
    noHeader: true,
  },
};

export type ActivityFormContextType = {
  goToPreviousForm: () => void;
  goToNextForm: () => void;
  goToForm: (form: ActivityForms | 'Back') => void;
  setValid: (value: boolean) => void;
  setFormError: (error: string | null) => void;
  current: ActivityForm;
};

export const ActivityFormContext = createContext<ActivityFormContextType>(
  {} as ActivityFormContextType,
);

type props = {
  children?: React.ReactNode;
  initialForm?: ActivityForms;
  formOverrideConfig?: ComponentLookupTable;
};

const ActivityFormScene = ({
  children,
  initialForm = ActivityForms.Title,
  formOverrideConfig: activityFormsDefs = DefaultActivityFormsDefs,
}: props) => {
  const navigate = useNavigate();
  const { formId } = useParams();
  const [form, setForm] = useState<ActivityForm>(activityFormsDefs[initialForm]);
  const [formError, setFormError] = useState<string | null>(null);
  const previousForm = () => {
    navigate(-1);
  };
  const nextForm = () => {
    if (form.nextForm) navigateToForm(form.nextForm);
  };

  const goToForm = (newForm: ActivityForms | 'Back') => {
    if (newForm === 'Back') navigate(-1);
    else {
      navigateToForm(newForm);
    }
  };

  const navigateToForm = (form: string) => {
    const url = window.location.pathname;
    const urlParts = url.split('/');
    const newUrl = urlParts.slice(0, urlParts.length - 1).join('/');
    navigate(newUrl + '/' + form);
  };

  const setValid = (value: boolean) => setForm({ ...form, isValid: value });

  useEffect(() => {
    if (formId) {
      const newForm = activityFormsDefs[formId as ActivityForms];
      if (newForm) {
        setForm(newForm);
      }
    }
  }, [formId]);

  return (
    <ActivityFormContext.Provider
      value={{
        goToPreviousForm: previousForm,
        goToNextForm: nextForm,
        goToForm: goToForm,
        current: form,
        setValid: setValid,
        setFormError: setFormError,
      }}
    >
      {formError ? (
        <ErrorView error={formError} onTryAgain={() => setFormError(null)} />
      ) : (
        <>{children}</>
      )}
    </ActivityFormContext.Provider>
  );
};

export default ActivityFormScene;
