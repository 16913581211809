import { Container, Stack, Typography } from '@mui/material';
import ProgressIndicator from './ProgressIndicator';
import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import PreviousButton from './PreviousButton';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { useTranslation } from 'react-i18next';

const CreateActivityHeader = () => {
  //toDo move back button to own component
  const { activity } = useContext(ActivityContext);
  const { current } = useContext(ActivityFormContext);
  const { t } = useTranslation();
  return (
    <>
      {!current.noHeader && (
        <Container>
          <Stack alignItems="center" spacing={2} marginY={2}>
            <Stack direction="row" sx={{ width: '100%' }}>
              <PreviousButton />
              <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', padding: 0.5 }}>
                {t('Create activity')}
              </Typography>
            </Stack>
            <ProgressIndicator />
            <Typography variant="h6" sx={{ wordBreak: 'break-word', minHeight: '1lh' }}>
              {!current.noActivityName && activity?.activityName}
            </Typography>
          </Stack>
        </Container>
      )}
    </>
  );
};

export default CreateActivityHeader;
