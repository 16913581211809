import { Note } from '@api';
import { Close, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  LinearProgress,
  Stack,
  TextField,
  Theme,
  lighten,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ColorSelector from './colorSelect/ColorSelector';
import { useTranslation } from 'react-i18next';
import { NotesContext } from 'src/contexts/NotesContext';

type NoteFormProps = {
  note?: Note;
  onClose: () => void;
  noteBoardId: number;
  notes: Note[];
};

const defaultNote: Note = {
  id: 1,
  title: '',
  text: '',
  color: '#53C433',
  position: 1,
  noteBoardId: 0,
};

const NoteForm = ({ note, onClose, noteBoardId, notes }: NoteFormProps) => {
  const { t } = useTranslation();
  const { createNote, editNote, deleteNote } = useContext(NotesContext);

  const [formNote, setFormNote] = useState<Note>(note ?? defaultNote);
  const [validBoard, setValidBoard] = useState(false);
  const [sending, setSending] = useState(false);

  const onRemove = async () => {
    setSending(true);
    if (note) {
      await deleteNote(note.id, noteBoardId);
    }
    onClose();
    setSending(false);
  };

  const onSave = async () => {
    setSending(true);
    if (note) {
      await editNote({
        ...formNote,
        noteBoardId: noteBoardId,
      });
    } else {
      const newPosition = notes.length;
      await createNote({
        ...formNote,
        noteBoardId: noteBoardId,
        position: newPosition ? newPosition + 1 : 1,
      });
    }
    setSending(false);
    onClose();
  };

  useEffect(() => {
    const validBoard =
      formNote.title.length !== 0 &&
      formNote.text.length !== 0 &&
      formNote.color.match(/^#[0-9A-F]{6}$/i) !== null;

    // Check if is the same note
    if (
      note &&
      note.title === formNote.title &&
      note.text === formNote.text &&
      note.color === formNote.color
    ) {
      setValidBoard(false);
      return;
    }
    setValidBoard(validBoard);
  }, [formNote]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: formNote.color,
          px: 2,
        }}
      >
        <Edit sx={{ visibility: 'hidden' }}></Edit>
        <DialogTitle sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Input
            value={formNote.title}
            onChange={(e) => setFormNote({ ...formNote, title: e.target.value })}
            placeholder={t('Title') ?? ''}
            sx={{
              '& .MuiInputBase-input': {
                padding: 0,
                fontSize: '1.25rem',
                textAlign: 'center',
                color: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
              },
              ':before': {
                borderBottomColor: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
              },
              ':after': {
                borderBottomColor: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
              },
            }}
          />
        </DialogTitle>
        <Close onClick={onClose}></Close>
      </Box>
      <DialogContent
        sx={{
          background: lighten(formNote.color, 0.7),
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Box
          color={(theme: Theme) => theme.palette.black}
          sx={{ minHeight: '10em', pt: 2 }}
          overflow={'auto'}
        >
          <TextField
            value={formNote.text}
            onChange={(e) => setFormNote({ ...formNote, text: e.target.value })}
            multiline
            fullWidth
            placeholder={t('Note Text') ?? ''}
            sx={{
              '& .MuiInputBase-input': {
                padding: 0,
                color: (theme: Theme) =>
                  theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
              },
              backgroundColor: lighten(formNote.color, 0.9),
              borderRadius: '5px',
            }}
          />
        </Box>
        <ColorSelector
          selectedColor={formNote.color}
          onSelectColor={(newColor) => setFormNote({ ...formNote, color: newColor })}
        ></ColorSelector>
        <DialogActions sx={{ p: 0, pt: 2, justifyContent: note ? 'space-between' : '' }}>
          {note && (
            <Button onClick={onRemove} variant="contained" color="error" disabled={sending}>
              {t('Remove')}
            </Button>
          )}
          <Button
            onClick={onSave}
            variant="contained"
            color="primary"
            disabled={!validBoard || sending}
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </DialogContent>
      {sending && (
        <Stack
          justifyContent={'center'}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <LinearProgress color="info" />
        </Stack>
      )}
    </>
  );
};

export default NoteForm;
