import { Box, Button, Dialog, InputLabel, Stack, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityContext } from 'src/contexts/ActivityContext';

type CustomDurationDialogProps = {
  open: boolean;
  onChange: (newVal: number) => void;
  onClose: () => void;
};

const CustomDurationDialog = (props: CustomDurationDialogProps) => {
  const { activity, setActivity } = useContext(ActivityContext);
  const [value, setValue] = useState<number>(activity.duration);

  useEffect(() => {
    setValue(activity.duration);
  }, [activity.duration]);

  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Stack direction={'column'} p={3} spacing={2}>
        <Box>
          <InputLabel htmlFor="customDuration">{t('Custom duration (minutes)')}</InputLabel>
          <TextField
            id="customDuration"
            type="number"
            value={value >= 0 ? value : ''}
            onChange={(e) => {
              if (e.target.value === '') setValue(-1);
              else if (+e.target.value > 1440) setValue(1440);
              else setValue(+e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 0, max: 1440 }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              props.onChange(value);
              props.onClose();
            }}
            variant={'contained'}
            disabled={value < 0}
          >
            Ok
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default CustomDurationDialog;
