import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';

const ActivityDescription = () => {
  const { activity } = useContext(ActivityContext);

  return (
    <Stack>
      <Typography variant="body1" sx={{ mt: 0, wordBreak: 'break-word' }}>
        {activity.description}
      </Typography>
    </Stack>
  );
};

export default ActivityDescription;
