import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type MaxClientsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const MaxClientsDialog = ({ open, onClose }: MaxClientsDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('Client limit reached')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography paragraph>
            {t('As a free user of this application, you can manage up to 4 clients.')}
          </Typography>
          <Typography paragraph>
            {t(
              'If you are working within an organization and wish to manage more clients, please contact ',
            )}
            <Link href="mailto:appsupport@knowit.se">appsupport@knowit.se</Link>
            {' ' + t('for more information.')}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={onClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MaxClientsDialog;
