import { dateLocales } from '@/i18n/dateLocales';
import { Box, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

type props = { onDaySelect: (day: Date) => void; date: Date };

const TinyDayHeader = ({ onDaySelect, date }: props) => {
  const { i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const theme = useTheme();
  const today = new Date();

  const day = format(date, 'EEEE').toLowerCase();

  const dayColor = (theme.palette.weekdays as any)[day].main;
  const dayBorder = (theme.palette.weekdays as any)[day].border;

  //Check date, month and year
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  return (
    <Box
      sx={{
        height: '4.5em',
        marginBottom: '0.5em',
        width: '100%',
        border: `2px solid transparent`,
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isToday && (
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: '90%',
              height: '7px',
              borderRadius: '7px',
            }}
          />
        )}
      </Box>
      <Stack
        sx={{
          backgroundColor: dayColor,
          borderRadius: 3,
          border: dayBorder,
          position: 'relative',
          height: 'calc(100% - 1em)',
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
        }}
        direction="column"
        alignItems="center"
        justifyContent="center"
        role="button"
        onClick={() => onDaySelect(date)}
      >
        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: 1.25 }}>
          {date.getDate()}
        </Typography>
        <Typography
          variant="body2"
          sx={{ textTransform: 'capitalize', fontWeight: isToday ? 'bold' : 'normal' }}
        >
          {format(date, 'EEE', { locale: dateLocales[lang] })}
        </Typography>
      </Stack>
    </Box>
  );
};

export default TinyDayHeader;
