import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

type DeleteStatusDialogProps = {
  status: 'idle' | 'loading' | 'success' | 'error';
  onClose: () => void;
};

const DeleteStatusDialog: React.FC<DeleteStatusDialogProps> = ({ status, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const maxWidth = isSmallScreen ? 'xs' : 'sm';

  const isOpen = status !== 'idle';

  const getTitle = () => {
    switch (status) {
      case 'loading':
        return t('Deleting Activity');
      case 'success':
        return t('Activity Deleted');
      case 'error':
        return t('Deletion Failed');
      default:
        return '';
    }
  };

  const getDialogContent = () => {
    switch (status) {
      case 'loading':
        return (
          <>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
            <Typography variant="body1" color="textPrimary">
              {t('Deleting activity, please wait...')}
            </Typography>
          </>
        );
      case 'success':
        return (
          <Typography variant="body1" color="textPrimary">
            {t('Activity successfully deleted')}
          </Typography>
        );
      case 'error':
        return (
          <Typography variant="body1" color="textPrimary">
            {t('Failed to delete activity')}
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="delete-status-dialog-title"
      aria-describedby="delete-status-dialog-description"
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogTitle id="delete-status-dialog-title" style={{ textAlign: 'center' }}>
        {getTitle()}
      </DialogTitle>
      <DialogContent>{getDialogContent()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus variant="contained">
          {t('To Start Page')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteStatusDialog;
