import { Box } from '@mui/material';
import { useRef } from 'react';
import TimeIntervalRenderer from '../TimeIntervalRenderer';
import TimelineDotIcon from './TimelineDotIcon';
//A component that renders each day
const TimelineDots = () => {
  const calendarRef = useRef(null);
  const dotsPerHour = 4;

  return (
    <Box sx={{ height: '100%', position: 'relative', width: '0.75em' }} ref={calendarRef}>
      <TimeIntervalRenderer times={24 * dotsPerHour} parentRef={calendarRef}>
        <TimelineDotIcon dotsPerHour={dotsPerHour} />
      </TimeIntervalRenderer>
    </Box>
  );
};

export default TimelineDots;
