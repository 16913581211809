import { useContext, useEffect, useState, useRef, MutableRefObject, RefObject } from 'react';
import { Stack, Button, Box } from '@mui/material';
import { getWeekDates } from '@/components/utils/DateUtils';
import { CalendarContext } from '@/contexts/CalendarContext';
import { SupervisorContext } from '@/contexts/SupervisorContext';
import { CalendarDayScene } from '@/contexts/CalendarDayContext';
import CalendarDay from './calendarDay/CalendarDay';
import Timeline from './timeline/Timeline';
import TimelineDots from './timeline/TimelineDots';
import TimeIndicator from './TimeIndicator';
import { TimerManagerProvider } from '@/contexts/TimerManagerContext';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@/contexts/AppContext';
import { getISOWeek, getISOWeekYear } from 'date-fns';
import CalendarHeader from './calendarHeader/CalendarHeader';
import TimeIntervalRenderer from './TimeIntervalRenderer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TodayIcon from '@mui/icons-material/Today';
import { useTheme } from '@mui/material';

const getDatesBetween = ({ startDate, endDate }: { startDate: Date; endDate: Date }): Date[] => {
  const daysInWeek = 7;
  return Array.from({ length: daysInWeek }, (_, index) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + index);
    return date;
  });
};

//A component that renders each day
const WeekCalendar = ({ headerRef }: { headerRef: MutableRefObject<HTMLDivElement | null> }) => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const innerCalendarRef = useRef<HTMLDivElement>(null);
  const dayHeaderRef = useRef<HTMLDivElement>(null);
  const { supervisorHeaderRef } = useContext(SupervisorContext);
  const { navbarRef, pageHeight } = useContext(AppContext);
  const { selectedWeek, setSelectedWeek } = useContext(CalendarContext);

  const [weekdays, setWeekdays] = useState<Date[]>([]); // [startDate, endDate
  const [renderDay, setRenderDay] = useState<Date | null>(null);
  const [calendarHeight, setCalendarHeight] = useState(0);

  const { palette } = useTheme();

  const [t] = useTranslation();

  const today = new Date();

  const RenderDayIsToday =
    renderDay &&
    renderDay.getDate() === today.getDate() &&
    renderDay.getMonth() === today.getMonth() &&
    renderDay.getFullYear() === today.getFullYear();

  useEffect(() => {
    const dates = getWeekDates(selectedWeek);
    const datesBetween = getDatesBetween(dates);
    setWeekdays(datesBetween);
    //Get weekday of renderday and set renderday to corresponding weekday in weekdays
    if (renderDay) {
      const weekday = renderDay.getDay();
      const weekdayDate = datesBetween.find((date) => date.getDay() === weekday);
      if (weekdayDate) setRenderDay(weekdayDate);
    }
  }, [selectedWeek]);

  const setDay = (day: Date) => {
    setRenderDay(day);
    //Check if renderday is in current week
    const dates = getWeekDates(selectedWeek);
    const datesBetween = getDatesBetween(dates);
    const isInWeek = datesBetween.find((date) => date.getTime() === day.getTime());
    if (!isInWeek) {
      //If not, set current week to the week of the renderday
      const week = getISOWeek(day);
      const year = getISOWeekYear(day);
      const newWeek = { week, year };
      setSelectedWeek(newWeek);
    }
  };

  // Scroll to current tim  e to 1/3 of the screen
  useEffect(() => {
    if (calendarRef.current) {
      const now = new Date();
      const nowHour = now.getHours();
      const nowMinute = now.getMinutes();
      const nowTime = nowHour + nowMinute / 60;

      const scrollHeight = calendarRef.current.scrollHeight;
      const clientHeight = calendarRef.current.clientHeight;
      const scrollPosition = (nowTime / 24) * scrollHeight - clientHeight / 4;
      calendarRef.current.scrollTo({ top: scrollPosition });
    }
  }, [calendarHeight]);

  const getSupervisorHeaderHeight = (
    supervisorHeaderRef: RefObject<HTMLDivElement> | undefined,
  ) => {
    if (supervisorHeaderRef && supervisorHeaderRef.current) {
      //Get height of supervisor header
      const supervisorHeaderStyles = window.getComputedStyle(supervisorHeaderRef.current);
      const supervisorHeaderMarginTop = parseFloat(supervisorHeaderStyles.marginTop);
      const supervisorHeaderMarginBottom = parseFloat(supervisorHeaderStyles.marginBottom);
      const supervisorHeaderHeight =
        supervisorHeaderRef.current?.getBoundingClientRect().height +
        supervisorHeaderMarginTop +
        supervisorHeaderMarginBottom;
      return supervisorHeaderHeight;
    }
    return 0;
  };

  useEffect(() => {
    const navbarHeight = navbarRef.current?.getBoundingClientRect().height ?? 0;
    if (headerRef.current && dayHeaderRef.current) {
      //Get height of header
      const headerStyles = window.getComputedStyle(headerRef.current);
      const marginTop = parseFloat(headerStyles.marginTop);
      const marginBottom = parseFloat(headerStyles.marginBottom);
      const headerHeight =
        headerRef.current?.getBoundingClientRect().height + marginTop + marginBottom;

      //Get height of day header
      const dayHeaderStyles = window.getComputedStyle(dayHeaderRef.current);
      const dayHeaderMarginTop = parseFloat(dayHeaderStyles.marginTop);
      const dayHeaderMarginBottom = parseFloat(dayHeaderStyles.marginBottom);
      const dayHeaderHeight =
        dayHeaderRef.current?.getBoundingClientRect().height +
        dayHeaderMarginTop +
        dayHeaderMarginBottom;

      const supervisorHeaderHeight = getSupervisorHeaderHeight(supervisorHeaderRef);

      const calendarHeight =
        pageHeight - navbarHeight - headerHeight - dayHeaderHeight - supervisorHeaderHeight;

      //We dont need more than 2000px
      const maxCalendarHeight = 2000;
      const minCalendarHeight = 300;
      setCalendarHeight(Math.max(Math.min(calendarHeight, maxCalendarHeight), minCalendarHeight));
    }
  }, [navbarRef, headerRef, dayHeaderRef, supervisorHeaderRef, pageHeight]);

  return (
    <TimerManagerProvider>
      {renderDay && (
        <Stack direction="row" justifyContent="space-around" margin={1}>
          <Button sx={{ mt: '0px !important' }} onClick={() => setRenderDay(null)}>
            <ChevronLeftIcon />
            {t('Back to week-view')}
          </Button>
          {!RenderDayIsToday && (
            <Button sx={{ mt: '0px !important' }} onClick={() => setRenderDay(today)}>
              <TodayIcon />
              {t('To today')}
            </Button>
          )}
        </Stack>
      )}
      <CalendarHeader
        onDaySelect={setDay}
        weekdays={weekdays}
        renderDay={renderDay}
        ref={dayHeaderRef}
      />
      <Box
        sx={{
          height: `${calendarHeight}px`,
          overflow: 'auto',
          pt: 1,
          width: '100%',
        }}
        ref={calendarRef}
      >
        <Stack
          direction="row"
          sx={{ width: '100%', height: '2000px', position: 'relative' }}
          ref={innerCalendarRef}
        >
          <Timeline />
          <TimelineDots />
          <Box
            sx={{
              width: 'calc(100% - 2em)',
              position: 'relative',
              backgroundColor: palette.lineLight,
            }}
          >
            <Stack direction="row" width="100%" height="100%">
              {renderDay ? (
                <>
                  <CalendarDayScene date={renderDay}>
                    <CalendarDay />
                  </CalendarDayScene>
                </>
              ) : (
                weekdays.map((date) => (
                  <CalendarDayScene date={date} key={date.toString()}>
                    <CalendarDay />
                  </CalendarDayScene>
                ))
              )}
            </Stack>
            <TimeIntervalRenderer times={24} parentRef={innerCalendarRef}>
              <Box
                sx={{
                  height: '1px',
                  width: '100%',
                  backgroundColor: palette.lineLight,
                }}
              />
            </TimeIntervalRenderer>
          </Box>
          <TimeIndicator calendarRef={innerCalendarRef} day={renderDay} />
        </Stack>
      </Box>
    </TimerManagerProvider>
  );
};

export default WeekCalendar;
