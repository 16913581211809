import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AuthApi, User } from '@api';
import { getTimezone } from 'src/utils/time';
import { AppContext } from './AppContext';
import BasicDialog from 'src/components/utils/dialogs/BasicDialog';

type props = {
  children?: React.ReactNode;
};

export type TimeZoneContextType = {
  timeZone: string;
};

export const TimeZoneContext = createContext<TimeZoneContextType>({
  timeZone: '',
});

export const TimeZoneScene = ({ children }: props) => {
  const [browserTimeZone, _] = useState(getTimezone());
  const { user, setUser } = useContext(AppContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const updateTimeZone = async () => {
    const res = await new AuthApi().updateTimeZone(browserTimeZone);
    if (res) setUser({ ...user, timeZone: browserTimeZone } as User);
  };

  useEffect(() => {
    if (user) {
      if (user.timeZone !== browserTimeZone) {
        if (user.timeZone === null) {
          updateTimeZone();
        } else setDialogOpen(true);
      }
    }
  }, [user, browserTimeZone]);

  const contextProvider = useMemo(
    () => ({
      timeZone: browserTimeZone,
    }),
    [browserTimeZone],
  );

  return (
    <TimeZoneContext.Provider value={contextProvider}>
      <BasicDialog
        open={dialogOpen}
        content={{
          title: 'You have changed time zone',
          body: 'You have changed time zone. Do you want to update your activities to your new time zone?',
          confirmText: 'Yes',
          cancelText: 'No',
          confirmButtonColor: undefined,
        }}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => updateTimeZone()}
      />
      {children}
    </TimeZoneContext.Provider>
  );
};
