import { Box, Card, Container, Divider, Stack, Typography, Link } from '@mui/material';
import GoogleLoginButton from '@/components/auth/GoogleLogin';
import AppleLoginButton from '@/components/auth/AppleLogin';
import SSOLoginbutton from '@/components/auth/SSOLogin';
import MainLogo from '@/components/auth/MainLogo';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import LegalDialog, { LegalType } from 'src/components/utils/dialogs/LegalDialog';
import { useTheme } from '@mui/material';

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const [legalText, setLegalText] = useState<LegalType>('');
  const { palette } = useTheme();
  const lang = i18n.language.split('-')[0];

  return (
    <div>
      <LegalDialog open={legalText} onClose={() => setLegalText('')} />
      <Container maxWidth={'xs'} sx={{ height: '100vh' }}>
        <Stack direction={'column'} height="100%">
          <Box sx={{ display: 'flex', alignItems: 'center', width: 'auto' }} flexGrow={1}>
            <Card
              sx={{
                padding: 3,
                width: '100%',
                justifyContent: 'center',
                border: palette.mode === 'dark' ? '1px solid gray' : '',
              }}
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <MainLogo />
                <Typography variant="h4">Todej</Typography>
                <Divider></Divider>
                <GoogleLoginButton />
                <AppleLoginButton />
                <SSOLoginbutton />
              </Stack>
            </Card>
          </Box>
          <Box textAlign={'center'} pb={1}>
            {t('By signing in you accept our') + ' '}
            <Link
              component={'button'}
              onClick={() => setLegalText(lang === 'sv' ? 'termsSv' : 'termsEn')}
              sx={{ paddingBottom: '3px' }}
            >
              {t('Terms of Service').toLowerCase()}
            </Link>
            {' ' + t('and acknowledge that you have read and understood our') + ' '}
            <Link
              component={'button'}
              onClick={() => setLegalText(lang === 'sv' ? 'privacySv' : 'privacyEn')}
              sx={{ paddingBottom: '3px' }}
            >
              {t('Privacy Policy').toLowerCase()}.
            </Link>
          </Box>
        </Stack>
      </Container>
    </div>
  );
};

export default LoginPage;
