import { useContext, useEffect, useState } from 'react';
import { SupervisorContext } from '@/contexts/SupervisorContext';
import { AppBar } from '@mui/material';
import SupervisorDialog from './SupervisorDialog';
import SupervisorHeaderText from './SupervisorHeaderText';

const SetHeaderHeight = () => {
  const { supervisorHeaderRef, setSupervisorHeaderHeight } = useContext(SupervisorContext);

  const getHeaderHeight = () => {
    if (supervisorHeaderRef?.current) {
      const style = window.getComputedStyle(supervisorHeaderRef.current);
      const marginTop = parseInt(style.marginTop || '0', 10);
      const marginBottom = parseInt(style.marginBottom || '0', 10);
      const height = supervisorHeaderRef.current.offsetHeight + marginTop + marginBottom;
      return height;
    }
    return 0;
  };

  useEffect(() => {
    setSupervisorHeaderHeight(getHeaderHeight());
  }, []);
  return null;
};

const SupervisorHeader = () => {
  const { supervisorHeaderRef, currentClient } = useContext(SupervisorContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <SupervisorDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
      {currentClient && (
        <AppBar
          position="sticky"
          color="warning"
          sx={{ pt: 1, pb: 0.5 }}
          role="button"
          onClick={() => setDialogOpen(true)}
          ref={supervisorHeaderRef}
        >
          <SetHeaderHeight />
          <SupervisorHeaderText />
        </AppBar>
      )}
    </>
  );
};

export default SupervisorHeader;
