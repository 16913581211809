import { Box } from '@mui/material';
import { useContext } from 'react';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import NextButton from './NextButton';
import AdditionalFormButton from './AdditionalFormButton';

const CreateActivityFooter = () => {
  //toDo move back button to own component
  const { current } = useContext(ActivityFormContext);
  return (
    <>
      {(current.nextForm || current.additionalForm) && <Box id="divider" sx={{ flex: 1 }} />}
      <AdditionalFormButton />
      <NextButton />
    </>
  );
};

export default CreateActivityFooter;
