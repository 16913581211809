import { Typography } from '@mui/material';
//A component that renders each day
const Timeline = ({ index }: { index?: number }) => {
  const timeOffset = index ?? 0;

  const renderTime = timeOffset.toString().padStart(2, '0');

  return (
    <Typography style={{ width: '100%', height: '1em', lineHeight: 1 }} variant="body2">
      {renderTime}
    </Typography>
  );
};

export default Timeline;
