import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BuyTodej = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5" pl={2} textAlign={'center'}>
        {t('Buy todej for your organization')}
      </Typography>
      <Typography variant="body1" pl={2} textAlign={'center'} pb={6}>
        {t('buy_todej_description')}
        <Link href="mailto:appsupport@knowit.se">appsupport@knowit.se</Link>
      </Typography>
    </>
  );
};

export default BuyTodej;
