import { AccordionSummary, Stack, Switch, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SupervisorContext } from 'src/contexts/SupervisorContext';
import { SettingsConfig } from './SettingsAccordionConfig';

const SuperviseModeToggle = (props: SettingsConfig) => {
  const { t } = useTranslation();
  const { isSupervising, setSupervising, refresh } = useContext(SupervisorContext);

  const toggle = () => {
    setSupervising(!isSupervising);
    refresh();
  };

  return (
    <AccordionSummary sx={{ mr: -1 }}>
      <Stack
        justifyContent={'space-between'}
        direction={'row'}
        width={'100%'}
        alignItems={'center'}
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
      >
        <Typography variant="h6" color={'primary'}>
          {t(props.title)}
        </Typography>
        <Switch
          checked={isSupervising}
          inputProps={{ 'aria-label': t('Toggle supervise mode') as string }}
        />
      </Stack>
    </AccordionSummary>
  );
};

export default SuperviseModeToggle;
