import { isDate } from 'date-fns';
import { createContext, useEffect, useState, useContext } from 'react';
import { ActivityApi, ActivityInstance } from '@api';
import { CalendarContext } from './CalendarContext';
import { SupervisorContext } from './SupervisorContext';

type props = {
  children?: React.ReactNode;
  instance?: ActivityInstance;
  instanceId?: number;
};

export type InstanceContextType = {
  instance: ActivityInstance;
  setInstance: (value: ActivityInstance) => void;
  startInstance: () => void;
  completeInstance: () => void;
  deleteInstance: () => Promise<boolean>;
  isStarted: boolean;
  timerPaused: boolean;
  setTimerPaused: (value: boolean) => void;
  toggleSubtask: (subtaskId: number) => void;
};

export type TimerState = {
  remainingTime: number;
  paused: boolean;
  estimatedEndTime: number;
};

export const InstanceContext = createContext<InstanceContextType>({} as InstanceContextType);

export const InstanceScene = (props: props) => {
  const { currentClient } = useContext(SupervisorContext);
  const { refreshWeek } = useContext(CalendarContext);

  const [instance, setInstance] = useState<ActivityInstance>(
    props.instance ?? ({} as ActivityInstance),
  );

  const getTimerStateFromLocalStorage = (): TimerState | null => {
    const savedState = localStorage.getItem(`timer-${props.instance?.activityInstanceId}`);
    return savedState ? JSON.parse(savedState) : null;
  };

  const getTimerState = (): boolean => {
    const paused = getTimerStateFromLocalStorage()?.paused;
    return paused ?? false;
  };

  const [timerPaused, setTimerPaused] = useState<boolean>(getTimerState());

  const startInstance = async () => {
    const res = await new ActivityApi().startActivity({
      activityInstanceId: instance.activityInstanceId,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res === 200) {
      setInstance({ ...instance, dateIsStarted: new Date() });
      setTimerPaused(false);
      refreshWeek();
    }
  };

  const removeInstanceFromLocalStorage = () => {
    if (instance.activityInstanceId !== undefined) {
      localStorage.removeItem(`timer-${instance.activityInstanceId}`);
    }
  };

  //completes the instance
  const completeInstance = async () => {
    const res = await new ActivityApi().completeActivity({
      activityInstanceId: instance.activityInstanceId,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res === 200) {
      setInstance({ ...instance, dateIsCompleted: new Date() });
      removeInstanceFromLocalStorage();
      refreshWeek();
    }
  };

  const deleteInstance = async (): Promise<boolean> => {
    const res = await new ActivityApi().removeActivityInstance({
      activityInstanceId: instance.activityInstanceId,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res === 200) {
      removeInstanceFromLocalStorage();
      refreshWeek();
      return true;
    }
    return false;
  };

  const toggleSubtask = async (subtaskId: number) => {
    // call api to update checkbox
    const res = await new ActivityApi().setSubTask({
      activityInstanceId: instance.activityInstanceId,
      subtaskId: subtaskId,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res === 200) {
      if (instance.checkedSubtasks?.some((i) => i === subtaskId)) {
        setInstance({
          ...instance,
          checkedSubtasks: instance.checkedSubtasks?.filter((i) => i !== subtaskId),
        });
      } else {
        setInstance({
          ...instance,
          checkedSubtasks: [...(instance.checkedSubtasks ?? []), subtaskId],
        });
      }
    }
  };

  useEffect(() => {
    const getInstance = async (id: number) => {
      const res = await new ActivityApi().getActivityInstance({
        activityInstanceId: id,
        supervisedTag: currentClient ? currentClient.tag : undefined,
      });
      if (res) setInstance(res);
    };
    if (!props.instance && props.instanceId) getInstance(props.instanceId);
    else if (instance && !isDate(instance.dateIsStarted)) removeInstanceFromLocalStorage();
  }, []);

  useEffect(() => {
    if (props.instance) setInstance(props.instance);
  }, [props.instance]);

  return (
    <InstanceContext.Provider
      value={{
        instance: instance,
        setInstance: setInstance,
        startInstance: startInstance,
        completeInstance: completeInstance,
        deleteInstance: deleteInstance,
        isStarted: isDate(instance.dateIsStarted),
        timerPaused: timerPaused,
        setTimerPaused: setTimerPaused,
        toggleSubtask: toggleSubtask,
      }}
    >
      {props.children}
    </InstanceContext.Provider>
  );
};
