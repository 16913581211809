import { useParams } from 'react-router-dom';
import { ActivityScene } from '@/contexts/ActivityContext';
import React, { useContext } from 'react';
import { InstanceContext, InstanceScene } from '@/contexts/InstanceContext';
import { CalendarContext } from '@/contexts/CalendarContext';
import ErrorView from '@/components/utils/ErrorView';
import { useTranslation } from 'react-i18next';

export type EditActivityParams = {
  instanceId: string;
  editSingleActivity?: string;
};

type props = {
  children?: React.ReactNode;
  useStoredActivity?: boolean;
};

const ActivityProvider = ({ children, useStoredActivity }: props) => {
  const { t } = useTranslation();
  const { instance } = useContext(InstanceContext);

  return (
    <>
      {instance.parentActivityId && instance.parentActivityId !== -1 && (
        <ActivityScene activityId={instance.parentActivityId} useStoredActivity={useStoredActivity}>
          {children}
        </ActivityScene>
      )}
      {instance.parentActivityId && instance.parentActivityId === -1 && (
        <ErrorView error={t('Activity could not be found')} />
      )}
    </>
  );
};

const InstanceAndActivityProvider = ({ children, useStoredActivity }: props) => {
  const { instanceId } = useParams<EditActivityParams>();
  const id = instanceId ? parseInt(instanceId) : undefined;
  const { findInstance } = useContext(CalendarContext);

  return (
    <InstanceScene instanceId={id} instance={id ? findInstance(id) : undefined}>
      <ActivityProvider useStoredActivity={useStoredActivity}>{children}</ActivityProvider>
    </InstanceScene>
  );
};
export default InstanceAndActivityProvider;
