import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ColorUnderline = ({ day }: { day: string }) => {
  const theme = useTheme();
  const colorDay = day.toLowerCase();

  const dayColor =
    (theme.palette.weekdays as any)[colorDay].underline ??
    (theme.palette.weekdays as any)[colorDay].main;
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '0.25em',
        borderRadius: '1em',
        backgroundColor: dayColor,
      }}
    ></Box>
  );
};

export default ColorUnderline;
