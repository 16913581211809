import ReactEcharts from 'echarts-for-react';
import { StatisticsContext } from 'src/contexts/StatisticsContext';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import Badge from './Badge';

const HalfDonutGraph = () => {
  const { data } = useContext(StatisticsContext);

  const { t } = useTranslation();
  const colors = ['#eb2c3a', '#ee8130', '#fce92b', '#91bd49', '#009f54'];

  const [moods, setMoods] = useState<any>([]);
  const [options, setOptions] = useState<any>({
    tooltip: {
      trigger: 'item',
    },
    legend: {
      show: false,
      top: '5%',
      left: 'center',
      selectedMode: false,
    },
    series: [
      {
        top: '-50%',
        left: '-50%',
        right: '-50%',
        bottom: '-50%',
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        startAngle: 180,
        label: {
          color: '#000',
          fontSize: '50',
          position: 'center',
          padding: [3, 4, 5, 6],
          formatter: () => {
            return 0; // Use sum variable here
          },
        },
        data: [],
      },
    ],
  });

  const getupdatedSeries = () => {
    if (!Object.keys(data).length) return [];

    const arr: any = [];

    Object.keys(data?.amountOfActivitiesPerRating).forEach((key: any) => {
      if (parseInt(key) > 0) {
        arr.push({
          value: data?.amountOfActivitiesPerRating[key],
          mood: parseInt(key),
          itemStyle: {
            color: colors[key - 1],
          },
        });
      }
    });

    return arr.filter((item: any) => item.value !== 0);
  };

  useEffect(() => {
    const arr: any = getupdatedSeries();

    const reduced = arr.reduce((a: any, b: any) => a + b.value, 0);
    if (arr.length) {
      arr.push({
        value: reduced ? reduced : 0,
        itemStyle: {
          color: 'none',
          decal: {
            symbol: 'none',
          },
        },
        label: {
          show: false,
        },
      });
    }

    setOptions({
      ...options,
      series: [
        {
          data: arr,
          label: {
            color: '#000',
            fontSize: '50',
            position: 'center',
            formatter: () => {
              return reduced; // Use sum variable here
            },
          },
        },
      ],
    });
    if (data?.amountOfActivitiesPerRating) {
      const moods = Object.keys(data.amountOfActivitiesPerRating).map(
        (key: any) => data.amountOfActivitiesPerRating[key],
      );
      moods.shift();

      // dont want to show the badge if there are no activities
      const sum = moods.reduce((a: any, b: any) => a + b, 0);
      if (sum > 0) setMoods(moods);
      else setMoods([]);
    }
  }, [data]);

  const imageHeight = '40px';

  return (
    <Paper
      elevation={4}
      sx={() => ({
        width: '100%',
        height: '50%',
        padding: '16px',
        marginTop: '16px',
      })}
    >
      <Typography variant="subtitle1">{t('Activities per rating')}</Typography>

      <ReactEcharts option={options} style={{ height: '210px' }} />

      <Stack
        direction="row"
        spacing={3}
        flexWrap="wrap"
        padding={'6px'}
        margin={'6px'}
        justifyContent="center"
      >
        {moods.length ? (
          moods.map((m: any, index: number) => (
            <Badge mood={index + 1} text={m} key={`badge-${index}`}>
              <img
                src={`/emotions/${index + 1}.png`}
                alt="activity img"
                style={{
                  height: imageHeight,
                }}
              />
            </Badge>
          ))
        ) : (
          <></>
        )}
      </Stack>
    </Paper>
  );
};

export default HalfDonutGraph;
