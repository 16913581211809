import { useContext, useEffect, useState } from 'react';
import { StatisticsContext } from 'src/contexts/StatisticsContext';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const RatedFeeling = ({
  rated,
  previous,
  image,
  text,
}: {
  rated: number;
  previous: number;
  image: string;
  text: string;
}) => {
  const { data, intervalType } = useContext(StatisticsContext);
  const [ratedText, setRatedText] = useState<string>('');
  const [previousText, setPreviousText] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    setRatedText(!Object.keys(data).length ? `Inga ${text}` : `${rated} ${text}`);
    setPreviousText(
      `${Math.abs(previous)} ${t(
        `${previous >= 0 ? 'more' : 'less'} than the previous ${intervalType}`,
      )}`,
    );
  }, [data]);

  return (
    <Card
      sx={{ display: 'flex', marginTop: '16px', justifyContent: 'space-between', padding: '16px' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          component="div"
          variant="subtitle1"
          sx={{ paddingTop: '16px', paddingRight: '4px', fontWeight: 'bold' }}
        >
          {ratedText}
        </Typography>
        {Object.keys(data).length ? (
          <Typography component="div" variant="subtitle2" sx={{ paddingTop: '1px' }}>
            {previousText}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 70, height: 70, opacity: !Object.keys(data).length ? '40%' : '100%' }}
        image={image}
        alt="Emotion"
      />
    </Card>
  );
};

export default RatedFeeling;
