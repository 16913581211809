//In this case we need to update translate3d instead of translateY (as DragOverlay uses that)
//Changing translateY will cause the x-value to be reset to 0
const updateTranslateY = (element: HTMLElement, translateY: number) => {
  // Get the current transform property value
  const currentTransform = element.style.transform;
  // Parse the current translateY value using a regular expression
  const match = /translate3d\(([^,]+), ([^,]+), ([^)]+)\)/.exec(currentTransform);

  if (match) {
    // Extract the current Y translation value and convert it to a number
    const currentX = match[1];
    const currentY = parseInt(match[2], 10);
    const currentZ = match[3];

    // Calculate the new Y translation value
    const newY = currentY + translateY;

    // Update the transform property with the new Y translation value
    element.style.transform = `translate3d(${currentX}, ${newY}px, ${currentZ})`;
  }
};

/*
      Animation for deleting the note
      We need to create a copy of the note, and animate that instead of the original note.
      This is because <DragOverlay> will be removed from the DOM when the drag ends.
    */
export const deleteItemAnimation = () => {
  const originalElement = document.getElementById('dragOverlayElement')?.parentNode;
  if (originalElement) {
    const newNode = originalElement.cloneNode(true);
    if (newNode instanceof HTMLElement) {
      newNode.id = 'drop-animation-element';

      newNode.style.transition = 'all 1s ease-out';

      document.body.appendChild(newNode);

      // Delay the updateTranslateY function call to allow for initial rendering
      setTimeout(() => {
        updateTranslateY(newNode, 250);
        newNode.style.transform += ' rotate(70deg)';
        newNode.style.opacity = '0';
      }, 0); // Use a small delay, like 0 milliseconds
      setTimeout(() => {
        newNode.remove();
      }, 1000); // 1000 milliseconds = 1 second
    }
  }
};
