import { IconButton } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useContext } from 'react';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useTranslation } from 'react-i18next';

const PreviousButton = () => {
  const { t } = useTranslation();
  const { current, goToPreviousForm } = useContext(ActivityFormContext);
  const activity = useContext(ActivityContext);
  const handleBack = () => {
    if (current.previousFormAction === 'ValidateRecurrence') activity.validateRecurrence?.();
    goToPreviousForm();
  };

  return (
    <>
      <IconButton
        aria-label={`${t('Previous week')}`}
        sx={{ position: 'absolute', padding: 1, bgcolor: 'line' }}
        onClick={handleBack}
      >
        <ArrowBackOutlinedIcon />
      </IconButton>
    </>
  );
};

export default PreviousButton;
