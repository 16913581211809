import { useEffect, useState } from 'react';

type UseTimerStateProps = {
  minutes: number;
  seconds?: number;
  isRunning: boolean;
  onTimerEnd?: () => void;
  timerId?: number;
};

type TimerState = {
  remainingTime: number;
  paused: boolean;
  estimatedEndTime: number;
};

const useTimerState = ({
  minutes,
  seconds = 0,
  isRunning,
  onTimerEnd,
  timerId,
}: UseTimerStateProps) => {
  const getFromLocalStorage = (): TimerState | null => {
    if (timerId === undefined) return null;
    const savedState = localStorage.getItem(`timer-${timerId}`);
    return savedState ? JSON.parse(savedState) : null;
  };

  const getInitialState = (): number => {
    const initialState = getFromLocalStorage();
    if (!initialState) return minutes * 60 + seconds;

    if (initialState.paused) {
      return initialState.remainingTime;
    } else {
      const currentTime = Date.now();
      const remainingTime = Math.max(
        0,
        Math.floor((initialState.estimatedEndTime - currentTime) / 1000),
      );
      return remainingTime;
    }
  };

  const [time, setTime] = useState<number>(getInitialState());

  const saveToLocalStorage = (timerState: TimerState) => {
    if (timerId !== undefined) {
      localStorage.setItem(`timer-${timerId}`, JSON.stringify(timerState));
    }
  };

  const endTimer = () => {
    onTimerEnd?.();
  };

  useEffect(() => {
    saveToLocalStorage({
      remainingTime: time,
      paused: !isRunning,
      estimatedEndTime: Date.now() + time * 1000,
    });
    const timer = setInterval(() => {
      if (isRunning) {
        setTime((prevTime) => {
          if (prevTime > 0) {
            const newState = prevTime - 1;
            return newState;
          }
          clearInterval(timer);
          endTimer();
          return 0;
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [isRunning, onTimerEnd]);

  return {
    time,
    saveToLocalStorage,
    getFromLocalStorage,
    timerDone: time === 0,
  };
};

export default useTimerState;
