import { useEffect, useState } from 'react';
import { NotificationApi } from '@api';
import NotificationPermissionDialog from './components/utils/dialogs/NotificationPermissionDialog';

declare global {
  interface Window {
    isIosApp: boolean;
    subscribeIOS: (endpoint: string) => void;
    unsubscribeIOS: (endpoint: string) => void;
    isApp: boolean;
  }
}

export const getExistingSubscription = async () => {
  const serviceWorkerRegistration = await navigator.serviceWorker.ready;
  const existingSubscription = await serviceWorkerRegistration.pushManager.getSubscription();

  return existingSubscription;
};

export const subscribe = async () => {
  try {
    const existingSubscription = await getExistingSubscription();

    if (existingSubscription) {
      return;
    }

    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    const vapidPublicKey = await new NotificationApi().getVapidPublicKey();

    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: vapidPublicKey,
    });

    if (subscription) {
      const subscriptionJSON = subscription.toJSON();

      if (subscriptionJSON.endpoint && subscriptionJSON.keys) {
        const res = await new NotificationApi().subscribe({
          endpoint: subscriptionJSON.endpoint,
          keys: {
            p256dh: subscriptionJSON.keys.p256dh,
            auth: subscriptionJSON.keys.auth,
          },
        });
        if (res) {
          localStorage.setItem('notifications', 'true');
          console.log('Backend subscribed', res);
        }
      }
    }
  } catch (error) {
    console.warn('Failed to subscribe the user: ', error);
  }
};

export const unsubscribe = async () => {
  try {
    const existingSubscription = await getExistingSubscription();

    if (existingSubscription) {
      const subscriptionJSON = await existingSubscription.toJSON();
      if (subscriptionJSON.endpoint) {
        const res = await new NotificationApi().unsubscribe({
          endpoint: subscriptionJSON.endpoint,
        });
        if (res) {
          await existingSubscription.unsubscribe();
          localStorage.setItem('notifications', 'false');
          console.log('Backend unsubscribed', res);
        }
      }
    }
  } catch (error) {
    console.warn('Failed to unsubscribe the user: ', error);
  }
};

export const subscribeIOS = async (endpoint: string) => {
  try {
    const res = await new NotificationApi().subscribe({
      endpoint: endpoint,
      isAppleDevice: true,
    });
    if (res) {
      localStorage.setItem('notifications', 'true');
      console.log('Backend subscribed', res);
    }
  } catch (error) {
    console.warn('Failed to subscribe the user: ', error);
  }
};

export const unsubscribeIOS = async (endpoint: string) => {
  try {
    const res = await new NotificationApi().unsubscribe({
      endpoint: endpoint,
    });
    if (res) {
      localStorage.setItem('notifications', 'false');
      console.log('Backend subscribed', res);
    }
  } catch (error) {
    console.warn('Failed to subscribe the user: ', error);
  }
};

const isPushNotificationSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

const PushNotificationProvider = () => {
  const [showDialog, setShowDialog] = useState(false);

  const onPermissionGranted = () => {
    subscribe();
  };

  useEffect(() => {
    window.subscribeIOS = subscribeIOS;
    window.unsubscribeIOS = unsubscribeIOS;
    if (isPushNotificationSupported()) setShowDialog(true);
    else console.warn('This browser does not support desktop notification');
  }, []);

  return (
    <>
      <NotificationPermissionDialog show={showDialog} onPermissionGranted={onPermissionGranted} />
    </>
  );
};

export default PushNotificationProvider;
