import { AccordionSummary, Button, Stack, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AIToggle from './AIToggle';
import { Link } from 'react-router-dom';

const AISettings = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <AIToggle />
      <AccordionSummary sx={{ mr: -1 }}>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          width={'100%'}
          alignItems={'center'}
        >
          <Typography variant="h6" color={'primary'}>
            {t('AI Profile')}
          </Typography>
          <Button component={Link} to="/ai-profile" variant={'outlined'} color={'primary'}>
            {t('Configure')}
          </Button>
        </Stack>
      </AccordionSummary>
    </Stack>
  );
};

export default AISettings;
