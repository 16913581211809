import { Box } from '@mui/material';
import CircleProgress from './CircleProgress';

type DottedTimerProps = {
  percentage: number;
  color: string;
  stroke?: string;
  style?: React.CSSProperties;
  text?: boolean;
  backgroundColor?: string;
};

const circleProgressStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  padding: '10%',
};

const DottedTimer = (props: DottedTimerProps) => {
  return (
    <Box sx={{ position: 'relative' }} style={props.style}>
      <svg viewBox="0 0 100 100">
        <defs>
          <g id="lines" style={{ stroke: props.stroke ?? 'black' }}>
            <line x1="43" x2="47" />
            <line x1="-43" x2="-47" />
            <line y1="43" y2="47" />
            <line y1="-43" y2="-47" />
          </g>
          <g id="quarterLines" style={{ stroke: props.stroke ?? 'black' }}>
            <line x1="41" x2="47" />
            <line x1="-41" x2="-47" />
            <line y1="41" y2="47" />
            <line y1="-41" y2="-47" />
          </g>
        </defs>
        <g transform="translate(50 50)">
          <use href="#quarterLines" transform="rotate(0)" />
          <use href="#lines" transform="rotate(6)" />
          <use href="#lines" transform="rotate(12)" />
          <use href="#lines" transform="rotate(18)" />
          <use href="#lines" transform="rotate(24)" />
          <use href="#lines" transform="rotate(30)" />
          <use href="#lines" transform="rotate(36)" />
          <use href="#lines" transform="rotate(42)" />
          <use href="#lines" transform="rotate(48)" />
          <use href="#lines" transform="rotate(54)" />
          <use href="#lines" transform="rotate(60)" />
          <use href="#lines" transform="rotate(66)" />
          <use href="#lines" transform="rotate(72)" />
          <use href="#lines" transform="rotate(78)" />
          <use href="#lines" transform="rotate(84)" />
        </g>
      </svg>
      <Box sx={circleProgressStyle}>
        <CircleProgress percentage={props.percentage} color={props.color}></CircleProgress>
      </Box>
      {props.text && (
        <>
          <Box sx={circleProgressStyle}>
            <svg viewBox="0 0 100 100">
              <circle r="40" cx="50" cy="50" fill={props.backgroundColor ?? 'white'}></circle>
            </svg>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DottedTimer;
