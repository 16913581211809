import { Button, Container } from '@mui/material';
import { useContext } from 'react';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { useTranslation } from 'react-i18next';

const AdditionalFormButton = () => {
  const formHandler = useContext(ActivityFormContext);
  const { t } = useTranslation();

  const handleNext = async () => {
    if (formHandler.current.additionalForm) {
      formHandler.goToForm(formHandler.current.additionalForm);
    }
  };

  return (
    <>
      {formHandler.current.additionalForm && (
        <Container sx={{ p: 2 }}>
          <Button
            onClick={handleNext}
            variant={'outlined'}
            disabled={!formHandler.current.isValid}
            sx={{ width: '100%', padding: 1.25 }}
          >
            {formHandler.current.additionalFormText
              ? t(formHandler.current.additionalFormText)
              : formHandler.current.additionalForm}
          </Button>
        </Container>
      )}
    </>
  );
};

export default AdditionalFormButton;
