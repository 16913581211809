import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';

export const NavigationItems = [
  { link: '/', text: 'Daily', icon: <HouseOutlinedIcon /> },
  { link: '/week', text: 'Weekly', icon: <CalendarMonthOutlinedIcon /> },
  { link: '/notes', text: 'Notes', icon: <TextSnippetOutlinedIcon /> },
  { link: '/statistics', text: 'Statistics', icon: <BarChartIcon /> },
  { link: '/settings', text: 'Settings', icon: <SettingsIcon /> },
];
