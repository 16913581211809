import { Container, Box, Stack, Typography } from '@mui/material';
import ViewActivityHeader from '@/components/activity/viewActivity/ViewActivityHeader';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useContext, useEffect } from 'react';
import { AppContext } from '@/contexts/AppContext';
import ActivityImage from '@/components/activity/viewActivity/ActivityImage';
import ActivityActions from '@/components/activity/viewActivity/ActivityActions';
import ActivityDescription from '@/components/activity/viewActivity/ActivityDescription';
import Subtasks from '@/components/activity/viewActivity/Subtasks';
import { useTheme } from '@mui/material';
import InstanceAndActivityProvider from './InstanceAndActivityProvider';
import ErrorView from '@/components/utils/ErrorView';
import { useTranslation } from 'react-i18next';
import ActivityName from '@/components/activity/viewActivity/ActivityName';
import { SupervisorContext } from 'src/contexts/SupervisorContext';

const ViewActivity = () => {
  const theme = useTheme();
  const { activity } = useContext(ActivityContext);
  const { supervisorHeaderHeight } = useContext(SupervisorContext);
  const { pageHeight } = useContext(AppContext);
  const { t } = useTranslation();
  // If the activityId is 0, then the activity could not be found
  const defaultActivityId = 0;

  return (
    <>
      {activity.activityId ? (
        <Stack sx={{ minHeight: `calc(${pageHeight}px - ${supervisorHeaderHeight}px)` }}>
          <Container sx={{ px: 2, pt: 3 }}>
            <ViewActivityHeader />
            <ActivityImage displayQuarters />
            <ActivityActions />
          </Container>
          <Box
            sx={{ flex: 1, backgroundColor: theme.palette.lineLight }}
            p={3}
            pt={2}
            borderRadius="2em 2em 0 0"
          >
            <ActivityName />
            {activity.description ? <ActivityDescription /> : null}
            {!activity.description && !activity.subtasks?.length ? (
              <Typography sx={{ fontStyle: 'italic', mt: 2 }}>
                {t('No description or checklist')}
              </Typography>
            ) : (
              <Box mt={2} />
            )}
            {activity.subtasks?.length ? <Subtasks /> : null}
          </Box>
        </Stack>
      ) : (
        activity.activityId === defaultActivityId && (
          <ErrorView error={t('Activity could not be found')} />
        )
      )}
    </>
  );
};

const ViewActivityPage = () => {
  const { setShowNavbar } = useContext(AppContext);

  useEffect(() => {
    setShowNavbar(false);
    return () => setShowNavbar(true);
  }, []);

  return (
    <InstanceAndActivityProvider>
      <ViewActivity />
    </InstanceAndActivityProvider>
  );
};
export default ViewActivityPage;
