import { useContext } from 'react';
import { NotesContext } from 'src/contexts/NotesContext';
import BoardComponent from './BoardComponent';
import { Box, CircularProgress } from '@mui/material';
import ManageBoards from './ManageBoards';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

/*
  Due to conflicts between draggable notes and Swiper's touch events, we've temporarily disabled touch-based navigation
  in the Swiper component by setting 'allowTouchMove' to 'false'. Please note that this may limit some touch interactions
  within Swiper. We plan to revisit and address this issue for a more seamless user experience in the future.
*/

const BoardSwiper = ({ swiperRef, minHeight }: any) => {
  const { boards, loaded } = useContext(NotesContext);
  const safariRenderHack = { opacity: loaded ? 1 : 0.99, willChange: 'opacity' };

  return (
    <Box
      style={safariRenderHack}
      sx={{
        overflow: 'auto',
        width: '100%',
        height: minHeight,
        minHeight: 'inherit',
      }}
    >
      <Swiper ref={swiperRef} autoHeight allowTouchMove={false}>
        {loaded ? (
          <>
            {boards.map((board, index) => (
              <SwiperSlide key={index} style={{ minHeight: minHeight }}>
                <BoardComponent board={board} />
              </SwiperSlide>
            ))}
            <SwiperSlide style={{ minHeight: minHeight }}>
              <ManageBoards />
            </SwiperSlide>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: minHeight,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Swiper>
    </Box>
  );
};

export default BoardSwiper;
