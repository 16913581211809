import { useRef } from 'react';
import { Button, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

const ImageInput = ({
  value,
  isFocused,
  onChange,
  sx,
  id = 'imageInput',
  onRemove,
}: {
  value: string;
  isFocused?: boolean;
  onChange: { (e: React.ChangeEvent<HTMLInputElement>): void };
  sx?: SxProps;
  id?: string;
  onRemove?: { (): void };
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  const removeItem = () => {
    onRemove?.();
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={onChange}
        style={{ display: 'none' }}
        ref={imageInputRef}
        id={id}
      />
      <Button
        onClick={handleButtonClick}
        variant="outlined"
        sx={{
          position: 'relative',
          width: '6em',
          height: '6em',
          padding: 0,
          flexGrow: 2,
          boxShadow: isFocused ? '0 20px 20px 0 rgba(4, 6, 15, 0.08)' : 'none',
          border: isFocused ? `1px solid ${theme.palette.primary.main}` : '1px solid lightgray',
          borderRadious: '1em',
          ...sx,
        }}
      >
        {value && onRemove ? (
          <CancelIcon
            role="button"
            onClick={(e: any) => {
              e.stopPropagation();
              removeItem();
            }}
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              backgroundColor: 'white',
              color: 'DarkSlateGray',
              borderRadius: '50%',
            }}
          />
        ) : null}
        {value ? (
          <img
            src={value}
            style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '1em' }}
            alt="Subtask item"
          />
        ) : (
          <div
            style={{
              display: 'flex',
              flexFlow: 'column wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PhotoCameraIcon fontSize="large" />
            <span style={{ fontSize: '12px' }}>{t('Add image')}</span>
          </div>
        )}
      </Button>
    </>
  );
};
export default ImageInput;
