import { useContext, useEffect, useState } from 'react';
import { StatisticsContext } from 'src/contexts/StatisticsContext';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

const WeekDayAverage = ({
  weekday,
  rated,
  activities,
  image,
  text,
}: {
  weekday: string;
  rated: number;
  activities: number;
  image: string;
  text: string;
}) => {
  const { data } = useContext(StatisticsContext);
  const [headerText, setHeaderTExt] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    if (weekday) setHeaderTExt(`${t(`firstLetterCapitalDays.${weekday?.toLowerCase()}`)} ${text}`);
  }, [data]);

  if (weekday) {
    return (
      <Card
        sx={{
          display: 'flex',
          marginTop: '16px',
          justifyContent: 'space-between',
          padding: '16px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            component="div"
            variant="subtitle1"
            sx={{ paddingTop: '16px', paddingRight: '4px', fontWeight: 'bold' }}
          >
            {headerText}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Card
              sx={{
                minHeight: 100,
                border: 'none',
                boxShadow: 'none',
              }}
              raised={false}
            >
              <CardContent>
                <Typography gutterBottom variant="h4" component="div" textAlign={'center'}>
                  {rated}
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign={'center'}>
                  {t('Average rating')}
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                minHeight: 100,
                border: 'none',
                boxShadow: 'none',
              }}
              raised={false}
            >
              <CardContent>
                <Typography gutterBottom variant="h4" component="div" textAlign={'center'}>
                  {activities}
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign={'center'}>
                  {t('Activities')}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <CardMedia component="img" sx={{ width: 70, height: 70 }} image={image} alt="Emotion" />
      </Card>
    );
  } else return <></>;
};

export default WeekDayAverage;
