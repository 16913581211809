import { Routes, Route } from 'react-router-dom';
import LoginPage from 'src/pages/login/LoginPage';
import StartPage from 'src/pages/login/StartPage';
const StartRouter = () => {
  return (
    <Routes>
      <Route path={'/login'} element={<LoginPage />} />
      <Route path="*" element={<StartPage />} />
    </Routes>
  );
};

export default StartRouter;
