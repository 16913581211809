import { Box } from '@mui/material';
import BoardSwiper from 'src/components/notes/BoardSwiper';
import NotesHeader from 'src/components/notes/NotesHeader';
import { NotesScene } from 'src/contexts/NotesContext';
import { useRef, useContext, useEffect, useState } from 'react';
import { AppContext } from 'src/contexts/AppContext';
import { SupervisorContext } from 'src/contexts/SupervisorContext';

const NotesPage = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { navbarRef } = useContext(AppContext);
  const { supervisorHeaderHeight } = useContext(SupervisorContext);
  const { pageHeight } = useContext(AppContext);
  useEffect(() => {
    const navbarHeight = navbarRef.current?.getBoundingClientRect().height ?? 0;
    const headerHeight = headerRef.current?.getBoundingClientRect().height ?? 0;
    const usedSpace = navbarHeight + headerHeight + supervisorHeaderHeight;
    setMinHeight(`calc(${pageHeight}px - ${usedSpace}px)`);
  }, [navbarRef, headerRef, supervisorHeaderHeight, pageHeight]);

  const swiperRef = useRef(null);

  const [minHeight, setMinHeight] = useState('0px');

  return (
    <NotesScene>
      <NotesHeader swiperRef={swiperRef} ref={headerRef}></NotesHeader>
      <Box sx={{ minHeight: minHeight }}>
        <BoardSwiper swiperRef={swiperRef} minHeight={minHeight}></BoardSwiper>
      </Box>
    </NotesScene>
  );
};

export default NotesPage;
