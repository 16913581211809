import {
  setISOWeekYear,
  setISOWeek,
  startOfISOWeek,
  endOfISOWeek,
  getISOWeek,
  format,
} from 'date-fns';
import { dateLocales } from '@/i18n/dateLocales';

export const getWeekDates = ({
  week,
  year,
}: {
  week: number;
  year: number;
}): { startDate: Date; endDate: Date } => {
  // Create a date object starting from the first day of the ISO week
  const date = setISOWeek(setISOWeekYear(new Date(), year), week);

  // Get the start and end dates of the ISO week
  const startDate = startOfISOWeek(date);
  const endDate = endOfISOWeek(date);

  return { startDate, endDate };
};

export const getCurrentWeek = () => {
  //Get current week based on new Date()
  const date = new Date();

  // Get the start and end dates of the ISO week
  const startDate = startOfISOWeek(date);
  const endDate = endOfISOWeek(date);

  return { startDate, endDate };
};

export const getCurrentMonth = (): { startDate: Date; endDate: Date } => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return { startDate, endDate };
};

export const getCurrentYear = (): { startDate: Date; endDate: Date } => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), 0, 1);
  const endDate = new Date(date.getFullYear(), 11, 31);

  return { startDate, endDate };
};

export const getWeekString = (date: Date): string => {
  return `${getISOWeek(date).toString()}`;
};

export const getMonthString = (date: Date, lang: string): string => {
  return `${format(date, 'yyyy MMMM', {
    //locale: lang === 'sv' ? dateLocales.sv : dateLocales.en,
    locale: dateLocales[lang],
  })}`;
};

export const getYearString = (date: Date): string => {
  return `${format(date, 'yyyy')}`;
};

//Get a string
