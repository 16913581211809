import { Typography, Box } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dateLocales } from '@/i18n/dateLocales';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useContext } from 'react';

const StartDateText = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const { activity } = useContext(ActivityContext);

  return (
    <Box
      sx={{
        fontWeight: 'bold',
        mx: 1,
        my: 2,
        '& .MuiTypography-root': {
          fontWeight: 'inherit',
        },
      }}
    >
      <Typography component="span">{`${t('Start date')}: `}</Typography>
      <Typography
        component="span"
        sx={{
          display: 'inline-block',
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        }}
      >
        {format(activity.startDate, `EEEE do '${t('date.of')} 'MMMM`, {
          locale: dateLocales[lang],
        })}
      </Typography>
    </Box>
  );
};

export default StartDateText;
