import { Box, CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from 'src/contexts/AppContext';

const LoadingPage = ({ minHeight }: { minHeight: string }) => {
  const { pageHeight } = useContext(AppContext);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        minHeight: `${minHeight}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingPage;
