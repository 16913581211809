type CircleProgressProps = { percentage: number; color: string };

const radius = 8;
const strokeWidth = 2 * radius;
const circumference = 2 * Math.PI * radius;

const CircleProgress = (props: CircleProgressProps) => {
  const getStrokeDashValue = () => circumference * (props.percentage / 100);

  return (
    <svg viewBox="0 0 32 32">
      <g transform="translate(16 16)">
        <circle
          stroke={props.color}
          strokeWidth={strokeWidth}
          strokeDasharray={`${getStrokeDashValue()},${circumference}`}
          r={radius}
          cx="0"
          cy="0"
          fill="none"
          transform="rotate(-90) "
        ></circle>
      </g>
    </svg>
  );
};

export default CircleProgress;
