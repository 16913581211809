import { useState, useEffect, MutableRefObject } from 'react';
import CalendarDayActivity from './CalendarDayActivity';
import { ActivityInstance } from '@api';
import CalendarDayCluster from './CalendarDayCluster';
import { generateColumns } from '@/components/utils/CalendarFns';

const ClusterRenderer = ({
  cluster,
  parentRef,
}: {
  cluster: ActivityInstance[];
  parentRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const [columns, setColumns] = useState<ActivityInstance[][]>([]);
  const [width, setWidth] = useState(0);
  const [maxNumberOfColumns, setMaxNumberOfColumns] = useState(99);

  const minColumnWidth = 18; //The minimum width of a column in pixels

  useEffect(() => {
    const newColumns = generateColumns(cluster);
    setColumns(newColumns);
  }, [cluster]);

  useEffect(() => {
    if (parentRef.current) {
      setWidth(parentRef.current.clientWidth);
    }
  }, [parentRef]);

  useEffect(() => {
    if (width > 0) {
      const newMaxNumberOfColumns = Math.max(1, Math.floor(width / minColumnWidth));
      setMaxNumberOfColumns(newMaxNumberOfColumns);
    }
  }, [width]);

  return (
    <>
      {columns.length <= maxNumberOfColumns ? (
        columns.map((column: ActivityInstance[], columnIndex) =>
          column.map((instance: ActivityInstance) => (
            <CalendarDayActivity
              instance={instance}
              parentRef={parentRef}
              column={columnIndex}
              numberOfColumns={columns.length}
              key={`${instance.activityInstanceId}:${instance.renderDuration}`}
            />
          )),
        )
      ) : (
        <CalendarDayCluster instances={cluster} parentRef={parentRef} />
      )}
    </>
  );
};

export default ClusterRenderer;
