/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* Component is only rendered when activity.recurringActivity != null */
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dateLocales } from '@/i18n/dateLocales';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useContext } from 'react';

const weekDayIntervals = ['WEEKLY', 'BIWEEKLY'];

const OccurrenceText = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];

  const { activity } = useContext(ActivityContext);

  const getWeekdayIndex = (weekday: string) =>
    ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'].indexOf(weekday);

  const areWeekdaysConsecutive = (weekdays: string[]): boolean => {
    const weekdayIndices = weekdays.map(getWeekdayIndex);
    return weekdayIndices.every((weekdayIndex, index) => {
      if (index === 0) {
        return true; // first weekday is always consecutive
      }
      return weekdayIndex === weekdayIndices[index - 1] + 1;
    });
  };

  const getWeekdayList = (weekdays: string[]) => {
    const sortedWeekdays = weekdays.slice().sort((a, b) => getWeekdayIndex(a) - getWeekdayIndex(b));
    const translatedWeekdays = sortedWeekdays.map((e) => t(`days.${e.toLowerCase()}`));
    if (translatedWeekdays.length === 1) return translatedWeekdays[0];
    else if (translatedWeekdays.length === 2) return translatedWeekdays.join(` ${t('and')} `);
    else if (areWeekdaysConsecutive(sortedWeekdays)) {
      return `${translatedWeekdays[0]}-${translatedWeekdays[translatedWeekdays.length - 1]}`;
    } else {
      const lastWeekday = translatedWeekdays.pop();
      return translatedWeekdays.join(', ') + ` ${t('and')} ` + lastWeekday;
    }
  };

  return (
    <Typography
      variant="body2"
      color="grey.500"
      sx={{
        mt: 1,
        display: 'inline-block',
        textTransform: 'lowercase',
        '&:first-letter': {
          textTransform: 'uppercase',
        },
      }}
    >
      {weekDayIntervals.includes(activity.recurringActivity!.recurringInterval) ? (
        <>
          {`${t('The activity will recur')} ${t(
            'recurrence.' + activity.recurringActivity!.recurringInterval.toLowerCase(),
          )}`}
          {` ${t('on')} `}
          <Typography component="span" variant="body2" sx={{ textTransform: 'none' }}>
            {getWeekdayList(activity.recurringActivity!.recurringDays!)}
          </Typography>
        </>
      ) : (
        <>
          {t('The activity will recur')}
          {activity.recurringActivity!.recurringInterval === 'MONTHLY'
            ? format(activity.startDate, ` '${t('date.on the')} 'do '${t('date.of')} `, {
                locale: dateLocales[lang],
              })
            : format(activity.startDate, ` '${t('date.on the')} 'do '${t('date.of')} 'MMMM' `, {
                locale: dateLocales[lang],
              })}
          {t(`recurrence.every ${activity.recurringActivity!.recurringInterval.toLowerCase()}`)}
        </>
      )}
    </Typography>
  );
};

export default OccurrenceText;
