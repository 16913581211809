/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* Component is only rendered when activity.recurringActivity != null */

/* 
  TODO: 
    Component is currently converting time to UTC, but
    it should use local time in the future and let the
    backend convert it to UTC.
*/
import { Button, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import TimeInput from '@/components/utils/TimeInput';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { format } from 'date-fns';

const TimeSelect = () => {
  const { t } = useTranslation();
  const { activity, setActivity } = useContext(ActivityContext);

  //Set time of activity.recurringActivity.times[index] to date
  const setTime = (date: Date | null, index: number) => {
    if (date) {
      const newTimes = [...activity.recurringActivity!.recurringTimes!];
      newTimes[index] = format(date, 'HH:mm');
      setActivity({
        ...activity,
        recurringActivity: { ...activity.recurringActivity!, recurringTimes: newTimes },
      });
    }
  };

  const addTime = () => {
    if (activity.recurringActivity!.recurringTimes) {
      setActivity({
        ...activity,
        recurringActivity: {
          ...activity.recurringActivity!,
          recurringTimes: [...activity.recurringActivity!.recurringTimes, ''],
        },
      });
    }
  };

  const removeTime = (index: number) => {
    if (activity.recurringActivity!.recurringTimes) {
      const newTimes = [...activity.recurringActivity!.recurringTimes];
      newTimes.splice(index, 1);
      setActivity({
        ...activity,
        recurringActivity: { ...activity.recurringActivity!, recurringTimes: newTimes },
      });
    }
  };

  const convertTimeToLocalDate = (time: string): Date | null => {
    if (time.length === 0) return null;
    // create a new Date object
    const date = new Date();
    // set the UTC hours, minutes and seconds from the time variable
    date.setHours(+time.split(':')[0]);
    date.setMinutes(+time.split(':')[1]);
    return date;
  };

  return (
    <>
      {activity.recurringActivity?.recurringTimes?.map((time: string, index: number) =>
        index === 0 ? (
          <Box key={index}>
            <TimeInput value={activity.startDate} sx={{ my: 1, width: '6em' }} disabled />
            <Typography variant="body2" sx={{ mb: 1 }} color="GrayText">{`(${t(
              'Aktivitetens starttid',
            )})`}</Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }} key={index}>
            <TimeInput
              value={convertTimeToLocalDate(time)}
              onChange={(newDate: Date) => setTime(newDate, index)}
              sx={{ mr: 2, width: '6em' }}
            />
            <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Button
                variant="text"
                size="large"
                sx={{ my: 2 }}
                onClick={() => {
                  removeTime(index);
                }}
                color="error"
                startIcon={<DeleteIcon />}
              >
                {t('Remove')}
              </Button>
            </Box>
          </Box>
        ),
      )}

      <Button variant="outlined" sx={{ my: 2 }} onClick={addTime}>
        <AddIcon /> {t('Add time')}
      </Button>
    </>
  );
};

export default TimeSelect;
