/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* Component is only rendered when activity.recurringActivity != null */
import { FormHelperText, InputLabel, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { useContext, useState } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';

const EndDateSelect = () => {
  const { t } = useTranslation();
  const { activity, setActivity } = useContext(ActivityContext);

  const [dateError, setDateError] = useState<boolean | string>(false);

  const setDate = (date: Date | null) => {
    setActivity({
      ...activity,
      recurringActivity: {
        ...activity.recurringActivity!,
        recurringEndDate: date ?? null,
      },
    });
  };

  return (
    <Box sx={{ mx: 1 }}>
      <InputLabel htmlFor="endDate">{t('End date')}</InputLabel>
      <DatePicker
        value={activity.recurringActivity!.recurringEndDate}
        showDaysOutsideCurrentMonth={true}
        onChange={(newDate) => setDate(newDate)}
        onError={(err) => {
          setDateError(err ?? false);
        }}
        minDate={activity.startDate}
      ></DatePicker>
      <FormHelperText error={!!dateError}>{dateError}</FormHelperText>
    </Box>
  );
};

export default EndDateSelect;
