import { Container, Stack, Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const ActivityEdited = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Container
      sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
    >
      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Typography variant="h5" fontWeight={'bold'}>
          {t('Activity Edited')}
        </Typography>
        <CheckCircleOutlineIcon sx={{ color: palette.success.main, fontSize: '4em' }} />
        <Button onClick={() => navigate('/')} variant={'contained'} sx={{ width: '100%', p: 2 }}>
          {t('Done')}
        </Button>
      </Stack>
    </Container>
  );
};

export default ActivityEdited;
