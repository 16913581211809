import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import SimpleList from '../utils/list/SimpleList';
import { Edit } from '@mui/icons-material';
import BoardDialog from './BoardDialog';
import { Board } from '@api';
import { NotesContext } from 'src/contexts/NotesContext';
import BoardList from './BoardList/BoardList';
import AddIcon from '@mui/icons-material/Add';

const ManageBoards: React.FC = () => {
  const { t } = useTranslation();
  const [boardDialog, setBoardDialog] = useState<boolean>(false);
  const [boardToEdit, setBoardToEdit] = useState<Board | null>(null);
  const { boards } = useContext(NotesContext);

  const handleBoardClick = (board: Board) => {
    setBoardToEdit(board ?? null);
    setBoardDialog(true);
  };

  const handleCloseDialog = () => {
    setBoardDialog(false);
    setBoardToEdit(null);
  };

  const noBoardsJSX = () => (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box p={3} maxWidth={'12em'}>
        <img
          src="icons/noticeboard.svg"
          alt="noticeboard"
          width={'100%'}
          style={{ fill: 'black' }}
        />
      </Box>
      <Typography variant="subtitle1" gutterBottom textAlign={'center'}>
        {t('Board Information')}
      </Typography>
    </Box>
  );

  const boardsJSX = () => (
    <>
      <Typography variant="h6" gutterBottom textAlign={'center'}>
        {t('My note boards')}
      </Typography>
      <BoardList
        onRowClick={(value) => {
          handleBoardClick(value);
        }}
      />
      <Typography
        variant="caption"
        gutterBottom
        textAlign={'center'}
        mb={1}
        mt={1}
        fontStyle={'italic'}
      >
        {t('Click on a board to edit it')}
      </Typography>
    </>
  );

  // make sure to place the render in the middle of the page. Will use the same solution as the rest of the notes page
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={4}
      height={'100%'}
      justifyContent={'center'}
      sx={(theme) => ({ [theme.breakpoints.up('md')]: { mt: 4 } })}
    >
      {boards.length > 0 ? boardsJSX() : noBoardsJSX()}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setBoardDialog(true)}
        sx={{ m: 3 }}
        disabled={boards.length >= 4}
        startIcon={<AddIcon />}
      >
        {t('Add Board')}
      </Button>
      <BoardDialog
        open={boardDialog}
        onClose={handleCloseDialog}
        board={boardToEdit ?? undefined}
      />
    </Box>
  );
};

export default ManageBoards;
