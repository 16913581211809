import { useContext } from 'react';
import { Typography, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InstanceContext } from '@/contexts/InstanceContext';
import { ActivityApi } from '@api';

const FeelingOfActivity = () => {
  const { t } = useTranslation();

  const { instance, setInstance } = useContext(InstanceContext);

  const choices = [1, 2, 3, 4, 5];

  const setFeeling = async (feeling: number) => {
    setInstance({ ...instance, feelingOfActivity: feeling });
    await new ActivityApi().setActivityFeeling({
      activityInstanceId: instance.activityInstanceId,
      feelingOfActivity: feeling,
    });
  };

  return (
    <Stack spacing={2} pb={3} alignItems="center">
      <Stack alignItems="center">
        <Typography variant="body1" fontWeight={'bold'}>
          {t('How did you feel about the activity?')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {instance.feelingOfActivity !== undefined
            ? t(`activityFeeling.${instance.feelingOfActivity}`)
            : null}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-evenly" sx={{ maxWidth: '100%' }}>
        {choices.map((choice) => (
          <Button
            key={choice}
            onClick={() => setFeeling(choice)}
            variant="text"
            aria-label={t(`activityFeeling.${choice}`) as string}
            title={t(`activityFeeling.${choice}`) as string}
            sx={{
              minWidth: '0px',
              width: '100%',
              flexGrow: 1,
              p: 0.75,
              borderRadius: '50%',
              transition: 'all 0.05s ease-out',
              transform: instance.feelingOfActivity === choice ? 'scale(1.25)' : 'scale(1)',
            }}
          >
            <img
              src={`/emotions/${choice}.png`}
              alt={
                ((t('Emoji that represents ') as string) + t(`activityFeeling.${choice}`)) as string
              }
              height="100%"
              width="100%"
              style={{
                borderRadius: '50%',
                boxShadow:
                  instance.feelingOfActivity === choice ? 'grey 0px 2px 0.45em 0.01em' : 'none',
                transition: 'all 0.05s ease-out',
              }}
            />
          </Button>
        ))}
      </Stack>
      <Button
        onClick={() => setFeeling(0)}
        variant="outlined"
        aria-label={t("I don't want to specify") as string}
        title={t("I don't want to specify") as string}
        sx={{
          transition: 'all 0.05s ease-out',
          boxShadow: instance.feelingOfActivity === 0 ? 'grey 0px 2px 0.45em 0.01em' : 'none',
        }}
      >
        {t("I don't want to specify")}
      </Button>
    </Stack>
  );
};

export default FeelingOfActivity;
