import { Container, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import PreviousButton from '../PreviousButton';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { useTranslation } from 'react-i18next';

const MoreOptionsHeader = () => {
  // toDo move back button to own component
  const { activity } = useContext(ActivityContext);
  const { current } = useContext(ActivityFormContext);
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Stack alignItems="center" spacing={2} marginY={2}>
          <Stack direction="row" sx={{ width: '100%' }}>
            <PreviousButton />
            <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', padding: 0.5 }}>
              {t(current.formName)}
            </Typography>
          </Stack>
          <Typography
            variant="h5"
            sx={{ wordBreak: 'break-word', minHeight: '1lh' }}
            color="primary"
          >
            {!current.noActivityName && activity?.activityName}
          </Typography>
        </Stack>
      </Container>
    </>
  );
};

export default MoreOptionsHeader;
