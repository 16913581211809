import { ReactNode, useContext } from 'react';
import { SupervisorContext } from '../contexts/SupervisorContext';
import SelectClientPage from '../pages/Supervise/SelectClientPage';

type AuthGuardProps = {
  children?: ReactNode;
};

const SuperviseGuard = ({ children }: AuthGuardProps) => {
  const { currentClient, isSupervising } = useContext(SupervisorContext);
  if (!isSupervising) return <>{children}</>;
  if (isSupervising && currentClient) return <>{children}</>;
  else return <SelectClientPage />;
};

export default SuperviseGuard;
