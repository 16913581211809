import { DialogTitle, DialogContent, IconButton, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActivityInstance } from '@api';
import { CalendarContext } from '@/contexts/CalendarContext';
import { useContext } from 'react';
import ActivityInformation from './AcitivtyInformation';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { getActivityColor } from '@/components/utils/CalendarFns';
import { useTheme } from '@mui/material/styles';

const ActivityDialog = ({ activity }: { activity: ActivityInstance }) => {
  const { t } = useTranslation();
  const { setCalendarDialog } = useContext(CalendarContext);

  const { palette } = useTheme();

  const activityColor = getActivityColor(palette, activity.duration);

  return (
    <Box sx={{ borderLeft: `12px solid ${activityColor}` }}>
      <IconButton
        aria-label={t('Close') as string}
        onClick={() => setCalendarDialog(null)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="scroll-dialog-title" sx={{ wordBreak: 'break-word' }}>
        {activity.activityName}
      </DialogTitle>
      <DialogContent dividers={true}>
        <ActivityInformation activity={activity} />
        <Button
          component={Link}
          to={`/activity/${activity.activityInstanceId}`}
          variant="contained"
          sx={{ width: '100%', mt: 1 }}
          onClick={() => setCalendarDialog(null)}
        >
          {t('Go to activity')}
        </Button>
      </DialogContent>
    </Box>
  );
};

export default ActivityDialog;
