import { forwardRef, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { Note } from '@api';
import { lightenColor } from '../utils/ColorUtils';
import DeleteIcon from '@mui/icons-material/Delete';

export type NoteItemProps = HTMLAttributes<HTMLDivElement> & {
  note: Note;
  invisible?: boolean;
  isDragging?: boolean;
  deleteItem?: boolean;
  setNoteDialog?: (note: Note | boolean) => void;
  cardWidth: number;
  cardMargin: number;
  cardDragDelay: number;
};

const NoteItem = forwardRef<HTMLDivElement, NoteItemProps>(
  (
    {
      note,
      invisible,
      isDragging,
      deleteItem,
      style,
      setNoteDialog,
      cardWidth,
      cardMargin,
      cardDragDelay,
      ...props
    },
    ref,
  ) => {
    const typographyRef = useRef<HTMLDivElement>(null);
    const [maxRows, setMaxRows] = useState(4);
    const [lineHeight, setLineHeight] = useState(0);

    useEffect(() => {
      const typographyElement = typographyRef.current;

      if (typographyElement) {
        const lineHeight = parseFloat(getComputedStyle(typographyElement).lineHeight);
        setLineHeight(lineHeight);
        const containerHeight = typographyElement.clientHeight;

        // Calculate the maximum number of rows that can fit in the container
        const calculatedMaxRows = Math.floor(containerHeight / lineHeight);

        setMaxRows(calculatedMaxRows);
      }
    }, [typographyRef.current]);

    return (
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        ref={ref}
        onClick={() => setNoteDialog?.(note)}
        sx={{
          my: 1,
          mx: `${cardMargin}px`,
          height: `${cardWidth * 1.25}px`,
          width: `${cardWidth}px`,
          opacity: invisible ? '0' : '1',
          borderRadius: '10px',
          cursor: isDragging ? 'grabbing' : 'grab',
          transform: isDragging ? 'rotate(5deg)' : 'rotate(0deg)',
          backgroundColor: lightenColor(note.color, 70),
          boxShadow: isDragging ? 10 : 2,
          overflow: 'hidden',
          ...style,
        }}
        {...props}
      >
        <Box
          sx={(theme) => ({
            m: 0,
            p: 1,
            fontWeight: 'bold',
            backgroundColor: note.color,
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            flex: '0 0 auto',
            userSelect: 'none',
            msUserSelect: 'none',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none',
            color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
          })}
        >
          {note.title}
        </Box>
        <Box ref={typographyRef} sx={{ flex: 1, mt: 0.25 }}>
          <Typography
            sx={(theme) => ({
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: maxRows,
              height: `${lineHeight * maxRows}px`,
              maxHeight: '100%',
              px: 1,
              wordBreak: 'break-word',
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              userSelect: 'none',
              msUserSelect: 'none',
              MozUserSelect: 'none',
              WebkitUserSelect: 'none',
              color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.black,
            })}
          >
            {note.text}
          </Typography>
        </Box>
        {deleteItem && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'red',
              opacity: 0.3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'pink',
            }}
          >
            <DeleteIcon fontSize="large" />
          </Box>
        )}
      </Stack>
    );
  },
);

export default NoteItem;
