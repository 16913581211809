import { useTranslation } from 'react-i18next';
import BasicDialog from './BasicDialog';
import { useEffect, useState } from 'react';

type NotificationPermissionDialogProps = {
  show: boolean;
  onPermissionGranted: () => void;
};
const NotificationPermissionDialog = ({
  show,
  onPermissionGranted,
}: NotificationPermissionDialogProps) => {
  const notificationsEnabled = localStorage.getItem('notifications');
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (show && !open) {
      if (Notification.permission === 'granted') {
        onPermissionGranted();
      } else if (Notification.permission !== 'denied') {
        if (notificationsEnabled === null) setOpen(true);
      }
    }
  }, [show]);

  const dialogContent = {
    title: t('notificationPermission.title'),
    body: t('notificationPermission.body'),
    confirmText: t('notificationPermission.buttonYes'),
    cancelText: t('notificationPermission.buttonNo'),
  };

  const askPermission = async () => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      localStorage.setItem('notifications', 'true');
      console.log('Notification permission granted.');
      onPermissionGranted();
    } else if (permission === 'denied') {
      localStorage.setItem('notifications', 'false');
      console.log('Notification permission denied.');
    } else {
      console.warn('Unable to get permission to notify.');
    }
  };

  return (
    <BasicDialog
      open={open}
      content={dialogContent}
      onClose={() => setOpen(false)}
      onCancel={() => localStorage.setItem('notifications', 'false')}
      onConfirm={askPermission}
    />
  );
};

export default NotificationPermissionDialog;
