import { Button, InputLabel, TextField, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useContext, useState, useEffect } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { ActivityFormContext, ActivityForms } from '@/contexts/ActivityFormContext';
import AddIcon from '@mui/icons-material/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import MoreOptionsHeader from './MoreOptionsHeader';
import ImageInput from './ImageInput';
import OccurrenceText from './OccurrenceText';
import SubtaskText from './SubtaskText';
import EditIcon from '@mui/icons-material/Edit';
import { validateDescription } from '@/components/utils/Validate';

type MoreOptionsFormProps = {
  noHeader?: boolean;
  validateForm?: boolean;
  hideRecurrence?: boolean;
};

const MoreOptionsForm = ({
  noHeader = false,
  validateForm = true,
  hideRecurrence,
}: MoreOptionsFormProps) => {
  const { t } = useTranslation();
  const { activity, setActivity } = useContext(ActivityContext);
  const [descriptionError, setDescriptionError] = useState<boolean | string>(false);
  const [description, setDescription] = useState<string>(activity.description);
  const [imageError, setImageError] = useState<boolean>(false);
  const formHandler = useContext(ActivityFormContext);
  const addSubtasks = () => {
    formHandler.goToForm(ActivityForms.AddSubtasks);
  };
  const SetRecurrence = () => {
    formHandler.goToForm(ActivityForms.SetRecurrence);
  };

  const setImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageError(false);
    if (activity.subtasks === undefined) return;
    const file = e.target.files?.[0];
    if (file) {
      if (file.type && file.type.startsWith('image/')) {
        //Set image of the item
        setActivity({
          ...activity,
          picture: URL.createObjectURL(file),
          pictureFile: file,
          pictureEdited: true,
        });
      } else {
        setImageError(true);
        //Clear image from activity
        setActivity({ ...activity, picture: '', pictureFile: undefined, pictureEdited: true });
      }
    }
  };

  useEffect(() => {
    const error = validateDescription(description, t);
    if (error === null && activity.activityName.trim().length) {
      setActivity({ ...activity, description: description });
      validateForm && formHandler.setValid(true);
      setDescriptionError(false);
    } else {
      !validateForm && setActivity({ ...activity, description: description });
      validateForm && formHandler.setValid(false);
      if (error) setDescriptionError(error);
    }
  }, [description]);

  return (
    <>
      {noHeader ? null : <MoreOptionsHeader />}
      <ImageInput
        id="addImage"
        value={activity.picture}
        onChange={setImage}
        sx={{ mx: 'auto', width: '75%', maxWidth: '14em', height: null, aspectRatio: '1/1' }}
        onRemove={() => setActivity({ ...activity, picture: '', pictureFile: undefined })}
      />
      {imageError && (
        <Typography color="error" variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
          {t('Only images are allowed')}
        </Typography>
      )}
      <InputLabel htmlFor="addTime" sx={{ mt: 4 }}>
        {t('Activity description')}
      </InputLabel>
      <TextField
        id="activityDescription"
        multiline
        maxRows={5}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder={`${t('Add a description')}..`}
        inputProps={{ maxLength: 200 }}
        error={!!descriptionError}
        helperText={descriptionError}
        onKeyDown={(event) => {
          if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
        }}
      />
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ display: 'flex', justifyContent: 'end', marginTop: '4px' }}
      >
        {description.length}/200
      </Typography>
      <InputLabel sx={{ mt: 4 }}>{t('Add subtasks')}</InputLabel>
      <Button
        variant="outlined"
        id="addSubtasks"
        onClick={addSubtasks}
        startIcon={activity.subtasks?.length ? <EditIcon /> : <AddIcon />}
        size="large"
      >
        {activity.subtasks?.length ? t('Edit subtasks') : t('Add subtasks')}
      </Button>
      {activity.subtasks?.length ? <SubtaskText /> : null}
      {hideRecurrence ? null : (
        <>
          <InputLabel sx={{ mt: activity.subtasks?.length ? 2 : 4 }}>{t('Recurrence')}</InputLabel>
          <Button
            variant="outlined"
            id="serRecurrence"
            onClick={SetRecurrence}
            startIcon={<ReplayIcon sx={{ transform: 'rotate(200deg) scaleY(-1)' }} />}
            size="large"
          >
            {activity.subtasks?.length ? t('Edit recurrence') : t('Set recurrence')}
          </Button>
          {activity.recurringActivity && activity.recurringActivity.recurringEndDate && (
            <OccurrenceText />
          )}
        </>
      )}
    </>
  );
};

export default MoreOptionsForm;
