/* tslint:disable */
/* eslint-disable */

import * as runtime from '../runtime';
import { BaseAPI } from '../baseApi';
import { Client, Supervisor, SupervisorConnection } from '../types';
import { HttpStatusCode } from 'axios';

export interface AcceptClientRequest {
  client: Client;
}
export interface DeclineClientRequest {
  client: Client;
}

export interface CreateSupervisorRequest {
  tag: string;
}

export interface RemoveSupervisorRequest {
  supervisor: Supervisor;
}
export interface RemoveClientRequest {
  client: Client;
}
export interface RemoveSupervisorConnectionRequest {
  tag: string;
}

export class SupervisorApi extends BaseAPI {
  async acceptClient(requestParameters: AcceptClientRequest): Promise<HttpStatusCode | undefined> {
    const uri = encodeURIComponent(requestParameters.client.tag);

    try {
      const response = await this.request({
        path: `/supervisors/${uri}`,
        method: 'PUT',
      });

      return response.status;
    } catch (error) {}
  }

  async declineClient(
    requestParameters: DeclineClientRequest,
  ): Promise<HttpStatusCode | undefined> {
    return this.removeSupervisorConnection({ tag: requestParameters.client.tag });
  }

  async createSupervisor(
    requestParameters: CreateSupervisorRequest,
  ): Promise<HttpStatusCode | undefined> {
    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    try {
      const response = await this.request({
        path: `/supervisors`,
        method: 'POST',
        headers: headerParameters,
        body: requestParameters.tag,
      });

      return response.status;
    } catch (error) {}
  }

  async getSupervisorConnections(): Promise<Array<SupervisorConnection> | undefined> {
    try {
      const response = await this.request({
        path: `/supervisors`,
        method: 'GET',
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Array<SupervisorConnection> => jsonValue,
      ).value();
    } catch (error) {}
  }

  async removeSupervisorConnection(
    requestParameters: RemoveSupervisorConnectionRequest,
  ): Promise<HttpStatusCode | undefined> {
    try {
      const uri = encodeURIComponent(requestParameters.tag);

      const response = await this.request({
        path: `/supervisors/${uri}`,
        method: 'DELETE',
      });

      return response.status;
    } catch (error) {}
  }

  async removeClient(requestParameters: RemoveClientRequest): Promise<HttpStatusCode | undefined> {
    try {
      const uri = encodeURIComponent(requestParameters.client.tag);

      const response = await this.request({
        path: `/supervisors/client/${uri}`,
        method: 'DELETE',
      });

      return response.status;
    } catch (error) {}
  }
  async removeSupervisor(
    requestParameters: RemoveSupervisorRequest,
  ): Promise<HttpStatusCode | undefined> {
    try {
      const uri = encodeURIComponent(requestParameters.supervisor.tag);

      const response = await this.request({
        path: `/supervisors/supervisor/${uri}`,
        method: 'DELETE',
      });

      return response.status;
    } catch (error) {}
  }
}
