import { Container, Stack } from '@mui/material';
import Greeting from '@/components/calendar/daily/Greeting';
import DisplayDate from '@/components/calendar/daily/DisplayDate';
import CompletedDailyActivities from '@/components/calendar/daily/CompletedDailyActivities';
import AddActivityButton from '@/components/calendar/daily/AddActivityButton';
import MissedActivities from '@/components/calendar/daily/MissedActivities';
import OngoingActivities from '@/components/calendar/daily/OngoingActivities';
import UpcomingActivities from '@/components/calendar/daily/UpcomingActivities';
import { CalendarDayScene } from '@/contexts/CalendarDayContext';
import AiOnboarding from '@/components/utils/dialogs/AiOnboardingDialog';

const DailyPage = () => {
  return (
    <CalendarDayScene date={new Date()}>
      <Container>
        <Stack spacing={3} pb={3} pt={4}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: { mx: 4, mt: 2 },
            })}
          >
            <Greeting />
            <DisplayDate />
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: { mx: 4 },
            })}
          >
            <CompletedDailyActivities />
            <AddActivityButton />
          </Stack>

          <MissedActivities />
          <OngoingActivities />
          <UpcomingActivities />
          <AiOnboarding />
        </Stack>
      </Container>
    </CalendarDayScene>
  );
};

export default DailyPage;
