import { useContext, useMemo } from 'react';
import { CalendarDayContext } from '@/contexts/CalendarDayContext';
import { isDate } from 'date-fns';
import Statistics from '../Statistics';
import { useTranslation } from 'react-i18next';

const CompletedDailyActivities = () => {
  const { t } = useTranslation();
  const { instances } = useContext(CalendarDayContext);

  const completedInstances = useMemo(
    () => instances.filter((instance) => isDate(instance.dateIsCompleted)).length,
    [instances],
  );

  return (
    <>
      <Statistics
        completedInstances={completedInstances}
        title={t('Completed activities today')}
        visible={!!completedInstances}
      />
    </>
  );
};

export default CompletedDailyActivities;
