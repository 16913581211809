import { RadioGroup, Typography, Radio, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { RecurrenceInterval } from '@api';
import { format } from 'date-fns';
import { RecurrenceDay } from '@api';
import { weekDays } from './DaySelect';

const recurrenceOptions: Array<RecurrenceInterval> = [
  'DAILY',
  'WEEKLY',
  'BIWEEKLY',
  'MONTHLY',
  'YEARLY',
];

const RecurrenceForm = () => {
  const { t } = useTranslation();
  const { activity, setActivity } = useContext(ActivityContext);

  const startDay = format(activity.startDate, 'EEEE').toUpperCase();

  const getRecurringDays = (val: RecurrenceInterval, oldVal?: RecurrenceInterval) => {
    if (val === 'DAILY') return weekDays;
    if (oldVal === 'DAILY') return [startDay as RecurrenceDay];

    return [
      ...(activity.recurringActivity?.recurringDays
        ? activity.recurringActivity.recurringDays.filter((item) => item !== startDay)
        : []),
      startDay as RecurrenceDay,
    ];
  };

  const setInterval = (val: RecurrenceInterval) => {
    setActivity({
      ...activity,
      recurringActivity: {
        recurringInterval: val,
        recurringDays: getRecurringDays(val, activity.recurringActivity?.recurringInterval),
        recurringEndDate: activity.recurringActivity?.recurringEndDate ?? null,
        recurringTimes: activity.recurringActivity?.recurringTimes ?? [
          format(activity.startDate, 'HH:mm'),
        ],
      },
    });
  };

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="female"
      name="radio-buttons-group"
      value={activity.recurringActivity ? activity.recurringActivity.recurringInterval : null}
      onChange={(e) => setInterval(e.target.value as RecurrenceInterval)}
      sx={{ ml: 1 }}
    >
      {recurrenceOptions.map((option) => (
        <FormControlLabel
          value={option}
          control={<Radio />}
          key={option}
          label={
            <Typography component="span" sx={{ '&:first-letter': { textTransform: 'uppercase' } }}>
              {t(`recurrence.${option.toLowerCase()}`)}
            </Typography>
          }
        />
      ))}
    </RadioGroup>
  );
};

export default RecurrenceForm;
