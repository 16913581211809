import React from 'react';
import { Box } from '@mui/material';

const MainLogo = () => {
  const logoHeight = '100px';

  return (
    <Box
      width={'100%'}
      height={logoHeight}
      sx={{
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/icons/tmwlogo512.svg`}
        alt="Logo"
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default MainLogo;
