import { enUS, sv, de, fr, es, it, pl } from 'date-fns/esm/locale';

export const dateLocales: Record<string, Locale> = {
  en: enUS,
  sv,
  de,
  fr,
  es,
  it,
  pl,
};
