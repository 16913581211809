import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '@/contexts/AppContext';
import { useTranslation } from 'react-i18next';

const Greeting = () => {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Stack>
      <Typography variant="h6">{`${t('hi')},`}</Typography>
      <Typography variant="h5" fontWeight={'bold'}>
        {user?.firstName}
      </Typography>
    </Stack>
  );
};

export default Greeting;
