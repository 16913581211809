import { Button, Container, Box } from '@mui/material';
import { useContext, useState } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { useTranslation } from 'react-i18next';
import { ResponseError } from '@/api/runtime';
import CircularProgress from '@mui/material/CircularProgress';
import { InstanceContext } from 'src/contexts/InstanceContext';

const NextButton = () => {
  const formHandler = useContext(ActivityFormContext);
  const { createOffspring, createActivity, editActivity } = useContext(ActivityContext);
  const { instance } = useContext(InstanceContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const createErrorMessage = (error: ResponseError) => {
    if (error.response) {
      return `${error.response?.data?.statusCode}: ${error.response?.data?.errorMessage}`;
    } else {
      return t('No response from server.');
    }
  };

  const handleNext = async () => {
    if (formHandler.current.nextFormAction === 'Create') {
      setLoading(true);
      const res = await createActivity?.();
      if (res instanceof ResponseError) {
        formHandler.setFormError(createErrorMessage(res));
        setLoading(false);
        return;
      }
    }
    if (formHandler.current.nextFormAction === 'Edit') {
      setLoading(true);
      const res = await editActivity();
      if (res instanceof ResponseError) {
        formHandler.setFormError(createErrorMessage(res));
        setLoading(false);
        return;
      }
    }
    if (formHandler.current.nextFormAction === 'EditSingleActivity') {
      setLoading(true);
      const res = await createOffspring?.(instance);
      if (res instanceof ResponseError) {
        formHandler.setFormError(createErrorMessage(res));
        setLoading(false);
        return;
      }
    }
    // Handle other actions here
    formHandler.goToNextForm();
  };

  return (
    <>
      {formHandler.current.nextForm && (
        <Container sx={{ p: 2 }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              onClick={handleNext}
              variant={'contained'}
              disabled={!formHandler.current.isValid}
              sx={{ width: '100%', padding: 1.25 }}
            >
              {formHandler.current.nextFormText
                ? t(formHandler.current.nextFormText)
                : formHandler.current.nextForm}
            </Button>
          )}
        </Container>
      )}
    </>
  );
};

export default NextButton;
