import { useContext, useEffect, useState } from 'react';
import { Typography, Alert, Button, Container, Box } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useTranslation } from 'react-i18next';

import { ActivityContext } from '@/contexts/ActivityContext';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import StartDateText from './StartDateText';
import DaySelect from './DaySelect';
import TimeSelect from './TimeSelect';
import ClearRecurrence from './ClearRecurrence';
import MoreOptionsAccordion from './MoreOptionsAccordion';
import MoreOptionsHeader from './MoreOptionsHeader';
import RecurrenceForm from './RecurrenceForm';
import EndDateSelect from './EndDateSelect';
import { validateRecurrence } from '@/components/utils/Validate';
import { SupervisorContext } from 'src/contexts/SupervisorContext';
import { AppContext } from 'src/contexts/AppContext';

const weekDayIntervals = ['WEEKLY', 'BIWEEKLY'];

const SetRecurrence = () => {
  const formHandler = useContext(ActivityFormContext);
  const { activity } = useContext(ActivityContext);
  const [formValid, setFormValid] = useState<boolean | string>(false);
  const { t } = useTranslation();
  const { supervisorHeaderHeight } = useContext(SupervisorContext);
  const { pageHeight } = useContext(AppContext);

  const SetRecurrence = () => {
    formHandler.goToPreviousForm();
  };

  useEffect(() => {
    const error = validateRecurrence(activity.recurringActivity, t);
    if (error) {
      formHandler.setValid(false);
      setFormValid(false);
    } else {
      formHandler.setValid(true);
      setFormValid(true);
    }
  }, [activity]);

  return (
    <Box
      sx={{
        minHeight: `calc(${pageHeight}px - ${supervisorHeaderHeight}px - 24px)`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <MoreOptionsHeader />
      <Typography variant="h6">{`${t('The activity will recur')}..`}</Typography>
      <RecurrenceForm />
      {activity.recurringActivity && (
        <>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {t('Recurrence options')}
          </Typography>
          {weekDayIntervals.includes(activity.recurringActivity.recurringInterval) && (
            <MoreOptionsAccordion
              headerIcon={<DateRangeIcon sx={{ mr: 1 }} />}
              headerText={t('Select days')}
            >
              <DaySelect />
            </MoreOptionsAccordion>
          )}
          <MoreOptionsAccordion
            headerIcon={<ScheduleIcon sx={{ mr: 1 }} />}
            headerText={t('Select time')}
          >
            <TimeSelect />
          </MoreOptionsAccordion>
          <StartDateText />
          {activity.recurringActivity.recurringInterval === 'MONTHLY' &&
            activity.startDate.getDate() > 27 && (
              <Alert severity="info" sx={{ mb: 2 }}>
                {t(
                  `Note: If the activity starts on a late date (29th, 30th, or 31st) and the month doesn't have enough days, it will be scheduled on the last day of that month instead.`,
                )}
              </Alert>
            )}
          {activity.recurringActivity.recurringInterval === 'YEARLY' &&
            activity.startDate.getDate() > 28 &&
            activity.startDate.getMonth() === 1 && (
              <Alert severity="info" sx={{ mb: 2 }}>
                {t(
                  'Note: If the activity starts on February 29th, it will be scheduled on February 28th in non-leap years.',
                )}
              </Alert>
            )}
          <EndDateSelect />
          <ClearRecurrence />
        </>
      )}
      <Box id="divider" sx={{ flex: 1 }} />
      <Container sx={{ py: 2, px: 0 }}>
        <Button
          sx={{ width: '100%', padding: 1.25 }}
          variant="contained"
          onClick={SetRecurrence}
          disabled={!formValid}
        >
          {t('Set recurrence')}
        </Button>
      </Container>
    </Box>
  );
};

export default SetRecurrence;
