import { DialogTitle, DialogContent, Button, Box, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActivityInstance } from '@api';
import { CalendarContext } from '@/contexts/CalendarContext';
import { useContext } from 'react';
import ActivityInformation from './AcitivtyInformation';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { getActivityColor } from '@/components/utils/CalendarFns';
import { useTheme } from '@mui/material/styles';

const ClusterDialog = ({ activities }: { activities: ActivityInstance[] }) => {
  const { t } = useTranslation();
  const { setCalendarDialog } = useContext(CalendarContext);
  const { palette } = useTheme();

  return (
    <>
      <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
        <DialogTitle id="scroll-dialog-title">{t('Grouped activities')}</DialogTitle>
        <IconButton
          aria-label={t('Close') as string}
          onClick={() => setCalendarDialog(null)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {activities
        .sort(
          (a: ActivityInstance, b: ActivityInstance) =>
            a.startDate.getTime() - b.startDate.getTime(),
        )
        .map((activity) => (
          <Box
            sx={{
              mx: 2,
              my: 2,
              borderRadius: '5px',
              boxShadow: 3,
              borderLeft: `10px solid ${getActivityColor(palette, activity.duration)}`,
            }}
            key={activity.activityInstanceId}
          >
            <DialogContent>
              <DialogTitle id="scroll-dialog-title" sx={{ pt: 0, pl: 1, wordBreak: 'break-word' }}>
                {activity.activityName}
              </DialogTitle>
              <ActivityInformation activity={activity} useParagraph={false} />
              <Button
                component={Link}
                to={`/activity/${activity.activityInstanceId}`}
                variant="contained"
                sx={{ width: '100%', mt: 1 }}
                onClick={() => setCalendarDialog(null)}
              >
                {t('Go to activity')}
              </Button>
            </DialogContent>
          </Box>
        ))}
    </>
  );
};

export default ClusterDialog;
