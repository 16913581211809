import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up" />;
};

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  console.log(userAgent);
  return /iphone|ipad|ipod/.test(userAgent);
};

const InstallPWA = () => {
  const isApplication = window.isApp;
  const { t } = useTranslation();
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [show, setShow] = useState<boolean>(false);
  const [showInstallGuide, setShowInstallGuide] = useState<boolean>(false);
  const [promptSupported, setPromptSupported] = useState<boolean>(false);

  useEffect(() => {
    // Check if app is running in standalone mode
    if (isApplication) {
      setShow(false);
      return;
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
      // App is in standalone mode; don't show the install option
      setShow(false);
      return;
    }

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShow(true);
      setPromptSupported(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    const timeoutId = setTimeout(() => {
      if (!promptSupported) setShow(true);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (!promptSupported) {
      setShowInstallGuide(true);
      return;
    }

    setShow(false);

    deferredPrompt.prompt();

    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
    });
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  return (
    <Snackbar
      open={show}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      {showInstallGuide ? (
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          {isIos() ? (
            <>
              <AlertTitle>{t('install.iosManual.title')}</AlertTitle>
              <p style={{ whiteSpace: 'pre-line' }}>{t('install.iosManual.description')}</p>
            </>
          ) : (
            <>
              <AlertTitle>{t('install.manual.title')}</AlertTitle>
              <p style={{ whiteSpace: 'pre-line' }}>{t('install.manual.description')}</p>
            </>
          )}
        </Alert>
      ) : (
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          <AlertTitle>{t('install.title')}</AlertTitle>
          <Button color="primary" size="small" onClick={handleInstallClick} variant="contained">
            {t('install.add')}
          </Button>
        </Alert>
      )}
    </Snackbar>
  );
};

export default InstallPWA;
