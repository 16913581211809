import { PaletteMode } from '@mui/material';

export type weekdayColor = {
  main: string;
  border: string;
  underline?: string;
  light: string;
};

export type activityColors = {
  green: string;
  purple: string;
  blue: string;
  red: string;
  yellow: string;
  turquoise: string;
};

declare module '@mui/material/styles/createPalette' {
  // eslint-disable-next-line
  interface Palette {
    line: string;
    lineLight: string;
    lineDark: string;
    white: string;
    black: string;
    weekdays: {
      monday: weekdayColor;
      tuesday: weekdayColor;
      wednesday: weekdayColor;
      thursday: weekdayColor;
      friday: weekdayColor;
      saturday: weekdayColor;
      sunday: weekdayColor;
    };
    activities: activityColors;
    backdrop: string;
  }
}

const lightPalette = {
  mode: 'light' as PaletteMode,
  primary: { main: '#006151' },
  secondary: { main: '#f6f8fe' },
  error: { main: '#E53935' },
  warning: { main: '#FACC15' },
  success: { main: '#00C566' },
  weekdays: {
    monday: { main: '#70A560', border: 'none', light: '#e9f8e4' },
    tuesday: { main: '#5B92D1', border: 'none', light: '#dee9f6' },
    wednesday: {
      main: '#ffffff',
      border: '2px solid lightgray',
      underline: '#cacaca',
      light: '#f6f6f6',
    },
    thursday: { main: '#C78F46', border: 'none', light: '#fff1dd' },
    friday: { main: '#F4EA2E', border: 'none', light: '#fffdd0' },
    saturday: { main: '#FFA0B2', border: 'none', light: '#ffecef' },
    sunday: { main: '#FB5050', border: 'none', light: '#ffeded' },
  },
  moods: {
    bad: {
      main: '#eb2c3a',
    },
    2: {
      main: '#ee8130',
    },
    3: { main: '#fce92b' },
    4: { main: '#91bd49' },
    5: { main: '#009f54' },
  },
  activities: {
    green: '#1F8900',
    purple: '#7338BE',
    blue: '#2873DB',
    red: '#D1435B',
    yellow: '#D7C30F',
    turquoise: '#078484',
  },
  white: '#fefefe',
  lineLight: '#ECF1F6',
  line: '#e3e7ec',
  lineDark: '#4a4a65',
  black: '#111111',
  calendarLine: '#eeeeee',
  googleWhite: { main: '#fff' },
  appleBlack: { main: '#000 ' },
  backdrop: '#f8f8f8',
};

//Mostly AI-generated, but looks good enough
const darkPalette = {
  mode: 'dark' as PaletteMode,
  primary: { main: '#00e6c8' }, // Adjusted primary color for better contrast
  secondary: { main: '#18191A' }, // Darker secondary color for dark mode
  error: { main: '#EF5350' }, // Darker error color
  warning: { main: '#FFD54F' }, // Darker warning color
  success: { main: '#66BB6A' }, // Adjusted success color for better contrast
  background: { default: '#121212', paper: '#2b2b2b' }, // Darker background color
  weekdays: {
    monday: { main: '#70A560', border: 'none', light: '#364038' }, // Adjusted light color for better contrast
    tuesday: { main: '#5B92D1', border: 'none', light: '#1E2E4A' }, // Adjusted light color for better contrast
    wednesday: {
      main: '#ffffff', // Darker color for Wednesday
      light: '#292A2B', // Darker light color
    },
    thursday: { main: '#C78F46', border: 'none', light: '#503C19' }, // Adjusted light color for better contrast
    friday: { main: '#F4EA2E', border: 'none', light: '#4E4817' }, // Adjusted light color for better contrast
    saturday: { main: '#FFA0B2', border: 'none', light: '#5D2A39' }, // Adjusted light color for better contrast
    sunday: { main: '#FB5050', border: 'none', light: '#5A1B1B' }, // Adjusted light color for better contrast
  },
  activities: {
    green: '#1F8900',
    purple: '#7338BE',
    blue: '#2873DB',
    red: '#D1435B',
    yellow: '#D7C30F',
    turquoise: '#078484',
  },
  white: '#18191A', // Darker white color for dark mode
  lineLight: '#2C2D2E', // Darker lineLight color
  line: '#333333', // Darker line color
  lineDark: '#888888', // Darker lineDark color
  black: '#FDFDFD', // Lighter black color for better contrast
  calendarLine: '#eeeeee',
  googleWhite: { main: '#fff' }, // Google white remains the same
  appleBlack: { main: '#000 ' }, // Apple black remains the same
  backdrop: '#0e0e0e',
};

const getPalette = (mode: PaletteMode) => (mode === 'dark' ? darkPalette : lightPalette);

const appTHeme = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none' as const,
          borderRadius: '1em',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 'unset',
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } },
    },
  },
};

export { appTHeme, getPalette };
