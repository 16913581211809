import { Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StartPageTitle = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];

  // The play badge is only available in Swedish and English, fallback to english
  const badgeLang = lang === 'sv' ? 'sv' : 'en';

  return (
    <>
      <Typography variant="h4" textAlign={'center'} sx={{ position: 'sticky', top: 15 }}>
        {'Todej'}
      </Typography>
      <Stack flex={1} alignItems={'center'}>
        <Typography variant="subtitle1" textAlign={'center'}>
          {'- '}
          {t('Your digital coach')}
        </Typography>
        <Stack direction={'row'} justifyContent={'space-evenly'} pt={4}>
          <Link
            href="https://play.google.com/store/apps/details?id=com.cybercom.cyberday&pli=1"
            sx={{ m: 1 }}
          >
            <img
              src={`start/badges/google-play-badge_${badgeLang}.png`}
              alt="google play store"
              style={{ height: '40px' }}
            ></img>
          </Link>
          <Link href="https://apps.apple.com/se/app/todej/id1481061231" sx={{ m: 1 }}>
            <img
              src={`start/badges/app-store-badge_${badgeLang}.svg`}
              alt="app store"
              style={{ height: '40px' }}
            ></img>
          </Link>
        </Stack>
      </Stack>
    </>
  );
};

export default StartPageTitle;
