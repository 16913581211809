/* tslint:disable */
/* eslint-disable */

import * as runtime from '../runtime';
import { BaseAPI } from '../baseApi';
import { User } from '../types';
import { HttpStatusCode } from 'axios';

export interface UpdateTimeZoneRequest {
  timeZone: string;
}

export class AuthApi extends BaseAPI {
  /**
   *
   * Operation for logging in to TMW
   */
  async getUser(): Promise<User | null> {
    try {
      const response = await this.request({
        path: `/user`,
        method: 'GET',
      });

      const res = await new runtime.JSONApiResponse(
        response,
        (jsonValue): User => jsonValue,
      ).value();
      return res;
    } catch (e) {
      return null;
    }
  }

  async deleteAccount(): Promise<HttpStatusCode | null> {
    try {
      const response = await this.request({
        path: `/user`,
        method: 'DELETE',
      });
      return response.status;
    } catch (e) {
      return null;
    }
  }

  async updateTimeZone(timeZone: string): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};
    headerParameters['Content-Type'] = 'application/json';

    try {
      const response = await this.request({
        path: `/user/time-zone`,
        method: 'PUT',
        query: queryParameters,
        headers: headerParameters,
        body: timeZone,
      });

      return response.status;
    } catch (error) {}
  }

  /**
   *
   * Log out of your account
   */
  async logout(): Promise<HttpStatusCode | undefined> {
    try {
      const response = await this.request({
        path: `/logout`,
        method: 'POST',
      });

      return response.status;
    } catch (error) {}
  }
}
