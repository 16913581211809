import { Box, Typography } from '@mui/material';
import { ActivityInstance } from '@api';
import { MutableRefObject, useState, useEffect, useRef, useContext } from 'react';
import { format } from 'date-fns';
import {
  minActivityRenderHeight,
  resizeTextHorizontally,
  resizeTextVertically,
} from '@/components/utils/CalendarFns';
import { CalendarContext } from '@/contexts/CalendarContext';
import { getActivityColor } from '@/components/utils/CalendarFns';
import { useTheme } from '@mui/material/styles';

//A component that renders each day
const CalendarDayActivity = ({
  instance,
  parentRef,
  column,
  numberOfColumns,
}: {
  instance: ActivityInstance;
  parentRef: MutableRefObject<HTMLDivElement | null>;
  column: number;
  numberOfColumns: number;
}) => {
  const { setCalendarDialog } = useContext(CalendarContext);
  const [parentHeight, setParentHeight] = useState(0); // store the parent height in state
  const [startTime, setStartTime] = useState(0); // The height the activity should be rendered at
  const [duration, setDuration] = useState(0); // The height the activity should be rendered at

  const { palette } = useTheme();

  useEffect(() => {
    // get the parent height when the component mounts
    if (parentRef)
      if (parentRef.current) {
        const height = parentRef.current.getBoundingClientRect().height;
        setParentHeight(height);
      }
  }, []);

  useEffect(() => {
    //Set start time and duration
    if (parentHeight > 0) {
      //Calculate the distance between each number component based on the parent height and the number of components
      const distance = parentHeight / 24;
      //Set the start time based on the distance between each number component and the start time of the activity
      const hours = instance.startDate.getHours();
      const minutes = instance.startDate.getMinutes() / 60;
      setStartTime(distance * (hours + minutes));

      const duration = Math.max(instance.renderDuration || 0, minActivityRenderHeight);

      //Set the duration based on the distance between each number component and the duration of the activity
      setDuration(distance * (duration / 60));
    }
  }, [parentHeight]);

  useEffect(() => {
    // Hide text if it is too small
    if (textRef.current === null || duration === 0) return;
    const visible = resizeTextHorizontally(textRef);
    if (visible) resizeTextVertically(textRef, duration, 1.5, 1);
  }, [duration]);

  const textRef = useRef<HTMLDivElement>(null);

  const activityStartTime = format(new Date(instance.startDate), 'HH:mm');

  const activityColor = getActivityColor(palette, instance.duration);

  return (
    <Box
      onClick={() => setCalendarDialog([instance.activityInstanceId])}
      sx={{
        position: 'absolute',
        top: `${startTime}px`,
        left: `${column * (100 / numberOfColumns)}%`,
        width: `${100 / numberOfColumns}%`,
        height: `${duration}px`,
        textAlign: 'center',
        backgroundColor: palette.background.paper,
        border: '1px solid lightgray',
        borderLeft: `4px solid ${activityColor}`,
        borderRadius: '2px',
        boxShadow: 1,
        zIndex: 1,
      }}
    >
      <Typography variant="body2" ref={textRef} sx={{ lineHeight: '1.5', fontSize: '0.8rem' }}>
        {instance.renderDuration === instance.duration ||
        instance.startDate.getHours() !== 0 ||
        instance.startDate.getMinutes() !== 0
          ? activityStartTime
          : ''}
      </Typography>
    </Box>
  );
};

export default CalendarDayActivity;
