import { AccordionSummary, Stack, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsConfig } from './SettingsAccordionConfig';
import { unsubscribe, subscribe, getExistingSubscription } from '@/PushNotificationsProvider';

import NotificationDialog from '../utils/dialogs/NotificationDialog';

const NotificationToggle = (props: SettingsConfig) => {
  const { t } = useTranslation();
  const notificationsEnabled = localStorage.getItem('notifications');
  const [notifications, setNotifications] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const dialogContent = {
    title: t('notificationPermission.title'),
    body: t('notificationPermission.deniedDialog'),
  };

  useEffect(() => {
    if (notificationsEnabled === 'true') setNotifications(true);

    const checkIfSubscribed = async () => {
      const existingSubscription = await getExistingSubscription();
      setNotifications(!!existingSubscription);
    };
    checkIfSubscribed();
  }, []);

  const toggleNotifications = async () => {
    const isIosApp = window.isIosApp;

    if (isIosApp) {
      if (
        (window as any).webkit &&
        (window as any).webkit.messageHandlers &&
        (window as any).webkit.messageHandlers.messageListener
      )
        if (notifications) {
          (window as any).webkit.messageHandlers.messageListener.postMessage('UNSUBSCRIBE');
        } else {
          (window as any).webkit.messageHandlers.messageListener.postMessage('SUBSCRIBE');
        }
    } else {
      if (notifications) unsubscribe();
      else {
        if (typeof Notification !== 'undefined') {
          const permission = Notification.permission;

          if (permission === 'denied') setOpen(true);
          else {
            subscribe();
          }
        }
      }
    }

    setNotifications(!notifications);
  };

  return (
    <>
      <NotificationDialog open={open} content={dialogContent} onClose={() => setOpen(false)} />
      <AccordionSummary sx={{ mr: -1 }}>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          width={'100%'}
          alignItems={'center'}
          onClick={(e) => {
            e.stopPropagation();
            toggleNotifications();
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t(props.title)}
          </Typography>
          <Switch
            checked={notifications}
            inputProps={{ 'aria-label': t('Toggle notifications') as string }}
          />
        </Stack>
      </AccordionSummary>
    </>
  );
};

export default NotificationToggle;
