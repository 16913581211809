import { Stack } from '@mui/material';
import WeekSelect from '@/components/calendar/weekly/WeekSelect';
import WeekCalendar from '@/components/calendar/weekly/WeekCalendar';
import CompletedWeeklyActivities from '@/components/calendar/weekly/CompletedWeeklyActivities';
import { useRef } from 'react';

const WeekPage = () => {
  const headerRef = useRef(null);
  return (
    <Stack sx={{ height: '100%', px: 1 }} alignItems={'center'}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        mt={3}
        ref={headerRef}
        width={'100%'}
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: { px: 4 },
        })}
      >
        <CompletedWeeklyActivities />
        <WeekSelect />
      </Stack>
      <WeekCalendar headerRef={headerRef} />
    </Stack>
  );
};

export default WeekPage;
