import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const storedLang = localStorage.getItem('i18nextLng');

const LanguageSetting = () => {
  const { t } = useTranslation();
  const switchLang = async (lang: string) => {
    if (lang !== storedLang) {
      localStorage.setItem('i18nextLng', lang);
      window.location.reload();
    }
  };

  const setVariant = (lang: string) => {
    if (lang === storedLang) {
      return 'contained';
    }

    return 'outlined';
  };

  return (
    <Stack direction={'row'} spacing={2} flexWrap="wrap" useFlexGap>
      <Button variant={setVariant('en')} color="primary" onClick={() => switchLang('en')}>
        English
      </Button>
      <Button variant={setVariant('sv')} color="primary" onClick={() => switchLang('sv')}>
        Svenska
      </Button>
      <Button variant={setVariant('de')} color="primary" onClick={() => switchLang('de')}>
        Deutsch
      </Button>
      <Button variant={setVariant('fr')} color="primary" onClick={() => switchLang('fr')}>
        Français
      </Button>
      <Button variant={setVariant('es')} color="primary" onClick={() => switchLang('es')}>
        Español
      </Button>
      <Button variant={setVariant('it')} color="primary" onClick={() => switchLang('it')}>
        Italiano
      </Button>
      <Button variant={setVariant('pl')} color="primary" onClick={() => switchLang('pl')}>
        Polski
      </Button>
    </Stack>
  );
};

export default LanguageSetting;
