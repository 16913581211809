import ErrorIcon from '@mui/icons-material/Error';
import HomeIcon from '@mui/icons-material/Home';
import { Typography, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';
import { useContext } from 'react';
import { AppContext } from 'src/contexts/AppContext';

const ErrorView = ({ error, onTryAgain }: { error: string; onTryAgain?: () => void }) => {
  const { t } = useTranslation();
  const { pageHeight } = useContext(AppContext);
  return (
    <Stack
      height={`${pageHeight}px`}
      justifyContent="center"
      alignItems="center"
      direction="column"
      padding={3}
    >
      <ErrorIcon sx={{ fontSize: '6em', color: 'grey.700' }} />
      <Typography variant="h5" component="h1" paragraph>
        {t('Something went wrong!')}
      </Typography>
      <Typography variant="body1" textAlign="center" paragraph>
        {error}
      </Typography>
      {onTryAgain && (
        <Button
          size="large"
          variant="contained"
          color="primary"
          startIcon={<ReplayIcon />}
          sx={{ minWidth: '15em' }}
          onClick={onTryAgain}
        >
          {t('Update Form')}
        </Button>
      )}
      <Button
        size="large"
        variant="contained"
        color={onTryAgain ? 'secondary' : 'primary'}
        component={Link}
        to="/"
        startIcon={<HomeIcon />}
        sx={{ minWidth: '15em', marginBottom: '1em', marginTop: '1em' }}
      >
        {t('Go back home')}
      </Button>
    </Stack>
  );
};
export default ErrorView;
