import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AppDescription = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="subtitle1" flex={1} p={2} px={3}>
      {t('app_description')}
    </Typography>
  );
};

export default AppDescription;
