import { InputLabel, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useContext, useEffect, useState } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { format, isEqual, subYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import TimeInput from '@/components/utils/TimeInput';
import { InstanceContext } from 'src/contexts/InstanceContext';

const DateForm = ({
  validateForm = true,
  useInstance = false,
}: {
  validateForm?: boolean;
  useInstance?: boolean;
}) => {
  const formHandler = useContext(ActivityFormContext);

  const { activity, setActivity } = useContext(ActivityContext);
  const { instance, setInstance } = useContext(InstanceContext);

  const useDate = useInstance ? instance.startDate : activity.startDate;
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    setDate(isEqual(new Date(0), useDate) ? null : useDate);
  }, [useDate]);

  const { t } = useTranslation();

  const [dateError, setDateError] = useState<boolean | string>(false);
  const [timeError, setTimeError] = useState<boolean | string>(false);

  const minDate = subYears(new Date(), 3);

  useEffect(() => {
    if (date !== null && !dateError && !timeError) {
      const recurrence = activity.recurringActivity;
      if (recurrence && recurrence.recurringTimes.length) {
        recurrence.recurringTimes[0] = format(date, 'HH:mm');
      }
      setActivity({ ...activity, startDate: date, recurringActivity: recurrence });
      validateForm && formHandler.setValid(true);
    } else if (formHandler.current.isValid) formHandler.setValid(false);
  }, [date, dateError, timeError]);

  return (
    <>
      <InputLabel htmlFor="activityDate">{t('Which date')}</InputLabel>
      <DatePicker
        value={date}
        showDaysOutsideCurrentMonth={true}
        onChange={(newDate) => setDate(newDate)}
        onError={(err) => {
          setDateError(err ?? false);
        }}
        minDate={minDate}
      ></DatePicker>
      <FormHelperText error={!!dateError}>{dateError}</FormHelperText>
      <InputLabel htmlFor="activityTime" sx={{ pt: 4 }}>
        {t('What time')}
      </InputLabel>
      <TimeInput value={date} onChange={(newDate) => setDate(newDate)} />
      <FormHelperText error={!!timeError}>{timeError}</FormHelperText>
    </>
  );
};

export default DateForm;
