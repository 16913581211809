import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import SubtaskItem, { SubtaskItemProps } from './SubtaskItem';

//Wrapper for NoteItem that makes it sortable and draggable
const SortableSubtaskItem = (props: SubtaskItemProps) => {
  const { isDragging, setNodeRef, transform, transition } = useSortable({
    id: props.item.subtaskId,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return <SubtaskItem ref={setNodeRef} style={style} invisible={isDragging} {...props} />;
};

export default SortableSubtaskItem;
