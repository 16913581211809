import ScrollPicker from './ScrollPicker';
import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
const ScrollTimePicker = ({
  value,
  onChange,
}: {
  value: Date | null;
  onChange?: (newDate: Date) => void;
}) => {
  // We use a local reference to avid race conditions if both hour and minute are changed at the same time
  const localValue = useRef<Date | null>(value);
  const [hoursArray] = useState<Array<string>>(
    Array.from({ length: 24 }, (_, index) => index.toString().padStart(2, '0')),
  );
  const [minutesArray] = useState<Array<string>>(
    Array.from({ length: 60 }, (_, index) => index.toString().padStart(2, '0')),
  );
  const setMinutes = (minutes: string | number) => {
    const newDate = localValue.current ?? new Date(format(new Date(), 'yyyy-MM-dd'));
    const newMinute = typeof minutes === 'string' ? parseInt(minutes) : minutes;
    newDate.setMinutes(newMinute);
    if (localValue.current == null) localValue.current = newDate;
    if (onChange) onChange(newDate);
  };
  const setHours = (hours: string | number) => {
    const newDate = localValue.current ?? new Date(format(new Date(), 'yyyy-MM-dd'));
    const newHours = typeof hours === 'string' ? parseInt(hours) : hours;
    newDate.setHours(newHours);
    if (localValue.current == null) localValue.current = newDate;
    if (onChange) onChange(newDate);
  };

  useEffect(() => {
    if (value !== null) localValue.current = value;
  }, [value]);

  const dateValue = (date: Date | null, formatString: string) => {
    if (date && !isNaN(date.getTime())) return format(date, formatString);

    return '00';
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <ScrollPicker
        value={dateValue(localValue.current, 'HH')}
        items={hoursArray}
        onChange={setHours}
        sx={{ px: 0.25, fontSize: '1.25em' }}
      />
      <Typography sx={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.5)', fontSize: '1.25em' }}>
        :
      </Typography>
      <ScrollPicker
        value={dateValue(localValue.current, 'mm')}
        items={minutesArray}
        onChange={setMinutes}
        sx={{ px: 0.25, fontSize: '1.25em' }}
      />
    </Stack>
  );
};

export default ScrollTimePicker;
