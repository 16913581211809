import { set } from 'date-fns';
import { createContext, useEffect, useState, useRef } from 'react';

type props = {
  interval?: number;
  children?: React.ReactNode;
};

export type CalendarDayContextType = {
  renderTime: Date;
};

export const TimerManagerContext = createContext<CalendarDayContextType>({
  renderTime: new Date(),
} as CalendarDayContextType);

export const TimerManagerProvider = ({ children, interval = 1 }: props) => {
  const [renderTime, setRenderTime] = useState(new Date());

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Handle page visibility change
  const handleVisibilityChange = () => {
    if (document.hidden) {
      // Page is hidden, stop the interval
      stopInterval();
    } else {
      // Page is visible, restart the interval and update the time
      const newTime = new Date();
      setRenderTime(newTime);
      startInterval();
    }
  };

  const startInterval = () => {
    const remainingTimeToNextMinute = 60 - renderTime.getSeconds();
    // Set the initial delay to synchronize with the next minute
    setTimeout(() => {
      const newTime = new Date();
      setRenderTime(newTime);
      intervalRef.current = setInterval(() => {
        const updatedTime = new Date();
        setRenderTime(updatedTime);
      }, interval * 60 * 1000);
    }, remainingTimeToNextMinute * 1000);
  };

  const stopInterval = () => {
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    startInterval();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      stopInterval();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const contextValue = {
    renderTime: renderTime,
  };

  return (
    <TimerManagerContext.Provider value={contextValue}>{children}</TimerManagerContext.Provider>
  );
};
