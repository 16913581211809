import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export type BasicDialogContent = {
  title: string;
  body: string;
  confirmText: string;
  cancelText: string;
  confirmButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | undefined;
};

type BasicDialogProps = {
  open: boolean;
  content: BasicDialogContent;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose: () => void;
};

const BasicDialog = ({ open, content, onConfirm, onCancel, onClose }: BasicDialogProps) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };

  const handleCancel = () => {
    onCancel?.();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(content.title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t(content.body)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={handleCancel}>
          {t(content.cancelText)}
        </Button>
        <Button
          variant={'contained'}
          onClick={handleConfirm}
          autoFocus
          disableFocusRipple
          color={content.confirmButtonColor ?? 'primary'}
        >
          {t(content.confirmText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BasicDialog;
