import { Box, Paper } from '@mui/material';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import Navbar from './Navbar';
import { AppContext } from 'src/contexts/AppContext';

type LayoutProps = {
  children?: ReactNode;
};

const widthBreakpoint = 1025;

const Layout = ({ children }: LayoutProps) => {
  const { setPageHeight, navbarRef, showNavbar } = useContext(AppContext);
  const [navbarHeight, setNavbarHeight] = useState<number>(0);
  const pageRef = useRef<HTMLDivElement>(null);

  const setNewPageHeight = () => {
    const newHeight = pageRef?.current?.clientHeight;
    if (newHeight) {
      setPageHeight(newHeight);
    }
  };

  useEffect(() => {
    setNewPageHeight();
    window.addEventListener('resize', setNewPageHeight);
    return () => {
      window.removeEventListener('resize', setNewPageHeight);
    };
  }, [pageRef]);

  useEffect(() => {
    const newHeight = navbarRef.current?.clientHeight ?? 0;
    setNavbarHeight(showNavbar ? newHeight : 0);
  }, [navbarRef.current, showNavbar]);

  const userAgent = navigator.userAgent;
  const isMobile = !!userAgent.match(/mobile/i);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          background: (theme) => theme.palette.backdrop,
        }}
      >
        <Paper
          elevation={4}
          sx={(theme) => ({
            ...(isMobile
              ? null
              : {
                  [theme.breakpoints.up(widthBreakpoint)]: {
                    maxWidth: `${widthBreakpoint}px`,
                    maxHeight: '95vh',
                  },
                }),
            height: '100%',
            width: '100%',
            paddingBottom: `${navbarHeight}px`,
            position: 'relative',
          })}
          ref={pageRef}
        >
          <main
            style={{
              width: '100%',
              height: '100%',
              overflow: 'auto',
            }}
          >
            {children}
          </main>
          <Navbar />
        </Paper>
      </Box>
    </>
  );
};

export default Layout;
