import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import appleLogoWhite from '@/assets/apple-icon-white.svg';
import appleLogoBlack from '@/assets/apple-icon-black.svg';
import { BASE_URL } from '@api';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const AppleLoginButton = () => {
  const { t } = useTranslation();
  const isDarkTheme = useTheme().palette.mode === 'dark';
  return (
    <>
      <Button
        size="large"
        variant="contained"
        color={isDarkTheme ? 'googleWhite' : 'appleBlack'}
        sx={{
          justifyContent: 'left',
          width: '100%',
          color: 'white',
        }}
        href={`${BASE_URL}/login/apple`}
      >
        <img
          src={isDarkTheme ? appleLogoBlack : appleLogoWhite}
          alt="Apple-icon"
          style={{ height: '1.5em', width: '1.5em' }}
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          {t('Sign in with Apple')}
        </Box>
      </Button>
    </>
  );
};

export default AppleLoginButton;
