import { ReactNode, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import LoadingPage from './LoadingPage';
import { SupervisorScene } from 'src/contexts/SupervisorContext';
import AuthRouter from './AuthRouter';
import StartRouter from './StartRouter';

const AuthGuard = () => {
  const { authenticated } = useContext(AppContext);
  if (authenticated === 'authenticated')
    return (
      <SupervisorScene>
        <AuthRouter />
      </SupervisorScene>
    );
  else if (authenticated === 'loading') return <LoadingPage minHeight="100vh" />;
  else return <StartRouter />;
};

export default AuthGuard;
