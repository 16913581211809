import { Note } from '@api';
import { Dialog } from '@mui/material';
import { useMemo, useState } from 'react';
import ViewNote from './ViewNote';
import NoteForm from './NoteForm';

type NoteDialogProps = {
  open: boolean;
  onClose: () => void;
  note?: Note;
  noteBoardId: number;
  notes: Note[];
};

const NoteDialog = ({ open, onClose, note, noteBoardId, notes }: NoteDialogProps) => {
  const [editNote, setEditNote] = useState<boolean>(false);
  const viewNote = useMemo(() => {
    return note && !editNote;
  }, [note, editNote]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="board-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'md'}
    >
      {viewNote ? (
        <ViewNote onEdit={() => setEditNote(true)} note={note ?? ({} as Note)} onClose={onClose} />
      ) : (
        <NoteForm note={note} onClose={onClose} noteBoardId={noteBoardId} notes={notes} />
      )}
    </Dialog>
  );
};

export default NoteDialog;
