import { Paper, Box, Stack, Typography, useTheme } from '@mui/material';
import { addMinutes, format, isDate } from 'date-fns';
import { ActivityInstance } from '@api';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import MinimizeRoundedIcon from '@mui/icons-material/MinimizeRounded';
import Timer from '../utils/Timer';
import { getActivityColor } from '../utils/CalendarFns';

type ActivityCardProps = {
  activityInstance: ActivityInstance;
  type: 'missed' | 'upcoming' | 'ongoing';
  noElevation?: boolean;
  transparent?: boolean;
};

//toDo refactor html section
const ActivityCard = ({
  activityInstance: instance,
  type,
  noElevation,
  transparent,
}: ActivityCardProps) => {
  const endDate = addMinutes(new Date(instance.startDate), instance.duration);
  const { palette } = useTheme();
  const style = {
    paper: {
      borderRadius: 3,
      ...(transparent ? { backgroundColor: palette.lineLight } : {}),
      ...(type === 'missed' ? { paddingY: 2 } : {}),
    },
  };

  return (
    <>
      {type === 'missed' && (
        <Paper sx={style.paper} variant="outlined">
          <Stack direction={'row'} alignItems={'center'} width={'100%'}>
            <ErrorRoundedIcon
              fontSize={'large'}
              sx={{ m: 1, color: palette.mode == 'dark' ? palette.lineDark : palette.line }}
            ></ErrorRoundedIcon>
            <Stack flexGrow={1} width={'1em'}>
              <Typography
                variant={'subtitle2'}
                textAlign={'start'}
                sx={{ overflowWrap: 'break-word' }}
              >
                {instance.activityName}
              </Typography>
              <Typography variant={'subtitle2'} textAlign={'start'}>
                {isDate(instance.startDate) && `${format(instance.startDate, "HH':'mm")}`}
                {instance.duration > 0 && `-${format(endDate, "HH':'mm")}`}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      )}
      {type === 'upcoming' && (
        <Paper elevation={noElevation ? 0 : 1} sx={style.paper}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              position: 'relative',
              py: 0.5,
            }}
          >
            <Box
              sx={{
                m: 0.5,
                p: 0.5,
                backgroundColor: getActivityColor(palette, instance.duration),
                borderRadius: '1em',
                position: 'absolute',
                height: '90%',
              }}
            ></Box>
            <Stack sx={{ py: 2, pl: 4, pr: 1, maxWidth: '100%' }}>
              <Typography
                variant={'h6'}
                fontWeight={'bold'}
                textAlign={'start'}
                sx={{ overflowWrap: 'break-word' }}
              >
                {instance.activityName}
              </Typography>
              <Typography textAlign={'start'}>
                {isDate(instance.startDate) && `${format(instance.startDate, "HH':'mm")}`}
                {instance.duration > 0 && `-${format(endDate, "HH':'mm")}`}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      )}
      {type === 'ongoing' && (
        <Paper elevation={noElevation ? 0 : 5} sx={style.paper}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              position: 'relative',
              py: 0.5,
            }}
          >
            <Box
              sx={{
                m: 0.5,
                p: 0.5,
                backgroundColor: getActivityColor(palette, instance.duration),
                borderRadius: '1em',
                position: 'absolute',
                height: '45%',
              }}
            ></Box>
            <Stack sx={{ py: 3, pl: 4, pr: 1, maxWidth: '100%' }}>
              <Typography
                variant={'h5'}
                fontWeight={'bold'}
                textAlign={'start'}
                sx={{ overflowWrap: 'break-word' }}
              >
                {instance.activityName}
              </Typography>
              <Typography variant={'subtitle2'} textAlign={'start'}>
                {isDate(instance.startDate) && `${format(instance.startDate, "HH':'mm")}`}
                {instance.duration > 0 && `-${format(endDate, "HH':'mm")}`}
              </Typography>
              <Box sx={{ pt: 1, width: '6em' }}>
                {instance.duration && (
                  <Timer
                    minutes={instance.duration}
                    isRunning={false}
                    displayMode="minutes"
                    color={getActivityColor(palette, instance.duration)}
                  ></Timer>
                )}
              </Box>
            </Stack>
          </Stack>
        </Paper>
      )}
    </>
  );
};

export default ActivityCard;
