import { useContext, useMemo } from 'react';
import { CalendarDayContext } from '@/contexts/CalendarDayContext';
import { isDate } from 'date-fns';
import { Stack, Typography, Box } from '@mui/material';
import ActivityCard from '@/components/activity/ActivityCard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OngoingActivities = () => {
  const { instances } = useContext(CalendarDayContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ongoingInstances = useMemo(
    () =>
      instances.filter(
        (instance) => isDate(instance.dateIsStarted) && !isDate(instance.dateIsCompleted),
      ),
    [instances],
  );

  const viewActivity = (activityInstanceId: number) => () => {
    navigate(`/activity/${activityInstanceId}`);
  };

  return (
    <Stack spacing={2}>
      {ongoingInstances.length > 0 && (
        <>
          <Typography variant="h6">{t('Ongoing Activities')}</Typography>
          {ongoingInstances.map((instance, index) => (
            <Box onClick={viewActivity(instance.activityInstanceId)} key={index} display={'block'}>
              <ActivityCard key={index} activityInstance={instance} type={'ongoing'}></ActivityCard>
            </Box>
          ))}
        </>
      )}
    </Stack>
  );
};

export default OngoingActivities;
