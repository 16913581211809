import * as React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { useContext, useRef, useEffect, useState } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import ActivityImage from './ActivityImage';
import { format } from 'date-fns';
import { InstanceContext } from 'src/contexts/InstanceContext';

const ActivityName = () => {
  const { activity } = useContext(ActivityContext);
  const { instance } = useContext(InstanceContext);
  const [cardHeight, setCardHeight] = useState<number | undefined>(undefined);

  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.clientHeight);
    }
  }, []);

  return (
    <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
      <Stack direction="column" justifyContent="center" width="10px" flexGrow={1} ref={cardRef}>
        <Typography variant="h5" sx={{ pt: 2, overflowWrap: 'break-word' }}>
          {instance.activityName}
        </Typography>
        <Typography variant="body2" sx={{ pb: 2 }}>
          {format(instance.startDate, 'HH:mm')}-
          {format(new Date(instance.startDate.getTime() + instance.duration * 60000), 'HH:mm')}
        </Typography>
      </Stack>
      {activity.picture && (
        <Box width={cardHeight}>
          {cardHeight && activity.picture && (
            <ActivityImage height={`${cardHeight}px`} switchImages></ActivityImage>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default ActivityName;
