import { Stack, Typography, Box, IconButton, Button, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dateLocales } from '@/i18n/dateLocales';
import { CalendarContext } from '@/contexts/CalendarContext';
import { useState, useContext, useEffect } from 'react';
import { getWeekDates } from '@/components/utils/DateUtils';
import ColorUnderline from '../ColorUnderline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { getISOWeek, getISOWeekYear } from 'date-fns';
import WeekSelectDialog from './dialogs/WeekSelectDialog';

type WeekDates = {
  startDate: Date;
  endDate: Date;
};

const addDays = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

//A component that renders each day
const WeekSelect = () => {
  const { t, i18n } = useTranslation();
  const { selectedWeek, setSelectedWeek, goToCurrentWeek } = useContext(CalendarContext);
  const lang = i18n.language.split('-')[0];

  const [dialogOpen, setDialogOpen] = useState(false);

  const [weekDates, setWeekDates] = useState<WeekDates>(getWeekDates(selectedWeek));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setWeekDates(getWeekDates(selectedWeek));
  }, [selectedWeek]);

  const onPreviousWeek = () => {
    const newMonday = addDays(weekDates.startDate, -7);
    const newWeek = {
      week: getISOWeek(newMonday),
      year: getISOWeekYear(newMonday),
    };
    setSelectedWeek(newWeek);
  };

  const onNextWeek = () => {
    const newMonday = addDays(weekDates.startDate, 7);
    const newWeek = {
      week: getISOWeek(newMonday),
      year: getISOWeekYear(newMonday),
    };
    setSelectedWeek(newWeek);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const onDialogClose = () => {
    setDialogOpen(false);
  };

  //Set selectedWeek to the current week

  const today = new Date();

  return (
    <>
      <WeekSelectDialog onClose={onDialogClose} open={dialogOpen} />
      <Stack justifyContent={'start'}>
        <Typography
          variant="subtitle2"
          color={'primary'}
          textAlign={'end'}
          sx={{ textTransform: 'capitalize', mr: 3, lineHeight: 1, fontWeight: 'bold' }}
        >
          {format(weekDates.endDate, `yyyy`, { locale: dateLocales[lang] })}
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign={'end'}
          sx={{ textTransform: 'capitalize', mr: 3, lineHeight: 1.25 }}
        >
          {weekDates.startDate.getMonth() === weekDates.endDate.getMonth() ? (
            <>{format(weekDates.startDate, `MMMM`, { locale: dateLocales[lang] })}</>
          ) : (
            <>
              {format(weekDates.startDate, `MMMM`, { locale: dateLocales[lang] })} {' / '}{' '}
              {format(weekDates.endDate, `MMMM`, { locale: dateLocales[lang] })}
            </>
          )}
        </Typography>
        <Stack flexDirection="row" justifyContent="center" alignItems="center">
          <IconButton
            color="primary"
            onClick={onPreviousWeek}
            disabled={selectedWeek.year === 2000 && selectedWeek.week === 1}
            aria-label={t('Previous week') as string}
          >
            <NavigateBeforeIcon fontSize={isSmallScreen ? 'medium' : 'large'} />
          </IconButton>
          <Box sx={{ position: 'relative' }} role="button" onClick={openDialog}>
            <Typography
              variant={isSmallScreen ? 'h6' : 'h5'}
              fontWeight={'bold'}
              textAlign={'end'}
              sx={{ textTransform: 'capitalize' }}
            >
              {t('Week') + ' ' + selectedWeek.week.toString().padStart(2, '0')}
            </Typography>
            <ColorUnderline day={format(today, 'EEEE')} />
          </Box>
          <IconButton
            color="primary"
            onClick={onNextWeek}
            disabled={selectedWeek.year === 2099 && selectedWeek.week === 52}
            aria-label={t('Next week') as string}
          >
            <NavigateNextIcon fontSize={isSmallScreen ? 'medium' : 'large'} />
          </IconButton>
        </Stack>
        <Box sx={{ height: '2em', display: 'flex', justifyContent: 'center' }}>
          {(selectedWeek.week !== getISOWeek(today) ||
            selectedWeek.year !== getISOWeekYear(today)) && (
            <Button variant="text" sx={{ textAlign: 'center' }} onClick={goToCurrentWeek}>
              {t('To current week')}
            </Button>
          )}
        </Box>
      </Stack>
    </>
  );
};

export default WeekSelect;
