import { Typography, IconButton, Theme, Paper, Box, Stack } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useMemo, useState, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CurrentBoardIndicator from './CurrentBoardIndicator';
import { NotesContext } from 'src/contexts/NotesContext';
import { forwardRef } from 'react';

type NotesHeaderProps = {
  swiperRef: any;
};

const NotesHeader = forwardRef<HTMLDivElement, NotesHeaderProps>(({ swiperRef }, ref) => {
  const { t } = useTranslation();

  const { boards, loaded } = useContext(NotesContext);

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(() => {
    swiperRef.current && swiperRef.current.swiper.slidePrev();
  }, [swiperRef]);

  const scrollNext = useCallback(() => {
    swiperRef.current && swiperRef.current.swiper.slideNext();
  }, [swiperRef]);

  const scrollTo = useCallback(
    (index: number) => {
      swiperRef.current && swiperRef.current.swiper.slideTo(index);
    },
    [swiperRef],
  );

  const onSelect = useCallback(() => {
    if (!swiperRef.current) return;
    setSelectedIndex(swiperRef.current.swiper.activeIndex);
    if (swiperRef.current.swiper.snapGrid !== scrollSnaps)
      setScrollSnaps(swiperRef.current.swiper.snapGrid);
    // Swiper has properties to determine if it's at the beginning or end
    setPrevBtnDisabled(swiperRef.current.swiper.isBeginning);
    setNextBtnDisabled(swiperRef.current.swiper.isEnd);
  }, [swiperRef]);

  useEffect(() => {
    if (!swiperRef.current.swiper) return;

    // Add Swiper event listeners here
    // Swiper has its own set of events and you might need to listen for those
    const swiper = swiperRef.current.swiper;
    swiper.on('update', onSelect);
    swiper.on('slideChange', onSelect);
    return () => {
      swiper.off('slideChange', onSelect);
      swiper.off('update', onSelect);
    };
  }, [swiperRef, onSelect]);

  const lineHeight = 1.6; // Line height
  const fontSize = 1.25 * 16; // Font size in pixels (1rem = 16px)
  const lines = 3; // Number of lines

  const containerHeight = `${lineHeight * fontSize * lines}px`;

  const styles = useMemo(() => {
    return {
      container: {
        background: boards[selectedIndex]
          ? boards[selectedIndex].color
          : (theme: Theme) => theme.palette.white,
        color: (theme: Theme) =>
          boards[selectedIndex]
            ? theme.palette.mode === 'dark'
              ? theme.palette.white
              : theme.palette.black
            : theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        height: containerHeight,
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },
      previousButton: {
        color: (theme: Theme) =>
          boards[selectedIndex]
            ? theme.palette.mode === 'dark'
              ? theme.palette.white
              : theme.palette.black
            : theme.palette.primary.main,
      },
      nextButton: {
        color: (theme: Theme) =>
          boards[selectedIndex]
            ? theme.palette.mode === 'dark'
              ? theme.palette.white
              : theme.palette.black
            : theme.palette.primary.main,
      },
      title: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        wordBreak: 'break-word',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        flexGrow: 1,
      },
      titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      },
    };
  }, [boards, selectedIndex]);

  return (
    <Paper sx={styles.container} ref={ref}>
      <Stack direction={'column'} alignSelf={'center'}>
        <IconButton
          onClick={scrollPrev}
          aria-label={t('Previous board') as string}
          sx={styles.previousButton}
          disabled={prevBtnDisabled}
        >
          <ChevronLeftIcon sx={{ fontSize: '2em' }} />
        </IconButton>
      </Stack>
      {loaded ? (
        <Box sx={styles.titleContainer}>
          <Stack flex={1} justifyContent={'center'}>
            <Box>
              <Typography variant="h6" px={1} sx={styles.title}>
                {boards[selectedIndex]
                  ? boards[selectedIndex].title
                  : boards.length
                  ? t('Manage boards')
                  : t('Add Board')}
              </Typography>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="center" alignItems={'center'}>
            {scrollSnaps.map((snap, index) => (
              <CurrentBoardIndicator
                hideAddButton={!boards.length}
                key={index}
                isSelected={index === selectedIndex}
                lastItemSelected={nextBtnDisabled}
                addButton={index === scrollSnaps.length - 1}
                onClick={() => scrollTo(index)}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <></>
      )}

      <Stack direction={'column'} alignSelf={'center'}>
        <IconButton
          onClick={scrollNext}
          aria-label={t('Next board') as string}
          sx={styles.nextButton}
          disabled={nextBtnDisabled}
        >
          <ChevronRightIcon sx={{ fontSize: '2em' }} />
        </IconButton>{' '}
      </Stack>
    </Paper>
  );
});

export default NotesHeader;
