import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ActivityContext } from '@/contexts/ActivityContext';

const ClearRecurrence = () => {
  const { t } = useTranslation();

  const { activity, setActivity } = useContext(ActivityContext);

  const [open, setOpen] = useState(false);

  const clearRecurrence = () => {
    setActivity({
      ...activity,
      recurringActivity: null,
    });
    setOpen(false);
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="large"
        color="error"
        variant="outlined"
        sx={{ mt: 4 }}
        startIcon={<DeleteIcon />}
        onClick={() => openDialog()}
      >
        {t('Clear recurrence')}
      </Button>
      <Dialog
        open={open}
        onClose={() => closeDialog()}
        aria-labelledby={t('Alert Dialog Title') as string}
        aria-describedby={t('Are you sure you want to clear the recurrence?') as string}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Are you sure you want to clear the recurrence?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeDialog()}>{t('Cancel')}</Button>
          <Button onClick={() => clearRecurrence()} autoFocus>
            {t('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClearRecurrence;
