/* tslint:disable */
/* eslint-disable */

import * as runtime from '../runtime';
import { BaseAPI } from '../baseApi';
import { Activity, ActivityInstance } from '../types';
import { HttpStatusCode } from 'axios';
import { ResponseError } from '../runtime';
import { compressImageToSize } from './CompressImage';

export interface CreateActivityRequest {
  activity: Activity;
  supervisedTag?: string;
}

export interface CompleteActivityRequest {
  activityInstanceId: number;
  supervisedTag?: string;
}

export interface EditActivityRequest {
  activity: Activity;
  supervisedTag?: string;
}

export interface CreateActivityOffspringRequest {
  activityInstance: ActivityInstance;
  activity: Activity;
  supervisedTag?: string;
}

export interface GetActivityInstancesRequest {
  startDate: Date;
  endDate: Date;
  supervisedTag?: string;
}

export interface GetActivityRequest {
  activityId: number;
  supervisedTag?: string;
}
export interface GetActivityInstanceRequest {
  activityInstanceId: number;
  supervisedTag?: string;
}

export interface RemoveActivityRequest {
  activityId: number;
  supervisedTag?: string;
}

export interface RemoveActivityInstanceRequest {
  activityInstanceId: number;
  supervisedTag?: string;
}

export interface StartActivityRequest {
  activityInstanceId: number;
  supervisedTag?: string;
}

export interface SetFeelingActivityRequest {
  activityInstanceId: number;
  feelingOfActivity: number;
  supervisedTag?: string;
}

export interface SetSubtaskItemRequest {
  activityInstanceId: number;
  subtaskId: number;
  supervisedTag?: string;
}

export interface UploadImageRequest {
  image: File;
  url: string;
  supervisedTag?: string;
}

export class ActivityApi extends BaseAPI {
  async createActivity(
    requestParameters: CreateActivityRequest,
  ): Promise<HttpStatusCode | ResponseError | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activities`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: runtime.toRequestBody(requestParameters.activity),
      });

      if (response.data) {
        await this.handleImageUpload(response.data, requestParameters.activity);
      }

      return response.status;
    } catch (error: unknown) {
      if (error instanceof ResponseError) return error;
    }
  }

  async completeActivity(
    requestParameters: CompleteActivityRequest,
  ): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activity-instances/${requestParameters.activityInstanceId}/complete`,
        method: 'PUT',
        query: queryParameters,
      });

      return response.status;
    } catch (error) {}
  }

  async editActivity(
    requestParameters: EditActivityRequest,
  ): Promise<HttpStatusCode | ResponseError | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activities`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: runtime.toRequestBody(requestParameters.activity),
      });

      if (response.data) {
        await this.handleImageUpload(response.data, requestParameters.activity);
      }

      return response.status;
    } catch (error) {
      if (error instanceof ResponseError) return error;
    }
  }

  async createActivityOffspring(
    requestParameters: CreateActivityOffspringRequest,
  ): Promise<HttpStatusCode | ResponseError | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }
    if (requestParameters.activityInstance.activityInstanceId !== undefined) {
      queryParameters['paramActivityInstanceId'] =
        requestParameters.activityInstance.activityInstanceId;
    }

    try {
      const response = await this.request({
        path: `/activity-instances`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: runtime.toRequestBody(requestParameters.activity),
      });

      if (response.data) {
        await this.handleImageUpload(response.data, requestParameters.activity);
      }

      return response.status;
    } catch (error) {
      if (error instanceof ResponseError) return error;
    }
  }

  async getActivityInstances(
    requestParameters: GetActivityInstancesRequest,
  ): Promise<Array<ActivityInstance> | undefined> {
    const queryParameters: any = {};

    if (requestParameters.startDate !== undefined) {
      queryParameters['intervalStartDate'] = (requestParameters.startDate as any)
        .toISOString()
        .slice(0, 10);
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters['intervalEndDate'] = (requestParameters.endDate as any)
        .toISOString()
        .slice(0, 10);
    }

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activity-instances`,
        method: 'GET',
        query: queryParameters,
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Array<ActivityInstance> => jsonValue,
      ).value();
    } catch (error) {}
  }

  async getActivity(requestParameters: GetActivityRequest): Promise<Activity | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activities/${requestParameters.activityId}`,
        method: 'GET',
        query: queryParameters,
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): Activity => jsonValue,
      ).value();
    } catch (error) {}
  }

  async getActivityInstance(
    requestParameters: GetActivityInstanceRequest,
  ): Promise<ActivityInstance | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activity-instances/${requestParameters.activityInstanceId}`,
        method: 'GET',
        query: queryParameters,
      });

      return await new runtime.JSONApiResponse(
        response,
        (jsonValue): ActivityInstance => jsonValue,
      ).value();
    } catch (error) {}
  }

  async removeActivity(
    requestParameters: RemoveActivityRequest,
  ): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activities/${requestParameters.activityId}`,
        method: 'DELETE',
        query: queryParameters,
      });

      return response.status;
    } catch (error) {}
  }

  async removeActivityInstance(
    requestParameters: RemoveActivityInstanceRequest,
  ): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activity-instances/${requestParameters.activityInstanceId}`,
        method: 'DELETE',
        query: queryParameters,
      });

      return response.status;
    } catch (error) {}
  }

  async startActivity(
    requestParameters: StartActivityRequest,
  ): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activity-instances/${requestParameters.activityInstanceId}/start`,
        method: 'PUT',
        query: queryParameters,
      });

      return response.status;
    } catch (error) {}
  }

  async setActivityFeeling(
    requestParameters: SetFeelingActivityRequest,
  ): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activity-instances/${requestParameters.activityInstanceId}/set-feeling`,
        method: 'PUT',
        query: queryParameters,
        body: runtime.toRequestBody({ feelingOfActivity: requestParameters.feelingOfActivity }),
      });

      return response.status;
    } catch (error) {}
  }

  async setSubTask(requestParameters: SetSubtaskItemRequest): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `/activity-instances/${requestParameters.activityInstanceId}/set-subtask`,
        method: 'PUT',
        query: queryParameters,
        body: runtime.toRequestBody({ subtaskId: requestParameters.subtaskId }),
      });

      return response.status;
    } catch (error) {}
  }

  async handleImageUpload(data: JSON, activity: Activity): Promise<void> {
    Object.entries(data).map(([key, value]) => {
      const image = runtime.getImageFile(activity, key);
      if (image) {
        this.uploadImageRequest({
          image: image,
          url: String(value),
        });
      }
    });
  }

  async uploadImageRequest(
    requestParameters: UploadImageRequest,
  ): Promise<HttpStatusCode | undefined> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    // Initialize quality and blob
    const maxFileSize = 3 * 1024 * 1024;

    const blob = await compressImageToSize(requestParameters.image, maxFileSize);

    if (blob === null) return undefined;

    // headerParameters['content-type'] = compressedFile.type;
    headerParameters['content-type'] = blob.type;

    if (requestParameters.supervisedTag !== undefined) {
      queryParameters['supervisedTag'] = requestParameters.supervisedTag;
    }

    try {
      const response = await this.request({
        path: `${requestParameters.url}`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: blob,
      });

      return response.status;
    } catch (error) {
      return undefined;
    }
    return undefined;
  }
}
