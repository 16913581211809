import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { StatisticsContext } from '@/contexts/StatisticsContext';

import { Paper, Typography, Grid } from '@mui/material';

const colors = ['#ececec', '#eb2c3a', '#ee8130', '#fce92b', '#91bd49', '#009f54'];
const months = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

const Item = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Dot = ({ color }: { color: number }) => {
  return (
    <div
      style={{
        margin: '2.4px',
        backgroundColor: colors[color],
        height: '15px',
        width: '15px',
        borderRadius: '50%',
      }}
    />
  );
};

const PixelView = () => {
  const { data } = useContext(StatisticsContext);
  const [pixelDots, setPixelDots] = useState<any>([]);

  useEffect(() => {
    const arr: any = [];
    if (data?.pixels) {
      Object.keys(data.pixels).forEach((key: any, index: number) => {
        Object.keys(data.pixels[key]).forEach((day: any) => {
          if (!arr[index]) arr[index] = [];
          arr[index].push(data.pixels[key][day]);
        });
      });
      setPixelDots(arr);
    } else {
      const arr = [...Array(12)].map(() => [...Array(31)].map(() => 0));
      setPixelDots(arr);
    }
  }, [data]);
  return (
    <Paper
      elevation={4}
      sx={() => ({
        width: '100%',
        height: '50%',
        padding: '16px',
        marginTop: '16px',
      })}
    >
      <Typography variant="subtitle1">Året i pixlar</Typography>
      <Paper
        elevation={0}
        sx={() => ({
          padding: '16px',
        })}
      >
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <Typography
              variant="caption"
              sx={() => ({
                color: 'white',
              })}
            >
              .
            </Typography>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {[...Array(31)].map((_, index) => (
                <Grid container justifyContent={'center'} key={`day-${index}`}>
                  <Typography variant="caption">{index + 1}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {pixelDots.length && (
            <Grid item xs={11}>
              <Grid container>
                {months.map((m, i) => (
                  <Grid item xs={1} key={`${i}-month`}>
                    <Item>{m}</Item>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      {pixelDots[i].map((day: any, j: number) => (
                        <Grid container justifyContent={'center'} key={`${i}dot${j}`}>
                          <Dot color={day} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Paper>
  );
};

export default PixelView;
