import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];

  const languageMap: { [key: string]: string } = {
    sv: 'Svenska',
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    es: 'Español',
    it: 'Italiano',
    pl: 'Polski',
  };

  const lamgText = languageMap[lang] || lang;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchLang = async (newLang: string) => {
    setAnchorEl(null);
    if (newLang !== lang) {
      localStorage.setItem('i18nextLng', newLang);
      window.location.reload();
    }
  };

  const flagStyle: React.CSSProperties = {
    width: '1.5em',
    height: '1.5em',
    borderRadius: '50%',
    aspectRatio: '1/1',
    objectFit: 'cover',
    marginRight: '0.5em',
  };

  return (
    <>
      <Button
        id="language-button"
        aria-controls={open ? 'lang-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src={`/lang/${lang}.svg`} style={flagStyle} alt="Swedish flag" />
        <Typography
          variant="body1"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: { display: 'none' },
          })}
        >
          {lamgText}
        </Typography>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
      >
        <MenuItem onClick={() => switchLang('sv')}>
          <img src="/lang/sv.svg" style={flagStyle} alt="Swedish flag" />
          Svenska
        </MenuItem>
        <MenuItem onClick={() => switchLang('en')}>
          <img src="/lang/en.svg" style={flagStyle} alt="English flag" />
          English
        </MenuItem>
        <MenuItem onClick={() => switchLang('de')}>
          <img src="/lang/de.svg" style={flagStyle} alt="German flag" />
          Deutsch
        </MenuItem>
        <MenuItem onClick={() => switchLang('fr')}>
          <img src="/lang/fr.svg" style={flagStyle} alt="French flag" />
          Français
        </MenuItem>
        <MenuItem onClick={() => switchLang('es')}>
          <img src="/lang/es.svg" style={flagStyle} alt="Spanish flag" />
          Español
        </MenuItem>
        <MenuItem onClick={() => switchLang('it')}>
          <img src="/lang/it.svg" style={flagStyle} alt="Italian flag" />
          Italiano
        </MenuItem>
        <MenuItem onClick={() => switchLang('pl')}>
          <img src="/lang/pl.svg" style={flagStyle} alt="Polish flag" />
          Polski
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSelect;
