import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { MutableRefObject, useState, useEffect, useContext } from 'react';
import { TimerManagerContext } from '@/contexts/TimerManagerContext';

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const TimeIndicator = ({
  calendarRef,
  day,
}: {
  calendarRef: MutableRefObject<HTMLDivElement | null>;
  day: Date | null;
}) => {
  const [columnWidth, setColumnWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const [distance, setDistance] = useState(0);
  const theme = useTheme();
  const [animationTimer, setAnimationTimer] = useState<number>(0);

  const { renderTime } = useContext(TimerManagerContext);
  const calculateDistance = (offset: number) => {
    if (calendarRef && calendarRef.current) {
      const height = calendarRef.current.getBoundingClientRect().height;
      const distance = height / 24;

      const hours = renderTime.getHours();
      const minutes = (renderTime.getMinutes() + offset) / 60;
      setDistance(distance * (hours + minutes));
    }
  };

  const caluclateLeft = () => {
    const emToPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
    //timelineWidth - 2em
    const timelineWidth = 2 * emToPx;

    if (day) {
      const identifierWidth = columnWidth;
      const calendarWidth = identifierWidth * 3;
      const middle = timelineWidth + calendarWidth / 2;
      const left = middle - identifierWidth / 2;
      return left;
    } else {
      const columnIndex = weekdays.indexOf(format(renderTime, 'EEEE'));

      const calendarOffset = columnWidth * columnIndex;

      const left = timelineWidth + calendarOffset;

      return left;
    }
  };

  // Get the column width
  useEffect(() => {
    if (calendarRef)
      if (calendarRef.current) {
        const calendarWidth = calendarRef.current.getBoundingClientRect().width;
        //Subtract 1.5em
        const emToPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
        //timelineWidth - 1.5em
        const timelineWidth = 2 * emToPx;

        if (day) {
          //Check if rendertime is current day
          const isToday = format(renderTime, 'dd-MM-yyyy') === format(day, 'dd-MM-yyyy');
          if (isToday) {
            const width = calendarWidth - timelineWidth;
            const indicatorWidth = width / 3;
            setColumnWidth(indicatorWidth);
          } else {
            const width = 0;
            setColumnWidth(width);
          }
        } else {
          const width = (calendarWidth - timelineWidth) / 7;

          setColumnWidth(width);
        }
      }
  }, [calendarRef, day]);

  useEffect(() => {
    setLeft(caluclateLeft());
  }, [columnWidth]);

  useEffect(() => {
    //Calculate initial distance
    calculateDistance(0);
  }, []);

  useEffect(() => {
    //Calculate distance every minute
    //Get seconds left. First time might be lower than 60 seconds
    const seconds = renderTime.getSeconds();
    const timeLeft = 60 - seconds;

    if (animationTimer !== timeLeft) setAnimationTimer(timeLeft);

    calculateDistance(1);
  }, [renderTime]);

  return (
    <>
      {distance && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: `${distance}px`,
              left: '1.25em',
              transform: 'translateY(-50%)',
              width: 'calc(100% - 1.25em)',
              height: `2px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.primary.main,
              margin: '0px !important',
              zIndex: 1,
              transition: `top ${animationTimer}s`,
            }}
          ></Box>
          {columnWidth > 0 && (
            <Box
              sx={{
                position: 'absolute',
                top: `${distance}px`,
                left: `${left}px`,
                transform: 'translateY(-50%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: columnWidth,
                backgroundColor: theme.palette.primary.main,
                borderRadius: '10px',
                color: theme.palette.primary.contrastText,
                margin: '0px !important',
                zIndex: 1,
                transition: `top ${animationTimer}s`,
                pointerEvents: 'none',
              }}
            >
              <Typography variant="caption">{format(renderTime, 'HH:mm')}</Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default TimeIndicator;
