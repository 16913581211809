import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Statistics = ({
  completedInstances,
  title,
  visible = true,
}: {
  completedInstances: number;
  title: string;
  visible?: boolean;
}) => {
  return (
    <Stack>
      {visible && (
        <>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="h6" fontWeight={'bold'} textAlign={'center'} p={2}>
            {completedInstances}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default Statistics;
