import { Stack, Typography, Button, Dialog } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FeelingOfActivity from './FeelingOfActivity';
import SuccessAnimation from '@/components/utils/SuccessAnimation';

type ActivityCompletedDialogProps = {
  open: boolean;
  onClose: () => void;
};

const ActivityCompletedDialog = ({ open, onClose }: ActivityCompletedDialogProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Dialog open={open} fullWidth>
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={2}
        padding={2}
      >
        <Typography variant="h5" fontWeight={'bold'}>
          {t('Activity Completed')}
        </Typography>
        <SuccessAnimation />
        <FeelingOfActivity />
        <Button onClick={() => navigate('/')} variant={'outlined'} sx={{ width: '100%', p: 1 }}>
          {t('To Start Page')}
        </Button>
        <Button onClick={onClose} variant={'contained'} sx={{ width: '100%', p: 1 }}>
          {t('Close')}
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ActivityCompletedDialog;
