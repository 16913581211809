import { Alert, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext, useState } from 'react';
import { ActivityFormContext, ActivityForms } from '@/contexts/ActivityFormContext';
import { validateName } from '@/components/utils/Validate';
import { useTranslation } from 'react-i18next';
import { ActivityContext } from 'src/contexts/ActivityContext';

const indicator = {
  borderRadius: '50%',
  width: '2em',
  textAlign: 'center',
  fontSize: '1.25rem',
  aspectRatio: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'text.primary',
  boxShadow: 'none',
  bgcolor: 'secondary.main',
  zIndex: 1,
  cursor: 'pointer',
};

const connector = {
  bgcolor: 'secondary.main',
  width: '2em',
  height: '0.5em',
};
const active = (primaryColor: string) => ({
  boxShadow: `0 0 0 2px white, 0 0 0 4px ${primaryColor}`,
});
const completedBg = {
  bgcolor: 'primary.main',
  color: 'white',
};

const ProgressIndicator = () => {
  const formHandler = useContext(ActivityFormContext);
  const { activity } = useContext(ActivityContext);
  const progress = formHandler.current.progressValue ?? 0;
  const theme = useTheme();
  const { t } = useTranslation();
  const [navigationError, setNavigationError] = useState<string>('');

  const getBgSx = (identifier: number) => (progress >= identifier ? completedBg : {});
  const getActiveSx = (identifier: number) =>
    progress === identifier ? active(theme.palette.primary.main) : {};

  const getIndicatorSx = (identifier: number) => {
    return { ...indicator, ...getBgSx(identifier), ...getActiveSx(identifier) };
  };

  const getConnectorSx = (identifier: number) => {
    return { ...connector, ...getBgSx(identifier) };
  };

  const isValidDestination = (formIndex: number) => {
    const nameError = validateName(activity.activityName, true, t);
    if (nameError) {
      setNavigationError(nameError);
      setTimeout(() => setNavigationError(''), 2000);

      return false;
    }
    if (formIndex > 2 && activity.startDate.toISOString() == new Date(0).toISOString()) {
      setNavigationError(t('You need to select a date first') + '');
      setTimeout(() => setNavigationError(''), 2000);
      return false;
    }
    return true;
  };

  return (
    <>
      {formHandler.current.progressValue && (
        <Stack direction={'row'} alignItems="center">
          <Box
            sx={getIndicatorSx(1)}
            onClick={() => formHandler.goToForm('Title' as ActivityForms)}
          >
            1
          </Box>
          <Box sx={getConnectorSx(2)} />
          <Box
            sx={getIndicatorSx(2)}
            onClick={() => isValidDestination(2) && formHandler.goToForm('Date' as ActivityForms)}
          >
            2
          </Box>
          <Box sx={getConnectorSx(3)} />
          <Box
            sx={getIndicatorSx(3)}
            onClick={() =>
              isValidDestination(3) && formHandler.goToForm('Duration' as ActivityForms)
            }
          >
            3
          </Box>
        </Stack>
      )}
      {navigationError.length ? (
        <Alert severity="error" sx={{ position: 'fixed', top: 0 }}>
          {navigationError}
        </Alert>
      ) : null}
    </>
  );
};

export default ProgressIndicator;
