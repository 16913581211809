import { Box, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '@/contexts/AppContext';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

type AddNoteButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
};

const AddNoteButton = ({ onClick, disabled }: AddNoteButtonProps) => {
  const { t } = useTranslation();
  const { navbarRef } = useContext(AppContext);
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const navbarHeight = navbarRef.current?.getBoundingClientRect().height ?? 0;
    setNavbarHeight(navbarHeight);
  }, [navbarRef]);

  return (
    <>
      {createPortal(
        <>
          <Box
            sx={{
              position: 'absolute',
              right: '8px',
              bottom: `${navbarHeight + 8}px`,
              m: 1,
              zIndex: 100,
              animation: 'fadeIn 0.5s',
              '@keyframes fadeIn': {
                from: { opacity: 0 },
                to: { opacity: 1 },
              },
            }}
          >
            <Fab
              color="primary"
              aria-label={t('Add') as string}
              onClick={onClick}
              disabled={disabled}
            >
              <AddIcon />
            </Fab>
          </Box>
        </>,
        navbarRef?.current ?? document.body,
      )}
    </>
  );
};

export default AddNoteButton;
