import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import SSODialog from './SSODialog';

const SSOLoginButton = () => {
  const { t } = useTranslation();
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <SSODialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
      <Button
        size="large"
        variant="contained"
        color={'secondary'}
        sx={{
          justifyContent: 'left',
          width: '100%',
          border: '1px solid lightgray',
        }}
        onClick={() => setDialogOpen(true)}
      >
        <KeyIcon sx={{ fontSize: '1.5em' }} />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          {t('Sign in with SSO')}
        </Box>
      </Button>
    </>
  );
};

export default SSOLoginButton;
