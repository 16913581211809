/* tslint:disable */
/* eslint-disable */

import * as runtime from '../runtime';
import { BaseAPI } from '../baseApi';
import { HttpStatusCode } from 'axios';

export type NotificationSubscribeResponse = {
  endpoint: string;
  keys?: {
    p256dh: string;
    auth: string;
  };
  isAppleDevice?: boolean;
};

export type NotificationUnsubscribeRequest = {
  endpoint: string;
};

export class NotificationApi extends BaseAPI {
  async getVapidPublicKey(): Promise<string | null> {
    try {
      const response = await this.request({
        path: `/notifications/public-key`,
        method: 'GET',
      });
      return await response.data;
    } catch (e) {
      return null;
    }
  }

  async subscribe(
    requestParameters: NotificationSubscribeResponse,
  ): Promise<HttpStatusCode | null> {
    try {
      const response = await this.request({
        path: `/notifications/subscribe`,
        method: 'POST',
        body: runtime.toRequestBody(requestParameters),
      });
      return response.status;
    } catch (e) {
      return null;
    }
  }

  async unsubscribe(
    requestParameters: NotificationUnsubscribeRequest,
  ): Promise<HttpStatusCode | null> {
    try {
      const b64 = btoa(requestParameters.endpoint);

      const response = await this.request({
        path: `/notifications/${b64}/unsubscribe`,
        method: 'DELETE',
      });
      return response.status;
    } catch (e) {
      return null;
    }
  }
  async getSubscriptions(): Promise<HttpStatusCode | null> {
    try {
      const response = await this.request({
        path: `/notifications/endpoints`,
        method: 'GET',
      });
      return response.status;
    } catch (e) {
      return null;
    }
  }
}
