/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* Component is only rendered when activity.recurringActivity != null */
import { FormControlLabel, FormGroup, Checkbox, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SyntheticEvent, useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { format } from 'date-fns';
import { RecurrenceDay } from '@api';
import { getISOWeek } from 'date-fns';

export const weekDays: Array<RecurrenceDay> = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

const DaySelect = () => {
  const { t } = useTranslation();
  const { activity, setActivity } = useContext(ActivityContext);

  const startDay = format(activity.startDate, 'EEEE').toUpperCase();

  // Add add weekday on click to recurringDays
  const addWeekday = (weekday: RecurrenceDay) => {
    if (activity.recurringActivity!.recurringDays) {
      setActivity({
        ...activity,
        recurringActivity: {
          ...activity.recurringActivity!,
          recurringDays: [...activity.recurringActivity!.recurringDays, weekday],
        },
      });
    } else {
      setActivity({
        ...activity,
        recurringActivity: { ...activity.recurringActivity!, recurringDays: [weekday] },
      });
    }
  };

  const removeWeekday = (weekday: RecurrenceDay) => {
    if (activity.recurringActivity!.recurringDays) {
      const newRecurringDays = activity.recurringActivity!.recurringDays.filter(
        (day) => day !== weekday,
      );
      setActivity({
        ...activity,
        recurringActivity: { ...activity.recurringActivity!, recurringDays: newRecurringDays },
      });
    }
  };

  const onWeekdaySelect = (day: RecurrenceDay, checked: boolean): void => {
    // If the checkbox is checked, call addWeekday with the value
    if (checked) {
      addWeekday(day);
    } else {
      // If the checkbox is not checked, call removeWeekday with the value
      removeWeekday(day);
    }
  };

  const addWeeks = (date: Date, weeks: number): Date => {
    const newDate = new Date(activity.startDate);
    newDate.setDate(activity.startDate.getDate() + weeks * 7);
    return newDate;
  };

  return (
    <>
      <Typography variant="body2" sx={{ my: 2 }} color="GrayText">{`${t(
        'Choose what days the task will repeat',
      )}:`}</Typography>
      <FormGroup>
        {weekDays.map((day) => (
          <FormControlLabel
            control={<Checkbox color="success" />}
            label={
              <Typography
                component="span"
                sx={{ '&:first-letter': { textTransform: 'uppercase' } }}
              >
                {t(`days.${day.toLowerCase()}`)}
              </Typography>
            }
            key={day}
            onChange={(e: SyntheticEvent<Element, Event>, checked: boolean) =>
              onWeekdaySelect(day, checked)
            }
            value={day}
            checked={activity.recurringActivity?.recurringDays?.includes(day)}
            disabled={day === startDay}
          />
        ))}
      </FormGroup>
      <Typography variant="body2" sx={{ my: 2 }} color="GrayText">
        {t('All activities will be scheduled at the same week') + ' ('}
        {getISOWeek(addWeeks(activity.startDate, 0)) +
          ', ' +
          getISOWeek(addWeeks(activity.startDate, 2)) +
          ', ' +
          getISOWeek(addWeeks(activity.startDate, 4)) +
          '...'}
        {')'}
      </Typography>
    </>
  );
};

export default DaySelect;
