import { AccordionSummary, Stack, Switch, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsConfig } from './SettingsAccordionConfig';
import { useTheme } from '@mui/material/styles';

const DarkModeToggle = (props: SettingsConfig) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const isDarkMode = useMemo(() => {
    return palette.mode === 'dark';
  }, [palette]);

  const toggleDarkMode = () => {
    localStorage.setItem('darkMode', !isDarkMode ? 'dark' : 'light');
    //make sure we trigger the event
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <>
      <AccordionSummary sx={{ mr: -1 }}>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          width={'100%'}
          alignItems={'center'}
          onClick={(e) => {
            e.stopPropagation();
            toggleDarkMode();
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t(props.title)}
          </Typography>
          <Switch checked={isDarkMode} inputProps={{ 'aria-label': 'Toggle darkMode' }} />
        </Stack>
      </AccordionSummary>
    </>
  );
};

export default DarkModeToggle;
