import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SupervisorList from './SupervisorList';
import SupervisorHeaderText from './SupervisorHeaderText';
import { useContext } from 'react';
import { SupervisorContext } from 'src/contexts/SupervisorContext';

type SupervisorDialogProps = {
  open: boolean;
  onClose: () => void;
};

const SupervisorDialog = ({ open, onClose }: SupervisorDialogProps) => {
  const { t } = useTranslation();
  const { setClient } = useContext(SupervisorContext);

  const stopSupervising = () => {
    setClient();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <SupervisorHeaderText headerText="Currently supervising" />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <SupervisorList onSelect={onClose} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={stopSupervising} variant="contained" color="error">
          {t('Stop supervising')}
        </Button>
        <Button onClick={onClose} variant="contained" color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SupervisorDialog;
