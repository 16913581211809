import { forwardRef, HTMLAttributes, useEffect, useRef } from 'react';
import { Stack, Box, Typography, IconButton } from '@mui/material';
import { Board } from '@api';
import EditIcon from '@mui/icons-material/Edit';
import { useSortable } from '@dnd-kit/sortable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export type BoardItemProps = HTMLAttributes<HTMLDivElement> & {
  board: Board;
  invisible?: boolean;
  isDragging?: boolean;
  deleteItem?: boolean;
  draghandle?: boolean;
};

const BoardItem = forwardRef<HTMLDivElement, BoardItemProps>(
  ({ board, invisible, isDragging, deleteItem, style, ...props }, ref) => {
    const active = useRef(false);
    const { attributes, listeners } = useSortable({ id: board.id });

    useEffect(() => {
      if (invisible && active.current) {
        active.current = false;
      }
    }, [invisible]);

    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        ref={ref}
        sx={{
          width: `100%`,
          opacity: invisible ? '0' : '1',
          borderRadius: '5px',
          cursor: isDragging ? 'grabbing' : 'grab',
          transform: isDragging ? 'rotate(5deg)' : 'rotate(0deg)',
          transformOrigin: 'left',
          boxShadow: isDragging ? 10 : 2,
          overflow: 'hidden',
          background: (theme) => theme.palette.background.paper,
          my: 0.4,
          ...style,
        }}
        {...props}
      >
        <Box
          sx={{
            height: '3.5em',
            width: '2.5em',
            cursor: 'grab',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          {...attributes}
          {...listeners}
        >
          <DragIndicatorIcon />
        </Box>

        <Box
          sx={{
            backgroundColor: board.color,
            height: '1.5rem',
            width: '1.5rem',
            borderRadius: '50%',
            mr: 1.5,
          }}
        />
        <Typography
          sx={{
            flex: 1,
            m: 0,
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            userSelect: 'none',
            msUserSelect: 'none',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none',
            textAlign: 'start',
          }}
        >
          {board.title}
        </Typography>
      </Stack>
    );
  },
);

export default BoardItem;
