import { createContext, useEffect, useState, useContext } from 'react';
import { ActivityApi, ActivityInstance } from '@api';
import { getWeekDates } from '../components/utils/DateUtils';
import { getISOWeek, getISOWeekYear } from 'date-fns';
import { Dialog } from '@mui/material';
import ActivityDialog from '../components/calendar/weekly/dialogs/ActivityDialog';
import ClusterDialog from '../components/calendar/weekly/dialogs/ClusterDialog';
import { SupervisorContext } from './SupervisorContext';
import { AppContext } from './AppContext';

type props = {
  children?: React.ReactNode;
};

export const getCurrentWeek = (): { week: number; year: number } => {
  return {
    week: getISOWeek(new Date()),
    year: getISOWeekYear(new Date()),
  };
};

export type CalendarContextType = {
  weekInstances: ActivityInstance[];
  selectedWeek: { week: number; year: number };
  setSelectedWeek: ({ week, year }: { week: number; year: number }) => void;
  refreshWeek: () => void;
  setCalendarDialog: (instanceIds: number[] | null) => void;
  findInstance: (instanceId: number) => ActivityInstance | undefined;
  goToCurrentWeek: () => void;
};

export const CalendarContext = createContext<CalendarContextType>({} as CalendarContextType);

export const CalendarScene = ({ children }: props) => {
  const [selectedWeek, setSelectedWeek] = useState<{ week: number; year: number }>(
    getCurrentWeek(),
  );
  const [dialogActivities, setDialogActivities] = useState<ActivityInstance[] | null>(null);
  const [weekInstances, setWeekInstances] = useState<ActivityInstance[]>([]);

  const { currentClient, isSupervising } = useContext(SupervisorContext);

  const { user } = useContext(AppContext);

  const getInstances = async (dates: {
    startDate: Date;
    endDate: Date;
  }): Promise<ActivityInstance[]> => {
    const res = await new ActivityApi().getActivityInstances({
      ...dates,
      supervisedTag: currentClient ? currentClient.tag : undefined,
    });
    if (res?.length) {
      return res;
    } else {
      return [];
    }
  };

  const findInstance = (instanceId: number) => {
    const res = weekInstances.find((instance) => instance.activityInstanceId === instanceId);
    return res;
  };

  // Refreshes the entire week
  const refreshWeek = async () => {
    const dates = getWeekDates(selectedWeek);
    const res = await getInstances(dates);
    setWeekInstances(res);
  };

  useEffect(() => {
    if (isSupervising) {
      if (currentClient) refreshWeek();
    } else refreshWeek();
  }, [selectedWeek, currentClient, user]); // <- User if we change user's timezone

  const setCalendarDialog = (instanceIds: number[] | null) => {
    if (!instanceIds) {
      setDialogActivities(null);
      return;
    }
    const dialogInstances: ActivityInstance[] = [];
    instanceIds?.forEach((instanceId) => {
      const e = weekInstances.find((instance) => instance.activityInstanceId === instanceId);
      if (e) dialogInstances.push(e);
    });

    setDialogActivities(dialogInstances);
  };

  const goToCurrentWeek = () => {
    setSelectedWeek(getCurrentWeek());
  };

  return (
    <CalendarContext.Provider
      value={{
        weekInstances: weekInstances,
        selectedWeek: selectedWeek,
        setSelectedWeek: setSelectedWeek,
        refreshWeek: refreshWeek,
        setCalendarDialog: setCalendarDialog,
        findInstance: findInstance,
        goToCurrentWeek: goToCurrentWeek,
      }}
    >
      <Dialog
        open={!!dialogActivities}
        onClose={() => setCalendarDialog(null)}
        scroll="paper"
        fullWidth
      >
        {dialogActivities &&
          (dialogActivities.length === 1 ? (
            <ActivityDialog activity={dialogActivities[0]} />
          ) : (
            <ClusterDialog activities={dialogActivities} />
          ))}
      </Dialog>
      {children}
    </CalendarContext.Provider>
  );
};
