import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from 'src/contexts/AppContext';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { pageHeight } = useContext(AppContext);
  return (
    <Stack
      height={`${pageHeight}px`}
      justifyContent="center"
      alignItems="center"
      direction="column"
      padding={3}
    >
      <Typography variant="h5" component="h1" paragraph>
        {t('Page not found')}
      </Typography>
      <Typography variant="body1" paragraph textAlign="center">
        {t(
          "Oops! The page you're looking for couldn't be found. Please check the URL and try again.",
        )}
      </Typography>
      <Button
        size="large"
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        startIcon={<HomeIcon />}
      >
        {t('Go back home')}
      </Button>
    </Stack>
  );
};

export default NotFoundPage;
