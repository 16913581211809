import { StatisticsContext } from 'src/contexts/StatisticsContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper, Box, Stack, Button } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { AiApi, languageKey } from '@api';
import { AppContext } from '@/contexts/AppContext';
import CircularProgress from '@mui/material/CircularProgress';

const AISummary = () => {
  const { data, intervalType } = useContext(StatisticsContext);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const aiEnabled = localStorage.getItem('aiEnabled') === 'true';

  const isLanguageKey = (lang: any): lang is languageKey => {
    return ['sv', 'en', 'de', 'fr', 'es', 'it', 'pl'].includes(lang);
  };

  const [summary, setSummary] = useState('');

  const fetchSummary = async () => {
    setLoading(true);
    const newSummary = await new AiApi().statisticsAnalysis(
      data,
      user?.firstName ?? 'namnet är okänt',
      isLanguageKey(lang) ? lang : 'sv',
    );
    setSummary(newSummary);
    setLoading(false);
  };

  useEffect(() => {
    fetchSummary();
  }, [data]);

  if (!aiEnabled) return null;

  return (
    <Paper
      elevation={4}
      sx={(theme) => ({
        width: '100%',
        padding: '16px',
        marginTop: '16px',
      })}
    >
      <Card sx={{ maxHeight: 400, border: 'none', boxShadow: 'none' }}>
        <Typography variant="subtitle1">{t('AI-Summary')}</Typography>
        {!loading ? (
          <>
            {summary != '' ? (
              <Typography variant="body2">{summary}</Typography>
            ) : (
              <Stack justifyContent={'center'}>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                  {t('No summary available')}
                </Typography>
                <Button onClick={fetchSummary}>{t('Try again')}</Button>
              </Stack>
            )}
          </>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Card>
    </Paper>
  );
};

export default AISummary;
