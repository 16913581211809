import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type BasicDialogContent = {
  title: string;
  body: string;
};

type BasicDialogProps = {
  open: boolean;
  content: BasicDialogContent;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose: () => void;
};

const BasicDialog = ({ open, content, onClose }: BasicDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(content.title)}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t(content.body)}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default BasicDialog;
