import { Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsAccordion from 'src/components/settings/SettingsAccordion';
import { useContext } from 'react';
import { AppContext } from 'src/contexts/AppContext';

const SettingsPage = () => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  return (
    <Container>
      <Stack spacing={3} pb={3}>
        <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', padding: 0.5 }}>
          {t('Settings')}
        </Typography>
        <Typography variant="subtitle2" sx={{ flex: 1, textAlign: 'center' }}>
          {t('Tag')}: {user?.tag}
        </Typography>
        <SettingsAccordion />
      </Stack>
    </Container>
  );
};

export default SettingsPage;
