import { StatisticsContext } from 'src/contexts/StatisticsContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type Item = {
  text: string;
  value: string | number;
};

const OverviewStat = () => {
  const { t } = useTranslation();
  const { data, intervalType } = useContext(StatisticsContext);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<Item[]>();

  useEffect(() => {
    if (!Object.keys(data).length) {
      setLoading(false);
      setItems([
        {
          text: t('Average rating'),
          value: 0,
        },
        {
          text: t('Completed activities'),
          value: 0,
        },
        {
          text: t('Activities with a score of 4+'),
          value: `0 %`,
        },
        {
          text: t('Missed activities'),
          value: 0,
        },
      ]);
    } else if (data?.amountOfActivitiesPerRating) {
      setLoading(false);
      setItems([
        {
          text: t('Average rating'),
          value: parseFloat(data.averageFeelingOfActivities.toFixed(1)),
        },
        {
          text: t('Completed activities'),
          value: data.amountOfCompletedActivities,
        },
        {
          text: t('Activities with a score of 4+'),
          value: `${
            data.amountOfCompletedActivities > 0
              ? Math.ceil(
                  ((data?.amountOfActivitiesPerRating[4] + data?.amountOfActivitiesPerRating[5]) /
                    data.amountOfCompletedActivities) *
                    100,
                )
              : 0
          } % `,
        },
        {
          text: t('Missed activities'),
          value: data?.notCompletedActivities?.amountOfNotCompletedActivities,
        },
      ]);
    }
  }, [data]);

  return (
    <Paper
      elevation={4}
      sx={(theme) => ({
        width: '100%',
        padding: '16px',
        marginTop: '16px',
      })}
    >
      {!loading ? (
        <Card sx={{ maxHeight: 400, border: 'none', boxShadow: 'none' }}>
          <Typography variant="subtitle1">{t(`Intervals.${intervalType}`)}</Typography>
          <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            {items?.map((item: Item, index: number) => (
              <Grid item xs={6} key={index}>
                <Card sx={{ minHeight: 100, border: 'none', boxShadow: 'none' }} raised={false}>
                  <CardContent>
                    <Typography gutterBottom variant="h4" component="div" textAlign={'center'}>
                      {item.value}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" textAlign={'center'}>
                      {item.text}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Card>
      ) : (
        <></>
      )}
    </Paper>
  );
};

export default OverviewStat;
