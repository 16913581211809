import { Box, SxProps } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import ScrollItem from './ScrollItem';
import { useTheme } from '@mui/material';

type ScrollPickerProps = {
  value: string | number | null;
  onChange: (item: string | number, index: number) => void;
  items: Array<string | number>;
  sx?: SxProps;
  infiniteScroll?: boolean;
  itemHeight?: number; //Item height in em
  marginHeight?: number; //Margin (Fade) height in em
};

const ScrollPicker = ({
  value,
  onChange,
  items,
  sx = {},
  infiniteScroll = true,
  itemHeight = 3,
  marginHeight = 1,
}: ScrollPickerProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const itemSizeInPx = useRef<number>(0);
  const minScrollTop = useRef<number>(0);
  const maxScrollBottom = useRef<number>(0);
  const [headerItems, setHeaderItems] = useState<Array<string | number>>([]);
  const [footerItems, setFooterItems] = useState<Array<string | number>>([]);

  const theme = useTheme();

  // Use effect to set header and footer items
  useEffect(() => {
    if (!infiniteScroll) return;
    const newHeaderItems = [];
    const newFooterItems = [];

    items.length > 1 && newHeaderItems.push(items[items.length - 2]);
    items.length > 0 && newHeaderItems.push(items[items.length - 1]);

    items.length > 0 && newFooterItems.push(items[0]);
    items.length > 1 && newFooterItems.push(items[1]);

    setHeaderItems(newHeaderItems);
    setFooterItems(newFooterItems);
  }, [items]);

  useEffect(() => {
    if (!scrollRef.current) return;
    if (headerItems.length === 0 && infiniteScroll) return;
    const container = scrollRef.current;
    const emToPx = parseFloat(getComputedStyle(container).fontSize);
    itemSizeInPx.current = itemHeight * emToPx;

    //If we scroll above this point, we should scroll to the bottom
    minScrollTop.current =
      (headerItems.length - 1) * itemSizeInPx.current + itemSizeInPx.current / 2;

    const scrollHeight = container.scrollHeight - container.clientHeight;

    //If we scroll below this point, we should scroll to the top
    maxScrollBottom.current =
      scrollHeight - (footerItems.length - 1) * itemSizeInPx.current - itemSizeInPx.current / 2;

    // Set initial scroll position
    if (value !== null) scrollToItem(items.indexOf(value), 'auto');
    else scrollToItem(0, 'auto');

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
        if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [scrollRef, headerItems]);

  // This function should be called when we want to scroll to a specific item
  const scrollToItem = (index: number, behavior: ScrollBehavior) => {
    if (scrollRef.current === null) return;
    if (headerItems.length === 0 && infiniteScroll) return;
    const container = scrollRef.current;
    const scrollTop = container.scrollTop;
    const headerOffset = headerItems.length * itemSizeInPx.current;
    const scrollPosition = index * itemSizeInPx.current + headerOffset;
    if (scrollPosition !== scrollTop)
      container.scrollTo({
        top: scrollPosition,
        behavior: behavior,
      });
  };

  // This function should be called when the user stops scrolling
  const scrollToNearestItem = () => {
    //convert 2em to px
    if (scrollRef.current === null) return;
    const headerItemsLength = headerItems.length;

    const container = scrollRef.current;
    if (container) {
      //How much we have scrolled
      const scrollTop = container.scrollTop;
      const offsetScrollTop = scrollTop - headerItemsLength * itemSizeInPx.current;
      // How many items can be dispalyed without scrolling
      // newItem should be between 0 and items.length - 1
      let newItem = Math.round(offsetScrollTop / itemSizeInPx.current);

      scrollToItem(newItem, 'smooth');

      // This should never happen, but just in case
      if (newItem < 0) {
        newItem = items.length - 1; // Set to the max value
      } else if (newItem >= items.length) {
        newItem = 0; // Set to 0
      }
      const selectedItem = items[newItem];
      onChange(selectedItem, newItem);
    }
  };

  // This function should be called when the user scrolls
  const handleInfiniteScroll = () => {
    if (!scrollRef.current) return;
    if (headerItems.length === 0 && infiniteScroll) return;
    const scrollTop = scrollRef.current.scrollTop;
    if (scrollTop < minScrollTop.current) {
      const diff = minScrollTop.current - scrollTop;
      scrollRef.current.scrollTop = maxScrollBottom.current - diff;
    } else if (scrollTop >= maxScrollBottom.current) {
      const diff = scrollTop - maxScrollBottom.current;
      scrollRef.current.scrollTop = minScrollTop.current + diff;
    }
  };

  const handleScroll = () => {
    // Handle infinite scroll
    if (infiniteScroll) handleInfiniteScroll();
    // Debounce scroll event
    if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => {
      // User stopped scrolling
      scrollToNearestItem();
    }, 350);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: `${itemHeight + marginHeight * itemHeight * 2}em`,
        overflow: 'scroll',
        msOverflowStyle: 'none' /* IE and Edge */,
        scrollbarWidth: 'none' /* Firefox */,
        '&::-webkit-scrollbar': {
          display: 'none' /* Chrome, Safari, Opera */,
        },
        ...sx,
      }}
      ref={scrollRef}
    >
      <Box
        sx={{
          background: `linear-gradient(
            to bottom,
            ${theme.palette.background.paper} 0%,
            transparent 80%
          )`,
          position: 'sticky',
          top: -1,
          height: `${marginHeight * itemHeight}em}`,
          minHeight: `${marginHeight * itemHeight}em}`,
        }}
      />
      {headerItems.map((item: number | string, index: number) => (
        <ScrollItem key={index} item={item} height={itemHeight} />
      ))}
      {items.map((item, index) => (
        <ScrollItem key={index} item={item} height={itemHeight} />
      ))}
      {footerItems.map((item: number | string, index: number) => (
        <ScrollItem key={index} item={item} height={itemHeight} />
      ))}
      <Box
        sx={{
          background: `linear-gradient(
            to bottom,
            transparent 20%,
            ${theme.palette.background.paper} 100%
          )`,
          position: 'sticky',
          bottom: -1,
          height: `${marginHeight * itemHeight}em}`,
          minHeight: `${marginHeight * itemHeight}em}`,
        }}
      />
    </Box>
  );
};

export default ScrollPicker;
