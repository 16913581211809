import { Box, Stack, Typography, useTheme } from '@mui/material';

export type SimpleListProps = {
  items: string[];
  onRowClick?: (item: string) => void;
  onIconClick?: (item: string) => void;
  icon?: JSX.Element;
};

const SimpleList = ({ items, onRowClick, onIconClick, icon }: SimpleListProps) => {
  const { palette } = useTheme();
  if (!items.length) return null;
  return (
    <Stack
      sx={{ width: '100%', borderRadius: '1em', boxShadow: `0 0 0 2px ${palette.lineDark}40` }}
      overflow={'hidden'}
    >
      {items.map((item, index) => (
        <Stack
          justifyContent={'space-between'}
          alignItems={'center'}
          direction={'row'}
          sx={{
            p: 1,
            backgroundColor: index % 2 ? null : palette.lineLight,
          }}
          key={`simpleListItem-${index}-${item}`}
          onClick={() => onRowClick?.(item)}
        >
          <Typography variant="subtitle1" sx={{ lineHeight: 0 }}>
            {item}
          </Typography>

          {icon ? (
            <Box
              onClick={(event) => {
                event.stopPropagation();
                onIconClick?.(item);
              }}
              m={0.5}
              lineHeight={0}
            >
              {icon}
            </Box>
          ) : null}
        </Stack>
      ))}
    </Stack>
  );
};

export default SimpleList;
