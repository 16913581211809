import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { InstanceContext } from '@/contexts/InstanceContext';
import { Box, Stack } from '@mui/material';
import { SubtaskItem as SubtaskItemType } from '@api';
import SubtaskItem from './SubtaskItem';

const Subtasks = () => {
  const { activity } = useContext(ActivityContext);
  const { instance, toggleSubtask } = useContext(InstanceContext);

  const handleCheckedChange = (subtaskId: number) => {
    toggleSubtask(subtaskId);
  };

  const isChecked = (item: SubtaskItemType): boolean => {
    return !!instance.checkedSubtasks?.some((i) => i === item.subtaskId);
  };

  return (
    <Box>
      {instance.parentActivityId &&
        activity?.subtasks?.map((item) => {
          return (
            <Stack key={`Checkbox${item.subtaskId}`} spacing={1}>
              <SubtaskItem
                imgSrc={item.picture}
                title={item.subtaskText}
                checked={isChecked(item)}
                onCheckedChange={handleCheckedChange}
                id={item.subtaskId}
              />
            </Stack>
          );
        })}
    </Box>
  );
};

export default Subtasks;
