import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AuthApi, User } from '@api';

type props = {
  children?: React.ReactNode;
};

type AuthenticationStatus = 'loading' | 'authenticated' | 'unauthenticated';

export type AppContextType = {
  user: User | null | undefined;
  setUser: (user: User | null) => void;
  authenticated: AuthenticationStatus;
  navbarRef: React.MutableRefObject<null | HTMLDivElement>;
  pageHeight: number;
  setPageHeight: (height: number) => void;
  showNavbar: boolean;
  setShowNavbar: (value: boolean) => void;
};

export const AppContext = createContext<AppContextType>({
  user: null,
  setUser: () => {},
  authenticated: 'loading',
  navbarRef: {} as React.MutableRefObject<null>,
  pageHeight: 0,
  setPageHeight: () => {},
  showNavbar: true,
  setShowNavbar: () => {},
});

export const AppScene = ({ children }: props) => {
  const [user, setUser] = useState<User | null>();
  const [pageHeight, setPageHeight] = useState<number>(0);
  const [showNavbar, setShowNavbar] = useState<boolean>(true);

  const navbarRef = useRef(null);
  const [authenticated, setAuthenticated] = useState<AuthenticationStatus>(
    user ? 'authenticated' : 'loading',
  );

  useEffect(() => {
    if (authenticated === 'loading') {
      getUser();
    }
  }, [authenticated]);

  const getUser = useCallback(async () => {
    const xsrfToken = document.cookie.split('; ').find((row) => row.startsWith('XSRF-TOKEN='));
    if (xsrfToken) {
      const res = await new AuthApi().getUser();
      setUser(res);
      setAuthenticated(res ? 'authenticated' : 'unauthenticated');
    } else {
      setAuthenticated('unauthenticated');
    }
  }, []);

  const contextProvider = useMemo(
    () => ({
      user,
      setUser,
      authenticated,
      navbarRef,
      pageHeight,
      setPageHeight,
      showNavbar,
      setShowNavbar,
    }),
    [user, setUser, authenticated, navbarRef, pageHeight, setPageHeight, showNavbar, setShowNavbar],
  );

  return <AppContext.Provider value={contextProvider}>{children}</AppContext.Provider>;
};
