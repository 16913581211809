import { useState, useRef } from 'react';
import { Stack } from '@mui/material';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import SortableSubtaskItem from './SortableSubtaskItem';
import SubtaskItem from './SubtaskItem';
import { SubtaskItem as SubtaskItemType } from '@api';

type SubtasksListProps = {
  list: SubtaskItemType[];
  onListUpdate: (newItems: SubtaskItemType[]) => void;
};

const SubtasksList = ({ list, onListUpdate }: SubtasksListProps) => {
  const [activeSubtaskItem, setActiveSubtaskItem] = useState<SubtaskItemType | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    const activeSubtaskItem = list.find((subtask) => subtask.subtaskId === event.active.id);
    if (activeSubtaskItem) {
      setActiveSubtaskItem(activeSubtaskItem);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over?.id) {
      const oldIndex = list.findIndex((obj) => obj.subtaskId === active.id);
      const newIndex = list.findIndex((obj) => obj.subtaskId === over?.id);
      const newArray = arrayMove(list, oldIndex, newIndex);

      const sortedSubtasks = newArray.map((item, index) => ({ ...item, position: index + 1 }));
      onListUpdate(sortedSubtasks);
    }

    setActiveSubtaskItem(null);
  };

  const handleDragCancel = () => {
    setActiveSubtaskItem(null);
  };

  const onRemove = (id: number) => {
    //remove item from subtasks
    const newSubtasks = list.filter((item) => item.subtaskId !== id);
    onListUpdate(newSubtasks);
  };

  const updateSubtaskItem = (item: SubtaskItemType) => {
    const newSubtaskItems = Array.from(list);
    const index = list.findIndex((subtask) => subtask.subtaskId === item.subtaskId);
    newSubtaskItems[index] = item;
    onListUpdate(newSubtaskItems);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext
        items={list.map((item) => item.subtaskId)}
        strategy={verticalListSortingStrategy}
      >
        <Stack ref={containerRef} direction="column" px={0.5} maxWidth={'35em'} width={'100%'}>
          {list.map((subtask) => (
            <SortableSubtaskItem
              key={subtask.subtaskId}
              item={subtask}
              onUpdate={updateSubtaskItem}
              onDelete={onRemove}
            />
          ))}
        </Stack>
      </SortableContext>
      {createPortal(
        <>
          <DragOverlay adjustScale>
            {activeSubtaskItem ? (
              <SubtaskItem id="dragOverlayElement" item={activeSubtaskItem} isDragging />
            ) : null}
          </DragOverlay>
        </>,
        document.body,
      )}
    </DndContext>
  );
};

export default SubtasksList;
