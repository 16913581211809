import { isAfter, isDate } from 'date-fns';
import { CalendarDayContext } from '@/contexts/CalendarDayContext';
import { Stack, Typography, Box } from '@mui/material';
import { useContext, useMemo } from 'react';
import ActivityCard from '@/components/activity/ActivityCard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

const UpcomingActivities = () => {
  const { instances } = useContext(CalendarDayContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const upcomingInstances = useMemo(
    () =>
      instances.filter(
        (instance) => isAfter(instance.startDate, new Date()) && !isDate(instance.dateIsStarted),
      ),
    [instances],
  );

  const viewActivity = (activityInstanceId: number) => () => {
    navigate(`/activity/${activityInstanceId}`);
  };

  const displayNoActivitiesMessage = useMemo(() => {
    const nrOfCompletedInstances = instances.filter((instance) =>
      isDate(instance.dateIsCompleted),
    ).length;
    if (nrOfCompletedInstances === instances.length) return true;
    return false;
  }, [instances]);

  return (
    <Stack spacing={2}>
      {upcomingInstances.length > 0 && (
        <>
          <Typography variant="subtitle1">{t('Upcoming Activities')}</Typography>
          {upcomingInstances.map((instance, index) => (
            <Box onClick={viewActivity(instance.activityInstanceId)} key={index} display={'block'}>
              <ActivityCard activityInstance={instance} type={'upcoming'}></ActivityCard>
            </Box>
          ))}
        </>
      )}
      {/* {displayNoActivitiesMessage && (
        <Typography variant={'subtitle1'}>{t('No more activities today')}</Typography>
      )} */}
      {displayNoActivitiesMessage && (
        <Card
          sx={{
            display: 'flex',
            boxShadow: '0px 0px 0px',
            height: '30vh',
            justifyContent: 'center',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>
              <NotificationsOffIcon style={{ fontSize: 80, color: 'grey' }} />
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
              {t('No more activities today')}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

export default UpcomingActivities;
