export const lightenColor = (color: string, percent: number) => {
  // Check if the color string is valid
  if (!/^#[0-9A-F]{6}$/i.test(color)) {
    return '#fff';
  }

  // Remove the "#" symbol and parse the color
  const hex = color.slice(1);
  const num = parseInt(hex, 16);

  // Calculate the new RGB values
  let r = (num >> 16) + (255 - (num >> 16)) * (percent / 100);
  let g = ((num >> 8) & 0x00ff) + (255 - ((num >> 8) & 0x00ff)) * (percent / 100);
  let b = (num & 0x0000ff) + (255 - (num & 0x0000ff)) * (percent / 100);

  // Ensure the values are within the valid range (0-255)
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  // Convert the RGB values back to a hex string
  const newHex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;

  return newHex;
};
