import { AccordionSummary, Stack, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import AiLegalDialog from '@/components/utils/dialogs/AiLegalDialog';

const AIToggle = () => {
  const { t } = useTranslation();
  const [legalDialogOpen, setLegalDialogOpen] = useState(false);
  const [isAIEnabled, setIsAIEnabled] = useState(localStorage.getItem('aiEnabled') === 'true');

  const ToggleAI = () => {
    if (isAIEnabled) {
      localStorage.setItem('aiEnabled', 'false');
      setIsAIEnabled(false);
      window.dispatchEvent(new Event('storage'));
    } else {
      // Ask for consent
      setLegalDialogOpen(true);
    }
  };

  const enableAI = () => {
    localStorage.setItem('aiEnabled', 'true');
    setIsAIEnabled(true);
    window.dispatchEvent(new Event('storage'));
    setLegalDialogOpen(false);
  };

  return (
    <>
      <AccordionSummary sx={{ mr: -1 }}>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          width={'100%'}
          alignItems={'center'}
          onClick={(e) => {
            e.stopPropagation();
            ToggleAI();
          }}
        >
          <Typography variant="h6" color={'primary'}>
            {t('Enable AI')}
          </Typography>
          <Switch checked={isAIEnabled} inputProps={{ 'aria-label': 'Toggle AI' }} />
        </Stack>
      </AccordionSummary>

      <AiLegalDialog
        open={legalDialogOpen}
        onEnable={enableAI}
        onClose={() => setLegalDialogOpen(false)}
      />
    </>
  );
};

export default AIToggle;
