import {
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const AiLegalDialog = ({
  open,
  onEnable,
  onClose,
}: {
  open: boolean;
  onEnable: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [legalConsent, setLegalConsent] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>{t('Important Information about Data Protection')}</DialogTitle>
        <DialogContent>
          <Stack>
            <AILegalText />

            <Stack direction={'row'} alignItems={'center'} pt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'Checkbox for legal consent' }}
                  />
                }
                label={
                  <Typography component="span">
                    {t('I agree that my activity history is shared for this purpose.')}
                  </Typography>
                }
                checked={legalConsent}
                onChange={() => setLegalConsent(!legalConsent)}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            {t('Cancel')}
          </Button>
          <Button onClick={onEnable} disabled={!legalConsent}>
            {t('Accept and Enable')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AILegalText = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="body2">
        {t(
          'To use the AI tool in this application, it is required that certain information is shared with our AI service provider',
        )}
      </Typography>
      <Typography variant="subtitle1" fontWeight={600}>
        {t('Mandatory information')}
      </Typography>
      <Typography variant="body2">
        {t(
          'Your activity history, including previously planned activities, is shared with the AI service',
        )}
      </Typography>
      <Typography variant="subtitle1" fontWeight={600} pt={1}>
        {t('Why we share this information')}
      </Typography>
      <Typography variant="body2">
        {t('To enable the AI to provide you with relevant and customized suggestions')}
      </Typography>
      <Typography variant="subtitle1" fontWeight={600} pt={1}>
        {t('Voluntary information')}
      </Typography>
      <Typography variant="body2">
        {t('There is an opportunity to share additional information to improve AI suggestions')}
      </Typography>
      <Typography variant="subtitle1" fontWeight={600} pt={1}>
        {t('Data security')}
      </Typography>
      <Typography variant="body2">
        {t('The information you share with the AI service is not stored with OpenAI')}
      </Typography>
      <Typography variant="subtitle1" fontWeight={600} pt={1}>
        {t('Important to know')}
      </Typography>
      <Typography variant="body2">
        {t(
          'We cannot guarantee that AI suggestions are always appropriate or meet your specific needs.',
        )}
      </Typography>
      <Typography variant="body2" pt={1}>
        {t('You can withdraw your consent at any time in the settings.')}
      </Typography>
    </>
  );
};

export default AiLegalDialog;
