import { CssBaseline, ThemeProvider } from '@mui/material';
import { AppScene } from './contexts/AppContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { dateLocales } from '@/i18n/dateLocales';
import { muiLocales } from '@/i18n/muiLocales';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useMemo, useState } from 'react';
import { createTheme } from '@mui/material';
import { appTHeme, getPalette } from './utils/theme';
import { PaletteMode } from '@mui/material';
import AuthGuard from './router/AuthGuard';
import { TimeZoneScene } from './contexts/TimeZoneScene';
import { BrowserRouter } from 'react-router-dom';

export const App = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  // The default setting in the browser
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // Set if they changed in the settings
  const [selectedDarkMode, setSelectedDarkMode] = useState(localStorage.getItem('darkMode'));

  const handleStorageChange = () => {
    setSelectedDarkMode(localStorage.getItem('darkMode'));
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const darkMode = useMemo(() => {
    if (selectedDarkMode === 'dark') return true;
    else if (selectedDarkMode === 'light') return false;
    else return prefersDarkMode;
  }, [prefersDarkMode, selectedDarkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        ...appTHeme,
        palette: getPalette((darkMode ? 'dark' : 'light') as PaletteMode),
      }),
    [darkMode],
  );

  return (
    <AppScene>
      <TimeZoneScene>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={dateLocales[lang]}
          localeText={muiLocales[lang]}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <AuthGuard />
            </BrowserRouter>
          </ThemeProvider>
        </LocalizationProvider>
      </TimeZoneScene>
    </AppScene>
  );
};
