import { Box, Typography } from '@mui/material';
import { ActivityInstance } from '@api';
import { MutableRefObject, useState, useEffect, useRef, useContext } from 'react';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import {
  minActivityRenderHeight,
  resizeTextVertically,
  resizeTextHorizontally,
} from '@/components/utils/CalendarFns';
import { CalendarContext } from '@/contexts/CalendarContext';
import { useTheme } from '@mui/material';

const CalendarDayCluster = ({
  instances,
  parentRef,
}: {
  instances: ActivityInstance[];
  parentRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const [parentHeight, setParentHeight] = useState(0); // store the parent height in state
  const [startTime, setStartTime] = useState(0); // The height the activity should be rendered at
  const [duration, setDuration] = useState(0); // The height the activity should be rendered at

  const { setCalendarDialog } = useContext(CalendarContext);

  const { palette } = useTheme();

  const textRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (textRef.current === null || duration === 0) return;
    const visible = resizeTextHorizontally(textRef);
    if (visible) resizeTextVertically(textRef, duration, 1.3, 1.3);
  }, [duration]);

  useEffect(() => {
    // get the parent height when the component mounts
    if (parentRef)
      if (parentRef.current) {
        const height = parentRef.current.getBoundingClientRect().height;
        setParentHeight(height);
      }
  }, []);

  useEffect(() => {
    //Calculate the distance between each number component based on the parent height and the number of components
    const distance = parentHeight / 24;

    if (instances.length === 0) return;
    const startTimes = instances.map((instance) => instance.startDate);

    //Map endDates by adding duration (minutes) to startDate
    const endTimes = instances.map((instance) => {
      const endDate = new Date(instance.startDate);
      endDate.setMinutes(endDate.getMinutes() + (instance.renderDuration || 0));
      return endDate;
    });

    //Get the earlest startTime
    const earliestStartTime = startTimes.reduce((a, b) => (a < b ? a : b));

    //Get the latest endTime
    const latestEndTime = endTimes.reduce((a, b) => (a > b ? a : b));

    //Calculate duration, in minutes
    const duration = Math.max(
      (latestEndTime.getTime() - earliestStartTime.getTime()) / 1000 / 60,
      minActivityRenderHeight,
    );

    const hours = earliestStartTime.getHours();
    const minutes = earliestStartTime.getMinutes() / 69;
    setStartTime(distance * (hours + minutes));

    //Set the duration based on the distance between each number component and the duration of the activity
    setDuration(distance * (duration / 60));
  }, [parentHeight, instances]);

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: `${startTime}px`,
          left: `0px`,
          width: `calc(100%)`,
          height: `${duration}px`,
          textAlign: 'center',
          borderRadius: '2px',
          border: `1px solid ${palette.line}`,
          borderLeft: '5px ridge firebrick',
          boxShadow: 1,
          display: 'flex',
          backgroundColor: palette.background.paper,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
        onClick={() => setCalendarDialog(instances.map((instance) => instance.activityInstanceId))}
      >
        <Typography
          variant="body2"
          sx={{ lineHeight: '1.1', fontSize: '0.8rem', fontWeight: 'bold' }}
          ref={textRef}
        >
          {duration > 44 && <FilterNoneIcon />}
          (+{instances.length})
        </Typography>
      </Box>
    </>
  );
};

export default CalendarDayCluster;
