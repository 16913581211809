import SupervisorList from '@/components/Supervisor/SupervisorList';
import { Container, Stack, Typography } from '@mui/material';
import Greeting from '@/components/calendar/daily/Greeting';
import DisplayDate from '@/components/calendar/daily/DisplayDate';
import { useTranslation } from 'react-i18next';
const SelectClientPage = () => {
  const { t } = useTranslation();
  return (
    <Container sx={{ pb: 2, position: 'relative' }}>
      <Stack
        spacing={3}
        pb={3}
        pt={1}
        mb={2}
        sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}
      >
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Greeting />
          <DisplayDate />
        </Stack>
        <Typography variant="h6" component="h1" paragraph>
          {t('Select a client to supervise')}
        </Typography>
      </Stack>
      <SupervisorList />
      <Typography variant="caption">{t('You can turn off supervise mode in settings')}</Typography>
    </Container>
  );
};

export default SelectClientPage;
