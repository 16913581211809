import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { StatisticsContext } from 'src/contexts/StatisticsContext';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

const StatisticsHeader = () => {
  const { intervalType, setIntervalType } = useContext(StatisticsContext);

  const { t } = useTranslation();

  return (
    <Stack sx={{ width: '100%' }} alignItems={'center'}>
      <Stack direction={'row'} alignItems={'center'} py={2}>
        <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
          {t('Interval')}:
        </Typography>
        <FormControl variant="outlined" sx={{ m: 1 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={intervalType}
            onChange={(e: any) => setIntervalType(e.target.value)}
            autoWidth
            size="small"
          >
            <MenuItem value="day">{t('Day')}</MenuItem>
            <MenuItem value="week">{t('Week')}</MenuItem>
            <MenuItem value="month">{t('Month')}</MenuItem>
            <MenuItem value="year">{t('Year')}</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default StatisticsHeader;
