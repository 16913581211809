import { Box, Stack } from '@mui/material';
import TinyDayHeader from './TinyDayHeader';
import LargeDayHeader from './LargeDayHeader';
import { forwardRef } from 'react';

type props = {
  onDaySelect: (day: Date) => void;
  weekdays: Date[];
  renderDay: Date | null;
};
//A component that renders each day
const CalendarHeader = forwardRef<HTMLDivElement, props>(
  ({ onDaySelect, weekdays, renderDay }, ref) => {
    return (
      <Stack direction="row" sx={{ width: '100%', mb: 1, height: '4.5em' }} spacing={0} ref={ref}>
        <Box
          sx={{
            width: '2em', // width of timeline
            minWidth: '2em',
          }}
        />
        {renderDay ? (
          <LargeDayHeader onDaySelect={onDaySelect} date={renderDay}></LargeDayHeader>
        ) : (
          weekdays.map((day) => (
            <TinyDayHeader onDaySelect={onDaySelect} date={day} key={day.toString()} />
          ))
        )}
      </Stack>
    );
  },
);

export default CalendarHeader;
