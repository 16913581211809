/* tslint:disable */
/* eslint-disable */

import { RequestOpts, ResponseError, parseDates } from './runtime';

import axios, { AxiosError, AxiosResponse } from 'axios';

export const BASE_URL = 'https://api.todej.com';

let previousResponseWasAuthorized = false;
export class BaseAPI {
  protected async request(context: RequestOpts): Promise<AxiosResponse> {
    if (!context.headers) context.headers = {};
    if (!context.query) context.query = {};
    const response = await this.callApi(context).catch((error: AxiosError) => {
      console.warn(error);
      return error.response;
    });

    if (response && response.status >= 200 && response.status < 300) {
      if (!previousResponseWasAuthorized) previousResponseWasAuthorized = true;
      return response;
    }
    if (
      response &&
      (response.status === 403 || response.status === 401) &&
      context.path === '/home'
    ) {
      // CSRF preflight, return status 200
      const csrfToken = document.cookie.replace(
        /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
        '$1',
      );
      if (csrfToken) return { ...response, status: 200 };
    }

    if (response && response.status === 403 && previousResponseWasAuthorized) {
      window.location.href = '/login';
    }

    throw new ResponseError(response, 'Response returned an error code');
  }
  private async callApi(context: RequestOpts): Promise<AxiosResponse | undefined> {
    const axiosInstance = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });

    axiosInstance.interceptors.response.use((response) => {
      parseDates(response.data);
      return response;
    });

    let config: any = {
      withCredentials: true,
    };

    if (context.query) config = { ...config, params: context.query };
    if (context.headers) config = { ...config, headers: { ...context.headers } };

    switch (context.method) {
      case 'GET':
        return axiosInstance.get(context.path, config);
      case 'PUT':
        return axiosInstance.put(context.path, context.body, config);
      case 'POST':
        return axiosInstance.post(context.path, context.body, config);
      case 'DELETE':
        return axiosInstance.delete(context.path, config);
    }
    return undefined;
  }
}
