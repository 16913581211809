import { Typography, useTheme, Stack, Button } from '@mui/material';
import { getActivityColor } from '@/components/utils/CalendarFns';
import { useContext } from 'react';
import { ActivityContext } from '@/contexts/ActivityContext';
import { ActivityFormContext } from '@/contexts/ActivityFormContext';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dateLocales } from '@/i18n/dateLocales';
import { Suggestion } from '@/api';

type SuggestionCardProps = {
  suggestion: Suggestion;
};

const SuggestionCard = ({ suggestion }: SuggestionCardProps) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const { palette } = useTheme();
  const activityColor = getActivityColor(palette, suggestion.duration);
  const formHandler = useContext(ActivityFormContext);
  const { activity, setActivity } = useContext(ActivityContext);
  const suggestionDate = new Date(suggestion.startDate);

  const selectSuggestion = () => {
    setActivity({
      ...activity,
      activityName: suggestion.activityName,
      startDate: new Date(suggestion.startDate),
      duration: suggestion.duration,
      description: suggestion.description,
      subtasks: suggestion.subtasks,
    });
    formHandler.setValid(true);
    formHandler.goToNextForm();
  };

  return (
    <Button
      sx={{
        backgroundColor: palette.background.paper,
        border: '1px solid lightgray',
        borderLeft: `10px solid ${activityColor}`,
        borderRadius: '8px',
        boxShadow1: 1,
        mt: 2,
        textAlign: 'left',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
      onClick={() => {
        selectSuggestion();
      }}
    >
      <Stack px={2} py={1.5}>
        <Typography variant="h6" fontWeight={'bold'}>
          {suggestion.activityName}
        </Typography>

        <Typography variant="body2" fontWeight={80} sx={{ textTransform: 'capitalize' }}>
          {format(suggestionDate, 'EEEE HH:mm-', { locale: dateLocales[lang] })}
          {format(new Date(suggestionDate.getTime() + suggestion.duration * 60000), 'HH:mm', {
            locale: dateLocales[lang],
          })}
        </Typography>
        <Typography variant="body2">{suggestion.description}</Typography>
      </Stack>
    </Button>
  );
};

export default SuggestionCard;
