import { Stack, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageSelect from './LangSelect';

const LoginHeader = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'sticky',
        top: 0,
        py: 1,
        px: 2,
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
      }}
    >
      <Stack width={'100%'} maxWidth={'70em'} justifyContent={'space-between'} direction={'row'}>
        <LanguageSelect />
        <Button variant="contained" href="/login" sx={{ my: 0.5, px: 2 }}>
          {t('Login')}
        </Button>
      </Stack>
    </Box>
  );
};

export default LoginHeader;
