import { Box } from '@mui/material';
import { useRef } from 'react';
import TimeIntervalRenderer from '../TimeIntervalRenderer';
import TimelineTime from './TimelineTime';

//A component that renders each day
const Timeline = () => {
  const calendarRef = useRef(null);

  return (
    <Box sx={{ height: '100%', position: 'relative', width: '1.25em' }} ref={calendarRef}>
      <TimeIntervalRenderer times={24} parentRef={calendarRef}>
        <TimelineTime />
      </TimeIntervalRenderer>
    </Box>
  );
};

export default Timeline;
