import CircleIcon from '@mui/icons-material/Circle';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useContext } from 'react';
import { TimerManagerContext } from '@/contexts/TimerManagerContext';

//A component that renders each day
const TimelineDotIcon = ({ dotsPerHour, index }: { dotsPerHour: number; index?: number }) => {
  const theme = useTheme();
  const [state, setState] = useState<'Completed' | 'Active' | 'Upcoming'>('Upcoming');
  const { renderTime } = useContext(TimerManagerContext);
  const fontSize = index && index % dotsPerHour === 0 ? '14px' : '11px';

  //SHould be active if current time is equal or greater than the dots time, calculated by index
  const calculateState = () => {
    if (index !== undefined) {
      // We show 5 dots per hour, so we need to convert the index to minutes
      const dotMinutes = (index * 60) / dotsPerHour;

      const currentTimeDistance = renderTime.getHours() * 60 + renderTime.getMinutes();

      const minutesBetweenDots = 60 / dotsPerHour;

      const activeOffset = minutesBetweenDots / 2;
      if (
        currentTimeDistance >= dotMinutes - activeOffset &&
        currentTimeDistance <= dotMinutes + activeOffset
      ) {
        setState('Active');
      } else if (currentTimeDistance >= dotMinutes) {
        setState('Completed');
      } else {
        setState('Upcoming');
      }
    }
  };

  useEffect(() => {
    calculateState();
  }, [renderTime]);

  if (index || index === 0)
    return (
      <>
        {state === 'Active' ? (
          <CircleIcon sx={{ fontSize: fontSize, color: theme.palette.success.main }} />
        ) : state === 'Completed' ? (
          <PanoramaFishEyeIcon sx={{ fontSize: fontSize, color: theme.palette.primary.main }} />
        ) : (
          <CircleIcon sx={{ fontSize: fontSize, color: theme.palette.primary.main }} />
        )}
      </>
    );
  return <></>;
};

export default TimelineDotIcon;
