import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

const muiAccordionTextToTop = {
  '&.Mui-expanded': {
    minHeight: '48px',
  },
  // Text to top in closed state
  '& .MuiAccordionSummary-content': {
    mt: 1,
    mb: 1,
  },
  // Text to top in open state
  '&.Mui-expanded .MuiAccordionSummary-content': {
    mt: 1,
    mb: 1,
  },
};

const MoreOptionsAccordion = ({
  children,
  headerText,
  headerIcon,
}: {
  children: ReactNode;
  headerText: string;
  headerIcon: ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Accordion
      sx={{
        ml: 1,
        my: 1,
        borderRadius: '5px',
        boxShadow: 2,
        borderLeft: `6px solid ${theme.palette.primary.main}`,
        '&.Mui-expanded': {
          ml: 1,
          my: 1,
          py: 0,
        },
        '&:before': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={muiAccordionTextToTop}
      >
        {headerIcon}
        <Typography sx={{ width: '100%' }}>{headerText}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          pl: 3,
          pt: 0,
          mb: 0,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default MoreOptionsAccordion;
