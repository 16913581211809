import { AuthApi } from '@api';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { createElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BasicDialog, { BasicDialogContent } from '../utils/dialogs/BasicDialog';
import { useNavigate } from 'react-router-dom';
import { SettingsConfig, settingsConfig } from './SettingsAccordionConfig';
import { SupervisorContext } from 'src/contexts/SupervisorContext';

const SettingsAccordion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSupervising } = useContext(SupervisorContext);

  const [dialog, setDialog] = useState<BasicDialogContent | false>(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (setting: SettingsConfig) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (setting.dialog) setDialog(setting.dialog);
      setExpanded(isExpanded ? setting.id : false);
    };

  const showSetting = (setting: SettingsConfig) => {
    if (setting.disabled) return false;
    if (setting.onlyAsSupervisor && !isSupervising) return false;
    if (setting.hideAsSupervisor && isSupervising) return false;
    return true;
  };

  const reloadApp = () => {
    navigate('/login');
    window.location.reload();
  };

  const handleLogout = async () => {
    await new AuthApi().logout();

    reloadApp();
  };

  const handleRemoveAccount = async () => {
    const res = await new AuthApi().deleteAccount();
    if (res) {
      reloadApp();
    }
  };

  const onDialogConfirm = () => {
    if (dialog && dialog.title === 'Logout') handleLogout();
    if (dialog && dialog.title === 'Delete account') handleRemoveAccount();
  };

  return (
    <div>
      {settingsConfig.map((setting, index) => (
        <Accordion
          expanded={expanded === setting.id}
          onChange={handleChange(setting)}
          key={`settingsAccordion item ${index + 1}`}
          sx={showSetting(setting) ? {} : { display: 'none' }}
        >
          {setting.accordionHeader ? (
            <Box>{createElement(setting.accordionHeader, setting)}</Box>
          ) : (
            <AccordionSummary>
              <Stack
                justifyContent={'space-between'}
                direction={'row'}
                width={'100%'}
                alignItems={'center'}
              >
                <Typography variant="h6" color={setting.titleColor ?? 'primary'}>
                  {t(setting.title)}
                </Typography>
                {setting.icon ? <>{createElement(setting.icon)}</> : null}
                {setting.accordionContent && !setting.icon ? (
                  <ExpandMore
                    sx={{
                      transform: expanded === setting.id ? 'rotate(180deg)' : 'none',
                    }}
                  />
                ) : null}
              </Stack>
            </AccordionSummary>
          )}
          {setting.accordionContent ? (
            <AccordionDetails>{createElement(setting.accordionContent)}</AccordionDetails>
          ) : null}
        </Accordion>
      ))}
      <BasicDialog
        open={!!dialog}
        onClose={() => setDialog(false)}
        onConfirm={onDialogConfirm}
        content={dialog ? dialog : ({} as BasicDialogContent)}
      />
    </div>
  );
};

export default SettingsAccordion;
