import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dateLocales } from '@/i18n/dateLocales';
import ColorUnderline from '../ColorUnderline';

const DisplayDate = () => {
  const today = new Date();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];

  return (
    <Stack justifyContent={'space-between'} sx={{ position: 'relative' }}>
      <Typography variant="subtitle2" textAlign={'end'}>
        {format(today, `do '${t('date.of')} 'MMMM`, { locale: dateLocales[lang] })}
      </Typography>
      <Typography
        variant="h5"
        fontWeight={'bold'}
        textAlign={'end'}
        sx={{ textTransform: 'capitalize' }}
      >
        {format(today, 'EEEE', { locale: dateLocales[lang] })}
      </Typography>
      <ColorUnderline day={format(today, 'EEEE')} />
    </Stack>
  );
};

export default DisplayDate;
