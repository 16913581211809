import { Recurrence, SubtaskItem } from '@api';
import { TFunction } from 'i18next';
export const invalidCharacters = /[<>;'"/\\[\]{}_\\&*@^:=!|+?#%~]/;

export const validateName = (name: string, errorOnEmpty: boolean, t: TFunction): null | string => {
  if (errorOnEmpty && name.trim().length === 0) {
    return t('Name cannot be empty');
  }
  if (name.length > 64) {
    return t('Name is too long');
  }
  const errorCharacters = name.match(invalidCharacters);
  if (errorCharacters) {
    return t('Name contains invalid character') + " '" + errorCharacters + "'";
  }
  return null;
};

export const validateDescription = (description: string, t: TFunction): null | string => {
  const errorCharacters = description.match(invalidCharacters);
  if (errorCharacters) {
    return t('Description contains invalid character') + " '" + errorCharacters + "'";
  }
  return null;
};

export const validateRecurrence = (
  recurringActivity: Recurrence | null,
  t: TFunction,
): null | string => {
  if (recurringActivity && recurringActivity.recurringEndDate) {
    if (recurringActivity.recurringTimes && recurringActivity.recurringTimes.length > 0) {
      const emptyTime = recurringActivity.recurringTimes.some(
        (e) => e === null || e === undefined || e === '',
      );
      if (emptyTime) return t('Time cannot be empty');
    }
    return null;
  }
  return t('End date is required');
};

export const validateSubtasks = (subtasks: SubtaskItem[], t: TFunction): null | string => {
  const erorItem = subtasks.some(
    (e: SubtaskItem) => validateSubtaskItem(e.subtaskText, t) !== null,
  );
  if (erorItem) return t('One or more subtasks are invalid');
  return null;
};

export const validateSubtaskItem = (subtask: string, t: TFunction): null | string => {
  if (subtask === '') return t('Item cannot be empty');
  const errorCharacters = subtask.match(invalidCharacters);
  if (errorCharacters) return t('Item contains invalid character') + " '" + errorCharacters + "'";
  return null;
};
