import { Box, Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

const AddActivityButton = () => {
  const { t } = useTranslation();
  const clearForm = () => {
    sessionStorage.removeItem('formActivity');
  };
  return (
    <Button component={Link} to="/create-activity/Title" sx={{ p: 0 }} onClick={clearForm}>
      <Stack sx={{ borderRadius: '1em' }} justifyContent={'center'} textAlign={'center'}>
        <Box p={0.5}>
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: '50%', minWidth: 'auto', p: 0.5 }}
            aria-label="Add new activity"
          >
            <AddIcon sx={{ color: 'line' }} fontSize={'large'}></AddIcon>
          </Button>
        </Box>
        <Typography variant="subtitle2" p={0.5}>
          {t('Add new activity')}
        </Typography>
      </Stack>
    </Button>
  );
};

export default AddActivityButton;
