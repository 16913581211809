/*
  TODO: Change input to custom input when implemented
*/
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { useState, useContext, useEffect, useRef } from 'react';
import { CalendarContext } from '@/contexts/CalendarContext';
import { useTranslation } from 'react-i18next';
import ScrollPicker from '@/components/scrollPicker/ScrollPicker';
import { Stack } from '@mui/material';

const getDefaultLocalValue = ({ year, week }: { year: number; week: number }) => {
  const newYear = year || new Date().getFullYear();
  const newWeek = week.toString().padStart(2, '0') || '01';
  return { year: newYear, week: newWeek };
};

const WeekSelectDialog = ({ onClose, open }: { onClose: () => void; open: boolean }) => {
  const { selectedWeek, setSelectedWeek } = useContext(CalendarContext);
  const localWeek = useRef(getDefaultLocalValue(selectedWeek));

  const { t } = useTranslation();

  const [yearsArray] = useState<Array<number>>(
    Array.from({ length: 100 }, (_, index) => index + 2000),
  );
  const [weeksArray] = useState<Array<string>>(
    Array.from({ length: 52 }, (_, index) => (index + 1).toString().padStart(2, '0')),
  );

  // Convert weekString to week object and set current week
  const onSelect = () => {
    if (!localWeek.current.year || !localWeek.current.week) return;

    setSelectedWeek({
      year: localWeek.current.year,
      week:
        typeof localWeek.current.week === 'string'
          ? parseInt(localWeek.current.week, 10)
          : localWeek.current.week,
    });
    onClose();
  };

  const setYear = (year: string | number) => {
    if (typeof year === 'string') {
      localWeek.current.year = Number.parseInt(year, 10);
    } else {
      localWeek.current.year = year;
    }
  };

  const setWeek = (week: string | number) => {
    if (typeof week === 'string') {
      localWeek.current.week = week;
    } else {
      localWeek.current.week = week.toString().padStart(2, '0');
    }
  };

  useEffect(() => {
    localWeek.current = getDefaultLocalValue(selectedWeek);
  }, [selectedWeek]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>{t('Select week')}</DialogTitle>
      <DialogContent>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography>{t('Year')}</Typography>
            <ScrollPicker
              value={localWeek.current.year}
              items={yearsArray}
              onChange={setYear}
              sx={{ px: 0.25, fontSize: '1.25em' }}
            />
          </Stack>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography>{t('Week')}</Typography>
            <ScrollPicker
              value={localWeek.current.week}
              items={weeksArray}
              onChange={setWeek}
              sx={{ px: 0.25, fontSize: '1.25em' }}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('Cancel')}</Button>
        <Button onClick={onSelect}>{t('Select')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WeekSelectDialog;
