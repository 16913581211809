import { dateLocales } from '@/i18n/dateLocales';
import { Stack, Typography, IconButton } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useTheme } from '@mui/material/styles';

type props = { onDaySelect: (day: Date) => void; date: Date };

const LargeDayHeader = ({ onDaySelect, date }: props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const theme = useTheme();
  const today = new Date();

  const day = format(date, 'EEEE').toLowerCase();

  const dayColor = (theme.palette.weekdays as any)[day].main;
  const dayBorder = (theme.palette.weekdays as any)[day].border;

  //Check date, month and year
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  return (
    <Stack
      sx={{
        backgroundColor: dayColor,
        borderRadius: 3,
        border: dayBorder,
        margin: 0,
        height: '4em',
        marginBottom: '0.5em',
        width: '10px', //Placeholder, they will flex-grow to fill the remaining space
        flexGrow: 1,
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <IconButton
        onClick={() => {
          const nextDay = new Date(date);
          nextDay.setDate(nextDay.getDate() - 1);
          onDaySelect(nextDay);
        }}
      >
        <NavigateBeforeIcon fontSize="large" />
      </IconButton>
      <Stack direction="column" alignItems="center" justifyContent="center" height="100%">
        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: 1.25 }}>
          {format(date, `do '${t('date.of')} 'MMMM`, { locale: dateLocales[lang] })}
        </Typography>
        <Typography
          variant="body2"
          sx={{ textTransform: 'capitalize', fontWeight: isToday ? 'bold' : 'normal' }}
        >
          {format(date, 'EEEE', { locale: dateLocales[lang] })}
        </Typography>
      </Stack>
      <IconButton
        onClick={() => {
          const nextDay = new Date(date);
          nextDay.setDate(nextDay.getDate() + 1);
          onDaySelect(nextDay);
        }}
      >
        <NavigateNextIcon fontSize="large" />
      </IconButton>
    </Stack>
  );
};

export default LargeDayHeader;
