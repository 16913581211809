import { useContext, useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ActivityContext } from '@/contexts/ActivityContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InstanceContext } from '@/contexts/InstanceContext';

const EditAction = () => {
  const { activity } = useContext(ActivityContext);
  const { instance, isStarted } = useContext(InstanceContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onEditClick = () => {
    if (activity.recurringActivity) {
      setDialogOpen(true);
    } else {
      onEditActivity();
    }
  };

  const clearForm = () => {
    sessionStorage.removeItem('formActivity');
  };

  const onEditActivity = () => {
    clearForm();
    navigate(`/edit-activity/${instance.activityInstanceId}/multi/Edit`);
  };

  const onEditSingleActivity = () => {
    clearForm();
    navigate(`/edit-activity/${instance.activityInstanceId}/single/Edit`);
  };

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          padding: 1,
          bgcolor: 'line',
          right: 0,
          top: 0,
          bottom: 0,
          display: isStarted ? 'none' : 'block',
        }}
        onClick={onEditClick}
      >
        <EditIcon />
      </IconButton>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t('Edit Activity')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              'This activity is recurring. Do you want to edit the whole series or just this activity?',
            )}
          </DialogContentText>
        </DialogContent>
        <Stack direction="column" spacing={2} px={3} pb={2}>
          <Button variant="contained" onClick={onEditActivity} autoFocus>
            {t('Edit series')}
          </Button>
          <Button variant="contained" onClick={onEditSingleActivity}>
            {t('Edit this activity')}
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default EditAction;
