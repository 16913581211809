import { ReactNode } from 'react';
import styled from '@emotion/styled';

const colors = ['#eb2c3a', '#ee8130', '#fce92b', '#91bd49', '#009f54'];

const BadgeSpan = styled.span({
  position: 'relative',
  display: 'block',
  width: '30px',
  height: '30px',
  left: '26px',
  top: '16px',
  borderRadius: '50%',
  border: '2px solid white',
});

const Text = styled.div({
  position: 'absolute',
  color: 'white',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const Badge = ({ children, mood, text }: { children: ReactNode; mood: number; text: string }) => {
  return (
    <div>
      <BadgeSpan style={{ backgroundColor: colors[mood - 1] }}>
        <Text>{text}</Text>
      </BadgeSpan>
      {children}
    </div>
  );
};

export default Badge;
