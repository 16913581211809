import { Box, Container, Stack } from '@mui/material';
import { ActivityContext } from '@/contexts/ActivityContext';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '@/contexts/AppContext';
import InstanceAndActivityProvider, { EditActivityParams } from './InstanceAndActivityProvider';
import ActivityFormScene, {
  DefaultActivityFormsDefs,
  ActivityFormContext,
  ActivityForms,
} from '@/contexts/ActivityFormContext';
import NextButton from '@/components/activity/createActivity/NextButton';
import ErrorView from '@/components/utils/ErrorView';
import { useTranslation } from 'react-i18next';
import { SupervisorContext } from 'src/contexts/SupervisorContext';
import { useParams } from 'react-router-dom';

const FormContent = () => {
  const { current } = useContext(ActivityFormContext);

  return (
    <Container sx={{ paddingTop: 3 }}>
      <Stack justifyContent={'center'}>{React.createElement(current.component)}</Stack>
    </Container>
  );
};

const EditActivity = () => {
  const { activity } = useContext(ActivityContext);
  const { supervisorHeaderHeight } = useContext(SupervisorContext);
  const { pageHeight } = useContext(AppContext);
  const { editSingleActivity } = useParams<EditActivityParams>();
  const { t } = useTranslation();
  // If the activityId is 0, then the activity could not be found
  const defaultActivityId = 0;
  const isEditingSingleActivity = editSingleActivity === 'single';

  const getOverrideConfig = () => {
    return {
      ...DefaultActivityFormsDefs,
      SetRecurrence: {
        ...DefaultActivityFormsDefs.SetRecurrence,
      },
      AddSubtasks: {
        ...DefaultActivityFormsDefs.AddSubtasks,
      },
      Edit: {
        ...DefaultActivityFormsDefs.Edit,
        nextFormAction: isEditingSingleActivity
          ? 'EditSingleActivity'
          : DefaultActivityFormsDefs.Edit.nextFormAction,
      },
    };
  };

  return (
    <>
      {activity.activityId ? (
        <Stack sx={{ minHeight: `calc(${pageHeight}px - ${supervisorHeaderHeight}px)` }}>
          <ActivityFormScene
            initialForm={ActivityForms.Edit}
            formOverrideConfig={getOverrideConfig()}
          >
            <Box
              sx={{
                minHeight: `calc(${pageHeight}px - ${supervisorHeaderHeight}px)`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormContent></FormContent>
              <Box id="divider" sx={{ flex: 1 }} />
              <NextButton />
            </Box>
          </ActivityFormScene>
        </Stack>
      ) : (
        activity.activityId === defaultActivityId && (
          <ErrorView error={t('Activity could not be found')} />
        )
      )}
    </>
  );
};

const EditActivityPage = () => {
  const { setShowNavbar } = useContext(AppContext);

  useEffect(() => {
    setShowNavbar(false);
    return () => setShowNavbar(true);
  }, []);

  return (
    <InstanceAndActivityProvider useStoredActivity>
      <EditActivity></EditActivity>
    </InstanceAndActivityProvider>
  );
};
export default EditActivityPage;
