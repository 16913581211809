import { StatisticsScene, StatisticsContext } from '@/contexts/StatisticsContext';
import AISummary from './AISummary';
import OverviewStat from './OverviewStat';
import LineChart from '@/components/statistics/LineChart';
import HalfDonutGraph from '@/components/statistics/HalfDonutChart';
import Paper from '@mui/material/Paper';
import RatedFeeling from './RatedCard';
import WeekDayAverage from './WeekDayAverage';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PixelView from '@/components/statistics/PixelView';

const StatisticsWrapper = () => {
  const { intervalType, data } = useContext(StatisticsContext);
  const { t } = useTranslation();

  return (
    <Paper
      sx={() => ({
        padding: '10px',
      })}
    >
      <AISummary />
      <OverviewStat />
      <LineChart />
      {/* <BarChartRating /> */}
      <HalfDonutGraph />
      {intervalType !== 'year' && (
        <>
          <RatedFeeling
            rated={data?.oneRatedActivities?.amountOfOneRatedActivities}
            previous={data?.oneRatedActivities?.comparedToLastInterval}
            text={t('activities were rated very poor')}
            image="/emotions/1.png"
          />
          <RatedFeeling
            rated={data?.fiveRatedActivities?.amountOfFiveRatedActivities}
            previous={data?.fiveRatedActivities?.comparedToLastInterval}
            text={t('activities were rated very good')}
            image="/emotions/5.png"
          />
          <RatedFeeling
            rated={data?.notCompletedActivities?.amountOfNotCompletedActivities}
            previous={data?.notCompletedActivities?.comparedToLastInterval}
            text={t('activities were missed')}
            image="/icons/exclamation-mark.png"
          />
        </>
      )}
      {(intervalType === 'week' || intervalType === 'month') && (
        <>
          <WeekDayAverage
            weekday={data?.highestRatedWeekDay?.weekDay}
            rated={data?.highestRatedWeekDay?.averageFeelingOfActivities}
            activities={data?.highestRatedWeekDay?.amountOfActivities}
            text={t('had the best average rating')}
            image="/emotions/4.png"
          />
          <WeekDayAverage
            weekday={data?.lowestRatedWeekDay?.weekDay}
            rated={data?.lowestRatedWeekDay?.averageFeelingOfActivities}
            activities={data?.lowestRatedWeekDay?.amountOfActivities}
            text={t('had the best average rating')}
            image="/emotions/2.png"
          />
        </>
      )}
      {intervalType === 'year' && <PixelView />}
    </Paper>
  );
};

const StatisticsPage = () => {
  return (
    <StatisticsScene>
      <StatisticsWrapper />
    </StatisticsScene>
  );
};

export default StatisticsPage;
