import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  SxProps,
  Stack,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollTimePicker from '../scrollPicker/ScrollTimePicker';
import { format } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import { useTheme } from '@mui/material/styles';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';

const getDefaultTimePicker = () => {
  const timePicker = localStorage.getItem('timePicker');
  if (timePicker === 'scroll' || timePicker === 'clock') return timePicker;
  return 'scroll';
};

const TimeInput = ({
  value,
  onChange,
  disabled = false,
  sx = {},
}: {
  value: Date | null;
  onChange?: (newDate: Date) => void;
  disabled?: boolean;
  sx?: SxProps;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [timePickerVariant, setTimePickerVariant] = useState<'clock' | 'scroll'>(
    getDefaultTimePicker(),
  );

  const setNewTimePickerVariant = (newVariant: 'clock' | 'scroll') => {
    setTimePickerVariant(newVariant);
    localStorage.setItem('timePicker', newVariant);
  };

  const [localValue, setLocalValue] = useState<Date | null>(value ? new Date(value) : null);

  const { palette } = useTheme();

  const handleClickOpen = () => {
    if (!disabled) setOpen(true);
  };

  const handleClose = () => {
    if (value) setLocalValue(new Date(value));
    else setLocalValue(null);
    setOpen(false);
  };

  const handleOk = () => {
    if (onChange && localValue) onChange(localValue);
    setOpen(false);
  };

  useEffect(() => {
    if (value) setLocalValue(new Date(value));
    else setLocalValue(null);
  }, [value]);

  const onSetTime = (date: Date | null) => {
    if (date) setLocalValue(new Date(date));
  };

  const dateValue = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) return format(date, 'HH:mm');

    return '';
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        sx={timePickerVariant === 'clock' ? { '& .MuiDialog-paper': { minWidth: '320px' } } : {}}
      >
        <Stack direction="row" justifyContent="space-between">
          <DialogTitle>{t('Select time')}</DialogTitle>
          <ButtonGroup sx={{ pr: 2 }}>
            <IconButton onClick={() => setNewTimePickerVariant('clock')}>
              <AccessTimeIcon
                sx={{ color: timePickerVariant === 'clock' ? palette.primary.main : 'lightgray' }}
              />
            </IconButton>
            <IconButton onClick={() => setNewTimePickerVariant('scroll')}>
              <SwipeVerticalIcon
                sx={{ color: timePickerVariant === 'scroll' ? palette.primary.main : 'lightgray' }}
              />
            </IconButton>
          </ButtonGroup>
        </Stack>
        <DialogContent sx={{ p: 0 }}>
          {timePickerVariant === 'clock' ? (
            <StaticTimePicker
              value={localValue}
              onChange={onSetTime}
              slotProps={{ actionBar: { actions: [] } }}
              displayStaticWrapperAs="mobile"
            />
          ) : (
            <ScrollTimePicker value={localValue} onChange={onSetTime} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleOk}>{t('Ok')}</Button>
        </DialogActions>
      </Dialog>
      <TextField
        id="time-input"
        placeholder="hh:mm"
        value={dateValue(value)}
        InputProps={{
          readOnly: true,
        }}
        onClick={handleClickOpen}
        disabled={disabled}
        sx={{ ...sx }}
      />
    </>
  );
};

export default TimeInput;
