import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SimpleList from '../utils/list/SimpleList';
import Delete from '@mui/icons-material/Delete';
import { useContext, useState } from 'react';
import { Client, SupervisorApi, SupervisorConnection } from '@api';
import { SupervisorContext } from 'src/contexts/SupervisorContext';
import BasicDialog from '../utils/dialogs/BasicDialog';
import { AppContext } from 'src/contexts/AppContext';
import MaxClientsDialog from './MaxClientsDialog';

const ManageClientsSetting = () => {
  const { t } = useTranslation();

  const { clients, supervisorRequests, refresh } = useContext(SupervisorContext);
  const { user } = useContext(AppContext);
  const [showMaxClientsDialog, setShowMaxClientsDialog] = useState(false);
  const [removeClientDialog, setRemoveClientDialog] = useState<undefined | Client>();
  const incomingRequests = supervisorRequests?.filter(
    (request) => request.supervisor.tag === user?.tag,
  );

  const acceptSupervisingRequest = async (request: SupervisorConnection) => {
    if (clients.length >= 4 || user?.isPremium) {
      setShowMaxClientsDialog(true);
      return;
    }
    const res = await new SupervisorApi().acceptClient({ client: request.client });
    if (res) refresh();
  };
  const declineSupervisingRequest = async (request: SupervisorConnection) => {
    const res = await new SupervisorApi().declineClient({ client: request.client });
    if (res) refresh();
  };

  const removeClient = async (client?: Client) => {
    if (client) {
      const res = await new SupervisorApi().removeClient({ client: client });
      if (res) refresh();
    }
  };

  return (
    <Stack spacing={3}>
      <Typography variant="subtitle2">{t('Information for supervisor')}</Typography>
      {incomingRequests?.length > 0 ? (
        <>
          {incomingRequests?.map((request: SupervisorConnection, index) => (
            <Stack spacing={2} key={`supervisingRequest-${index}`}>
              <Typography variant="subtitle1">
                {t('Supervise request from')}: {request.client.tag}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => declineSupervisingRequest(request)}
                >
                  {t('Decline')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => acceptSupervisingRequest(request)}
                >
                  {t('Accept')}
                </Button>
              </Stack>
            </Stack>
          ))}
        </>
      ) : null}
      <BasicDialog
        open={!!removeClientDialog}
        content={{
          title: t('Remove client'),
          body: `${removeClientDialog?.tag}: ${t('Will be removed as your client')}`,
          confirmText: t('Remove'),
          cancelText: t('Cancel'),
        }}
        onClose={() => setRemoveClientDialog(undefined)}
        onConfirm={() => removeClient(removeClientDialog)}
      ></BasicDialog>
      <MaxClientsDialog
        open={showMaxClientsDialog}
        onClose={() => setShowMaxClientsDialog(false)}
      />
      <SimpleList
        items={clients.map((client) => client.tag)}
        icon={<Delete color={'error'} />}
        onIconClick={(tag) => setRemoveClientDialog(clients.find((client) => client.tag === tag))}
      />
    </Stack>
  );
};

export default ManageClientsSetting;
