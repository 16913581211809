import { ActivityInstance } from '@api';
import { RefObject } from 'react';

export const minActivityRenderHeight = 10; // The minimum height an activity can be rendered at
const minFontSize = 8; // Minimum font size in pixels

export const activitiesIntersect = (
  activity1: ActivityInstance,
  activity2: ActivityInstance,
): boolean => {
  const activity1End = new Date(
    activity1.startDate.getTime() +
      Math.max(activity1.renderDuration || 0, minActivityRenderHeight) * 60000,
  );
  const activity2End = new Date(
    activity2.startDate.getTime() +
      Math.max(activity2.renderDuration || 0, minActivityRenderHeight) * 60000,
  );

  return activity1.startDate < activity2End && activity1End > activity2.startDate;
};

/* 
  Activities might span multiple days, so we need to break them up into multiple instances.
  If they start before current day, we need to insert the remaining duration into the current day.
  If they end after current day, we should end the activity at the end of the current day.
*/
export const breakActivities = (activities: ActivityInstance[], date: Date): ActivityInstance[] => {
  const newActivities: ActivityInstance[] = [];

  activities.forEach((activity) => {
    const activityEnd = new Date(
      activity.startDate.getTime() + Math.max(activity.duration, minActivityRenderHeight) * 60000,
    );

    if (activity.startDate >= date && activityEnd < new Date(date.getTime() + 86400000)) {
      activity.renderDuration = activity.duration;
      newActivities.push(activity);
    } else if (activity.startDate < date) {
      // Activity started on the day before, so set starttime to current day and calculate new duration
      const newDuration = Math.floor((activityEnd.getTime() - date.getTime()) / 60000);
      const newActivity = { ...activity, renderDuration: newDuration, startDate: date };
      if (newDuration > 0) newActivities.push(newActivity);
    } else {
      // Activity starts on current day, but spans to the next day. Calculate duration to midnight
      const newDuration = Math.floor(
        (date.getTime() + 86400000 - activity.startDate.getTime()) / 60000,
      );
      const newActivity = { ...activity, renderDuration: newDuration };
      newActivities.push(newActivity);
    }
  });

  return newActivities;
};

export const clusterActivities = (activities: ActivityInstance[]): ActivityInstance[][] => {
  const clusters: ActivityInstance[][] = [];

  activities.forEach((activity) => {
    // eslint-disable-next-line functional/no-let
    let intersects = false;
    clusters.forEach((cluster) => {
      if (cluster.some((a) => activitiesIntersect(a, activity))) {
        cluster.push(activity);
        intersects = true;
        return true; // Early termination
      }
      return false;
    });

    if (!intersects) {
      clusters.push([activity]);
    }
  });

  return clusters;
};

export const generateColumns = (cluster: ActivityInstance[]): ActivityInstance[][] => {
  const columns: ActivityInstance[][] = [];

  if (cluster.length === 1) {
    columns.push(cluster);
    return columns;
  }

  cluster.forEach((activity) => {
    // eslint-disable-next-line functional/no-let
    let added = false;
    columns.forEach((column) => {
      if (!added && !column.some((a) => activitiesIntersect(a, activity))) {
        column.push(activity);
        added = true;
        return true; // Early termination
      }
      return false;
    });

    if (!added) {
      columns.push([activity]);
    }
  });

  //We can remove culmuns with no activities
  const filteredColumns = columns.filter((column) => column.length > 0);
  return filteredColumns;
};

export const resizeTextVertically = (
  textRef: RefObject<HTMLDivElement>,
  parentHeight: number,
  originalLineHeight: number,
  minLineHeight: number,
) => {
  if (textRef.current === null) return;

  const text = textRef.current;
  if (text.clientHeight > parentHeight) {
    const fontSize = parseFloat(window.getComputedStyle(text).fontSize);

    // Try make the text fit by decreasing the line height
    const desiredLineHeight = Math.min(originalLineHeight, parentHeight / text.clientHeight);
    const newLineHeight = Math.max(desiredLineHeight, minLineHeight);
    text.style.lineHeight = newLineHeight.toString();

    if (newLineHeight === minLineHeight) {
      // If the line height is already at the minimum, decrease the font size instead
      const desiredFontSize = fontSize * (parentHeight / text.clientHeight);
      const newFontSize = Math.floor(desiredFontSize);
      if (newFontSize < minFontSize) {
        text.style.display = 'none'; // Hide the text if the font size is too small
        return;
      }
      text.style.fontSize = Math.floor(desiredFontSize).toString() + 'px';
    }
  }
};

export const resizeTextHorizontally = (textRef: RefObject<HTMLDivElement>): boolean => {
  if (textRef.current === null) return false;
  const text = textRef.current;
  const parent = text.parentElement;
  if (parent === null) return false;
  const textScrollWidth = text.scrollWidth;
  const parentWidth = parent.offsetWidth;

  // Proportion of font size reduction to achieve better fit within the parent element's width
  const scaleFactor = 0.8;

  const initialFontSize = parseFloat(window.getComputedStyle(text).fontSize);

  if (textScrollWidth <= parentWidth) return true; // If the text fits, return true
  const calculatedFontSize = (parentWidth / textScrollWidth) * initialFontSize * scaleFactor;

  if (calculatedFontSize < minFontSize) {
    text.style.display = 'none'; // Hide the text if the font size is too small
    return false;
  }
  text.style.fontSize = `${calculatedFontSize}px`; // Set the adjusted font size
  return true;
};

export const getActivityColor = (palette: any, duration: number) => {
  switch (duration) {
    case 0:
      return palette.activities.yellow;
    case 15:
      return palette.activities.green;
    case 30:
      return palette.activities.purple;
    case 45:
      return palette.activities.blue;
    case 60:
      return palette.activities.red;
    default:
      return palette.activities.turquoise;
  }
};
