import { Box } from '@mui/material';
import { createPortal } from 'react-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext } from 'react';
import { AppContext } from 'src/contexts/AppContext';

type DeleteAreaProps = {
  isDeleting: boolean;
  visible: boolean;
};

const DeleteArea = ({ isDeleting, visible }: DeleteAreaProps) => {
  const { navbarRef } = useContext(AppContext);

  return (
    <>
      {createPortal(
        <Box
          sx={{
            zIndex: 100,
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: navbarRef?.current ? '100%' : '10%',
            width: '100%',
            backgroundColor: isDeleting ? '#8c0000' : '#db0000',
            opacity: 0.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'clip-path 0.1s ease-out',
            clipPath: visible
              ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
              : 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            color: 'white',
          }}
        >
          <DeleteIcon fontSize="large" />
        </Box>,
        navbarRef?.current ?? document.body,
      )}
    </>
  );
};

export default DeleteArea;
