import { Stack, Button } from '@mui/material';
import { useContext, useState } from 'react';
import { InstanceContext } from '@/contexts/InstanceContext';
import { useTranslation } from 'react-i18next';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DoneIcon from '@mui/icons-material/Done';
import ActivityCompletedDialog from './ActivityCompletedDialog';
import { SupervisorContext } from 'src/contexts/SupervisorContext';

const ActivityActions = () => {
  const { startInstance, isStarted, completeInstance, instance } = useContext(InstanceContext);
  const { isSupervising } = useContext(SupervisorContext);
  const { t } = useTranslation();

  const [showCompleteDialog, setShowCompleteDialog] = useState(false);

  const onStartClick = () => {
    startInstance();
  };

  const complete = async () => {
    await completeInstance();
    setShowCompleteDialog(true);
  };

  return (
    <>
      <Stack direction="row" spacing={4} justifyContent="center" my={3}>
        {!isStarted ? (
          <Button
            sx={{ px: 6 }}
            variant="contained"
            color="primary"
            onClick={onStartClick}
            startIcon={<PlayArrowIcon />}
            disabled={isSupervising}
          >
            {t('Start Activity')}
          </Button>
        ) : !instance.dateIsCompleted ? (
          <Button
            sx={{ px: 4 }}
            startIcon={<DoneIcon />}
            variant="contained"
            color="primary"
            onClick={complete}
            disabled={isSupervising}
          >
            {t('Done')}
          </Button>
        ) : null}
      </Stack>
      <ActivityCompletedDialog
        open={showCompleteDialog}
        onClose={() => setShowCompleteDialog(false)}
      />
    </>
  );
};

export default ActivityActions;
