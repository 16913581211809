import { Container, Stack, Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { AiApi, languageKey } from '@api';
import { useEffect, useContext } from 'react';
import { CalendarContext } from '@/contexts/CalendarContext';

const TaskCreated = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  const { palette } = useTheme();
  const { weekInstances } = useContext(CalendarContext);
  const isLanguageKey = (lang: any): lang is languageKey => {
    return ['sv', 'en', 'de', 'fr', 'es', 'it', 'pl'].includes(lang);
  };

  useEffect(() => {
    const aiApi = new AiApi();
    aiApi.clearSuggestionCache();
    new AiApi().activitySuggestions(weekInstances, isLanguageKey(lang) ? lang : 'sv');
  }, []);

  return (
    <Container
      sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
    >
      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Typography variant="h5" fontWeight={'bold'}>
          {t('Activity Created')}
        </Typography>
        <CheckCircleOutlineIcon sx={{ color: palette.success.main, fontSize: '4em' }} />
        <Button onClick={() => navigate('/')} variant={'contained'} sx={{ width: '100%', p: 2 }}>
          {t('Done')}
        </Button>
      </Stack>
    </Container>
  );
};

export default TaskCreated;
