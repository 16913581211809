import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

type SSODialogProps = {
  open: boolean;
  onClose: () => void;
};

const SSODialog = (props: SSODialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullWidth={true}>
        <DialogTitle>{t('Todej SSO login')}</DialogTitle>
        <DialogContent>
          <Typography paragraph>{t('todej_paid_version_description1')}</Typography>
          <Typography paragraph>
            {t('todej_paid_version_description2') + ' '}
            <Link href={'mailto:appsupport@knowit.se'}>appsupport@knowit.se</Link>{' '}
            {' ' + t('todej_paid_version_description3')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SSODialog;
