/* tslint:disable */
/* eslint-disable */
import { AxiosResponse } from 'axios';
import { Activity } from './types';
import { format, isDate } from 'date-fns';

export class ResponseError extends Error {
  override name: 'ResponseError' = 'ResponseError';
  constructor(public response: AxiosResponse | undefined, msg?: string) {
    super(msg);
  }
}

export type Json = any;
export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD';
export type HTTPHeaders = { [key: string]: string };
export type HTTPQuery = {
  [key: string]:
    | string
    | number
    | null
    | boolean
    | Array<string | number | null | boolean>
    | Set<string | number | null | boolean>
    | HTTPQuery;
};
export type HTTPBody = Json | FormData | URLSearchParams;

export interface RequestOpts {
  path: string;
  method: HTTPMethod;
  headers?: HTTPHeaders;
  query?: HTTPQuery;
  body?: HTTPBody;
}

export interface ApiResponse<T> {
  raw: AxiosResponse;
  value(): Promise<T>;
}

export interface ResponseTransformer<T> {
  (json: any): T;
}

export class JSONApiResponse<T> {
  constructor(
    public raw: AxiosResponse,
    private transformer: ResponseTransformer<T> = (jsonValue: any) => jsonValue,
  ) {}
/*

{
  tag:{
    tag:
    ""
  }

  tag: ""
}

*/
  private recursiveTagReplacement(object: any): any {
    if (Array.isArray(object)) {
      return object.map((item) => this.recursiveTagReplacement(item));
    } else if (typeof object === 'object' && !isDate(object) && object !== null) {
      return Object.keys(object).reduce((newObj, key) => {
        let value = object[key];
        if (key === 'tag') {
          if (value.tag)
            value = value.tag;
        } else if (typeof value === 'object' && value !== null) {
          value = this.recursiveTagReplacement(value);
        }
        return { ...newObj, [key]: value };
      }, {});
    } else {
      return object;
    }
  }

  async value(): Promise<T> {
    const data = await this.raw.data;
    const newData = this.recursiveTagReplacement(data);
    return this.transformer(newData);
  }
}

export class VoidApiResponse {
  constructor(public raw: AxiosResponse) {}

  async value(): Promise<void> {
    return undefined;
  }
}

type AnyObject = Record<string, any>;
// Type guard function for checking if a value is an object
const isObject = (value: AnyObject): value is AnyObject =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

const ignoredKeys = ['pictureEdited', 'pictureFile'];
export const toRequestBody = (inputObject: AnyObject): AnyObject => {
  return Object.entries(inputObject).reduce((processedObject, [key, value]) => {
    if (ignoredKeys.includes(key)) {
      return processedObject;
    }
    if (key === 'picture') {
      // If the key is 'picture' and the 'edited' property is truthy, change its value
      if (value === '') processedObject[key] = null;
      else if (inputObject?.pictureEdited) processedObject[key] = 'NEW PICTURE';
      else processedObject[key] = value;
    } else if (value instanceof Date) {
      processedObject[key] = format(new Date(value), "yyyy-MM-dd'T'HH:mm:ssxxx");
    } else if (Array.isArray(value)) {
      // If the value is an array, process each item recursively
      processedObject[key] = value.map((item) => (isObject(item) ? toRequestBody(item) : item));
    } else if (isObject(value)) {
      // If the value is an object, process it recursively
      processedObject[key] = toRequestBody(value);
    } else if (key === 'recurringInterval') {
      processedObject[key] = value === 'DAILY' ? 'WEEKLY' : value;
    } else processedObject[key] = value;
    return processedObject;
  }, {} as AnyObject);
};

const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)|([+-]\d{2}:\d{2}))$/;
export const isIsoDateString = (value: any): boolean => {
  return value && typeof value === 'string' && isoDateFormat.test(value);
};

export const parseDates = (body: any) => {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      body[key] = new Date(value);
    } else if (typeof value === 'object') parseDates(value);
  }
};

export const getImageFile = (activity: Activity, key: string): File | undefined => {
  if (key === 'activityImageUrl' && activity.pictureFile) return activity.pictureFile;
  if (activity.subtasks && activity.subtasks.length) {
    const checkbox = activity.subtasks.find((item) => item.subtaskId === Number(key));
    return checkbox?.pictureFile;
  }
  return undefined;
};
