import { useTheme } from '@mui/material';
import { Box } from '@mui/material';
const ScrollItem = ({ item, height }: { item: string | number; height: number }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        px: 2,
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
        transition: 'transform 0.3s ease-in-out',
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: `${height}em}`,
        minHeight: `${height}em}`,
      }}
    >
      {item}
    </Box>
  );
};

export default ScrollItem;
