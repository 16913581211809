import { Typography, TextField, Stack, Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useContext } from 'react';
import { AIFormsContext } from '@/contexts/AIProfileContext';
import { useTranslation } from 'react-i18next';

const activityTypes = [
  'Sport/Träning',
  'Avkoppling',
  'Kultur',
  'Natur',
  'Hobby',
  'Socialt',
  'Läsning/Litteratur',
  'Film',
  'Matlagning',
  'Spel',
  'Trädgård',
  'Hemsysslor',
  'Musik',
  'Konst & Hantverk',
  'Utbildning',
];

const InterestsForm = () => {
  const { form, setForm } = useContext(AIFormsContext);
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const activity = e.currentTarget.textContent;
    if (activity) {
      if (form.preferredActivities.includes(activity)) {
        const newActivities = form.preferredActivities.filter((act) => act !== activity);
        setForm({ ...form, preferredActivities: newActivities });
      } else {
        setForm({ ...form, preferredActivities: [...form.preferredActivities, activity] });
      }
    }
  };

  return (
    <Stack spacing={4}>
      <Typography>{t('Which activities do you prefer?')}:</Typography>
      <Stack
        direction="row"
        flexWrap={'wrap'}
        justifyContent="space-evenly"
        spacing={1}
        rowGap={1.5}
        mt={'12px !important'}
      >
        {activityTypes.map((activity) => (
          <Chip
            key={activity}
            label={t(activity)}
            onClick={handleClick}
            variant={form.preferredActivities.includes(activity) ? 'filled' : 'outlined'}
            color="primary"
            icon={
              form.preferredActivities.includes(activity) ? (
                <CheckIcon fontSize="small" />
              ) : undefined
            }
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default InterestsForm;
