import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type InfoComponentProps = {
  image: string;
  title: string;
  description: string;
  color: string;
};

const InfoComponent = ({ image, title, description, color }: InfoComponentProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
        height: '100%',
        minWidth: '50%',
      }}
    >
      <Box sx={{ background: color, width: '20em', minHeight: '30rem' }}>
        <img
          src={image}
          alt={title}
          style={{
            width: '100%',
          }}
        />
        <Typography
          variant="h5"
          pl={2}
          pt={2}
          sx={(theme) => ({
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          })}
        >
          {t(title)}
        </Typography>
        <Typography
          variant="body1"
          p={2}
          sx={(theme) => ({
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
          })}
        >
          {t(description)}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoComponent;
